import React, { useEffect, useState, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { formatDateToUTCString } from '../utils/time_zones/TimeZones';
import SupportForm from '../features/support/SupportForm'

const Products = ({ locationName, call }) => {

    const contentRef = useRef();

    const [pricesData, setPricesData] = useState([]);
    const [inputPrice, setInputPrice] = useState(); // State to handle the price changes
    const [searchTerm, setSearchTerm] = useState(''); // State to manage searched item
    const [newSearchTerm, setNewSearchTerm] = useState(''); // State to manage searched item
    const [username, setUsername] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [locationData, setLocationData] = useState([]);
    const [newInventory, setNewInventory] = useState('');
    const [currentInventory, setCurrentInventory] = useState('');
    const [inventoryByProduct, setInventoryByProduct] = useState({});
    const [supplierId, setSupplierId] = useState('');
    const [priceQuantity, setPriceQuantity] = useState('');

    const [productName, setProductName] = useState('');
    const [productDes, setProductDes] = useState('');
    const [manufacturerName, setManufacturerName] = useState('');
    const [productCat, setProductCat] = useState('');
    const [quantityConversion, setQuantityConversion] = useState('');
    const [productSupplier, setProductSupplier] = useState('');
    const [productPriceQuantity, setProductPriceQuantity] = useState('');
    const [productPrice, setProductPrice] = useState('');

    


    useEffect(() => {

        fetchUsername();
        fetchLocations();

        fetch(`${call}/get-prices`)
      .then(response => response.json())
      .then(data => {
        setPricesData(data);
      })
      .catch(error => console.error('Error fetching prices data:', error));



    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';

    }, []);


    // Function to fetch the username
    const fetchUsername = () => {
        // Fetch username using the API and the email from local storage
        const userEmail = localStorage.getItem('userEmail');
      
        if (userEmail) {
          fetch(`${call}/get-username?user_email=${userEmail}`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              // Store the username in the state variable
              setUsername(data.username);
            })
            .catch(error => console.error('Error fetching username:', error));
        }
      };

    const fetchLocations = () => {

        fetch(`${call}/get-locations`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Store the username in the state variable
          setLocationData(data);
        })
        .catch(error => console.error('Error fetching locations:', error));

    }
  


    const handleSubmit = async (productID, old_price, new_price, supplierName, price_quantity  ) => {

       
        const product_id = productID;
        const origin = 'Products Page'
        const date_time = formatDateToUTCString(new Date());

        const response1 = await fetch(`${call}/update-price-tracking/${productID}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, product_id, old_price, new_price, date_time, origin  }),
        })

        const response2 = await fetch(`${call}/edit-prices/${productID}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ product_id, new_price, supplierName, price_quantity}),
        })



    }


    const handleCurrentInventory = async (productID, selectedLocation, quantity_conversion ) => {

        
        const response = await fetch(`${call}/get-inventory-quantity`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productID, selectedLocation  }),
        })

        const data = await response.json(); // Assuming the response is in JSON format
        
        setInventoryByProduct(prevInventory => ({
            ...prevInventory,
            [productID]: data[0].inventory_quantity
          }));

    

    }

    const handleInventoryUpdate = async (productID, selectedLocation, old_inventory_quantity, new_inventory_quantity, quantity_conversion ) => {
        
        const location_name = selectedLocation;
        const product_id = productID;
        new_inventory_quantity = new_inventory_quantity*quantity_conversion;
        
        const date_time = formatDateToUTCString(new Date());
        if(old_inventory_quantity === -1){
            old_inventory_quantity = null;
            const origin = "New Inventory";

            fetch(`${call}/insert-tracking-data/${productID}`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name, username, product_id, old_inventory_quantity , new_inventory_quantity, date_time, origin  }),
            })

            fetch(`${call}/insert-inventory`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({productID, selectedLocation, new_inventory_quantity }),
            })

            

        }
        else{
            const origin = "Edit Inventory";
            fetch(`${call}/insert-tracking-data/${productID}`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_name, username, product_id, old_inventory_quantity ,new_inventory_quantity, date_time, origin  }),
            })

            const response = await fetch(`${call}/edit-quantity/${productID}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newQuantity: parseInt(new_inventory_quantity, 10), locationName: selectedLocation }),
              });

        }

       


    }

    const handleAddProduct = async (productName, productDes, manufacturerName, productCat, quantityConversion, productSupplier, productPriceQuantity, productPrice ) => {

        const response = await fetch(`${call}/insert-product-data`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productName, productDes, manufacturerName, productCat, quantityConversion }),
        })

        const data = await response.json(); // Wait for the JSON conversion
        const product_id = data.product_id; // Access the product_id

        const response1 = await fetch(`${call}/insert-prices-data`, {
          method: 'PUT',
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify({ product_id, productSupplier, productPriceQuantity, productPrice }),
      })



    }



    const [showContact, setShowContact] = useState(false);
    const toggleContactInfo = () => {
      setShowContact(!showContact);
    }

    const filteredPricesData = pricesData.filter(item =>
        item.product_name.toLowerCase().includes(searchTerm.toLowerCase()))


    const filteredInventoryData = pricesData.filter(item =>
        item.product_name.toLowerCase().includes(newSearchTerm.toLowerCase()))

    const uniqueLocations = Array.from(new Set(locationData.map(item => item.location_name)));
    const uniqueManufacturers = Array.from(new Set(pricesData.map(item => item.manufacturer_name)));
    const uniqueCategories = Array.from(new Set(pricesData.map(item => item.product_cat)));

    function uniqueSuppliersForItem(selectedItemId) {
        if (!selectedItemId) {
          return [];
        }
        
        const filteredSuppliers = pricesData.filter(item => item.product_id === selectedItemId);
        const uniqueSuppliers = Array.from(new Set(filteredSuppliers.map(item => `${item.supplier_id}|${item.supplier_name}`)));
        
        return uniqueSuppliers.map(supplier => {
          const [id, name] = supplier.split('|');
          return { supplier_id: id, supplier_name: name };
        });
      }

      function uniqueSuppliers() {
        
        
        const uniqueSuppliers = Array.from(new Set(pricesData.map(item => `${item.supplier_id}|${item.supplier_name}`)));
        
        return uniqueSuppliers.map(supplier => {
          const [id, name] = supplier.split('|');
          return { supplier_id: id, supplier_name: name };
        });
      }
      

      function uniquePriceQuantitiesForItem(selectedItemId) {
        if (!selectedItemId) {
          return [];
        }
        return Array.from(new Set(pricesData.filter(item => item.product_id === selectedItemId).map(item => item.price_quantity)));
      }
      

  
    return (

        <div ref={contentRef} style={{ textAlign: 'left'}}>

        <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            
          <h1 style={{marginTop: '30px',  display: 'flex', fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>Products</h1>
    
        <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: '#87D3D7', marginRight: '-4px'}}>
        
        <h1 style={{ fontSize: '20px' }}>?</h1>
        </button>
        {showContact && (
          <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
        )}
    
        </div>
        
        <h3 style={{marginTop: '10px',  display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>Update Price</h3>

        <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '20px' }}>

        <input
          type="text"
          placeholder="Search Product Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}

          style={{ marginTop: '-10px',  marginRight: '20px', border: '1px solid #87D3D7',  padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}

        />

        </div>
        
        <div style={{ 
  border: '2px solid #87D3D7', 
  display: 'flex', 
  justifyContent: 'left', 
  flexDirection: 'column', 
  width: '100%', 
  maxHeight: '310px', // Set a fixed height
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
}}>
  <table style={{  
    backgroundColor: '#ffffff', 
    width: '100%', 
    borderCollapse: 'separate', 
    borderSpacing: 0, 
    position: 'relative' // Ensure positioning context
  }}>
    <thead style={{
      position: 'sticky', top: 0
    }}>
      <tr style={{ fontSize: '13px', backgroundColor: '#fffff', color: '#000000' }}>
        <th style={{ borderBottom:'2px solid black', padding: '10px',  width: '10%', textAlign: 'left', backgroundColor: '#ffffff' }}>Product Name</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'left', backgroundColor: '#ffffff' }}>Description</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Manufacturer</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Supplier</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Price Quantity</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Client Price</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Update Price</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Submit</th>
      </tr>
    </thead>
    <tbody>
      {filteredPricesData.map((item,index) => (
        <tr key={item.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
          <td style={{padding: '10px',}}>{item.product_name}</td>
          <td style={{padding: '10px', }}>{item.product_description}</td>
          <td style={{padding: '10px', textAlign: 'center'}}>{item.manufacturer_name}</td>
          <td style={{ padding: '10px' , textAlign: 'center' }}>
          <select
          onChange={(e) => {setSupplierId(e.target.value); }}

          style={{  padding: '5px', fontStyle: 'italic', width: '150px' }}
        >
          <option value="">Select Supplier</option>
            {uniqueSuppliersForItem(item.product_id).map(({ supplier_id, supplier_name }) => (
            <option key={supplier_id} value={supplier_id}>
                {supplier_name}
            </option>
          ))}
        </select>

          </td>

          <td style={{ padding: '10px' , textAlign: 'center' }}>
          <select
          onChange={(e) => {setPriceQuantity(e.target.value); }}

          style={{  padding: '5px', fontStyle: 'italic', width: '180px' }}
        >
          <option value="">Select Quantity Price</option>
          {uniquePriceQuantitiesForItem(item.product_id).map(price_quantity => (
            <option key={price_quantity} value={price_quantity}>
              {price_quantity}
            </option>
          ))}
        </select>

          </td>
          <td style={{ padding: '10px' , textAlign: 'center' }}>{item.client_price}</td>
          <td style={{padding: '10px', textAlign: 'center'}}><input
          type="text"
          style={{width: '100px'}} 
          onChange={(e) => {setInputPrice(parseFloat(e.target.value, 2))}}
        /></td>  
        <td style={{padding: '10px', textAlign: 'center'}}>
        <button onClick={() => handleSubmit(item.product_id, item.client_price, inputPrice, supplierId, priceQuantity )} style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer' , borderRadius: '8px'}}>Submit</button>
        </td>
          
         
        </tr>
      ))}
    </tbody>
  </table>
</div>


    <h3 style={{marginTop: '20px',  display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>Add Inventory</h3>
    
    <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '20px' }}>

    <input
    type="text"
    placeholder="Search Product Name"
    value={newSearchTerm}
    onChange={(e) => setNewSearchTerm(e.target.value)}

    style={{ marginTop: '-10px',  marginRight: '20px', border: '1px solid #87D3D7',  padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}

    />

    </div>

        <div style={{ 
  border: '2px solid #87D3D7', 
  display: 'flex', 
  justifyContent: 'left', 
  flexDirection: 'column', 
  width: '100%', 
  maxHeight: '310px', // Set a fixed height
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
}}>
  <table style={{  
    backgroundColor: '#ffffff', 
    width: '100%', 
    borderCollapse: 'separate', 
    borderSpacing: 0, 
    position: 'relative' // Ensure positioning context
  }}>
    <thead style={{
      position: 'sticky', top: 0
    }}>
      <tr style={{ fontSize: '13px', backgroundColor: '#fffff', color: '#000000' }}>
        <th style={{ borderBottom:'2px solid black', padding: '10px',  width: '10%', textAlign: 'left', backgroundColor: '#ffffff' }}>Product Name</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'left', backgroundColor: '#ffffff' }}>Description</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Location</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Current Inventory</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Quantity Conversion</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'left', backgroundColor: '#ffffff' }}>Update Inventory</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Update</th>

      </tr>
    </thead>
    <tbody>
      {filteredInventoryData.map((item,index) => (
        <tr key={item.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
          <td style={{padding: '10px',}}>{item.product_name}</td>
          <td style={{padding: '10px', }}>{item.product_description}</td>
          <td style={{padding: '10px',textAlign: 'center' }}>
          <select
          onChange={(e) => {setSelectedLocation(e.target.value); handleCurrentInventory(item.product_id, e.target.value, item.quantity_conversion); }}

          style={{  padding: '5px', fontStyle: 'italic', width: '150px' }}
        >
          <option value="">Select Location</option>
          {uniqueLocations.map(location_name => (
            <option key={location_name} value={location_name}>
              {location_name}
            </option>
          ))}
        </select>

          </td>

          <td style={{ padding: '10px', textAlign: 'center' }}>
          {
            inventoryByProduct[item.product_id] === -1
              ? 'No Inventory'
              : (
                  item.quantity_conversion === 0 || isNaN(inventoryByProduct[item.product_id]) || isNaN(item.quantity_conversion)
                    ? 'Select Location'
                    : (inventoryByProduct[item.product_id] / item.quantity_conversion).toFixed(2)
                )
          }
        </td>
          <td style={{padding: '10px',  textAlign: 'center'}}>{item.quantity_conversion}</td>
          <td style={{padding: '10px', textAlign: 'left'}}><input
          type="text"
          style={{width: '80px'}} 
          onChange={(e) => {setNewInventory(parseFloat(e.target.value, 2))}}
        />*{item.quantity_conversion}</td>  
        <td style={{padding: '10px', textAlign: 'center'}}>
        <button onClick={() => handleInventoryUpdate(item.product_id, selectedLocation, inventoryByProduct[item.product_id], newInventory, item.quantity_conversion)} style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer' , borderRadius: '8px'}}>Update</button>
        </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

<h3 style={{marginTop: '20px',  display: 'flex', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>Add Product</h3>


<div style={{ 
  border: '2px solid #87D3D7', 
  display: 'flex', 
  justifyContent: 'left', 
  flexDirection: 'column', 
  marginBottom: '30px',
  width: '100%', 
  maxHeight: '310px', // Set a fixed height
  minHeight: '100px', // Set a fixed height
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
}}>
  <table style={{  
    backgroundColor: '#ffffff', 
    width: '100%', 
    borderCollapse: 'separate', 
    borderSpacing: 0, 
    position: 'relative' // Ensure positioning context
  }}>
    <thead style={{
      position: 'sticky', top: 0
    }}>
      <tr style={{ fontSize: '13px', backgroundColor: '#fffff', color: '#000000' }}>
        <th style={{ borderBottom:'2px solid black', padding: '10px',  width: '10%', textAlign: 'left', backgroundColor: '#ffffff' }}>Product Name</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Description</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Manufacturer Name</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Product Category</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Quantity Conversion</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Supplier Name</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Price Quantity</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Price</th>
        <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center', backgroundColor: '#ffffff' }}>Add Product</th>

      </tr>
    </thead>
    <tbody>
     
          <td style={{padding: '10px', textAlign: 'left'}}><input
          type="text"
          style={{width: '80px'}} 
          onChange={(e) => {setProductName(e.target.value)}}
        /></td>  

           <td style={{padding: '10px', textAlign: 'center'}}><input
          type="text"
          style={{width: '80px'}} 
          onChange={(e) => {setProductDes(e.target.value)}}
        /></td> 

        <td style={{padding: '10px', textAlign: 'center'}}>

        <select
          onChange={(e) => {setManufacturerName(e.target.value)}}

          style={{  padding: '5px', fontStyle: 'italic', width: '150px' }}
        >
          <option value="">Select Manufacturer</option>
          {uniqueManufacturers.map(manufacturer_name => (
            <option key={manufacturer_name} value={manufacturer_name}>
              {manufacturer_name}
            </option>
          ))}
        </select>

          </td>

        <td style={{padding: '10px', textAlign: 'center'}}>
          
        <select
          onChange={(e) => {setProductCat(e.target.value)}}

          style={{  padding: '5px', fontStyle: 'italic', width: '150px' }}
        >
          <option value="">Select Category</option>
          {uniqueCategories.map(product_cat => (
            <option key={product_cat} value={product_cat}>
              {product_cat}
            </option>
          ))}
        </select>


          </td> 

        <td style={{padding: '10px', textAlign: 'center'}}><input
          type="text"
          style={{width: '80px'}} 
          onChange={(e) => {setQuantityConversion(e.target.value)}}
        /></td> 

        <td style={{padding: '10px', textAlign: 'center'}}>
          
        <select
          onChange={(e) => {setProductSupplier(e.target.value)}}

          style={{  padding: '5px', fontStyle: 'italic', width: '150px' }}
        >
          <option value="">Select Supplier</option>
          {uniqueSuppliers().map(({ supplier_id, supplier_name }) => (
            <option key={supplier_id} value={supplier_id}>
                {supplier_name}
            </option>
          ))}
        </select>

          </td> 


        <td style={{padding: '10px', textAlign: 'center'}}><input
          type="text"
          style={{width: '80px'}} 
          onChange={(e) => {setProductPriceQuantity(e.target.value)}}
        /></td> 

        <td style={{padding: '10px', textAlign: 'center'}}><input
          type="text"
          style={{width: '80px'}} 
          onChange={(e) => {setProductPrice(e.target.value)}}
        /></td> 

        <td style={{padding: '10px', textAlign: 'center'}}>
        <button onClick={() => handleAddProduct(productName, productDes, manufacturerName, productCat, quantityConversion, productPriceQuantity, productPrice, productSupplier)} style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer' , borderRadius: '8px'}}>Add</button>
        </td>
       
      
    </tbody>
  </table>
</div>

        
        
        
        </div>


    );


}

export default Products;