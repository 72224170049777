import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faTrash} from '@fortawesome/free-solid-svg-icons';
import ContentEditable from 'react-contenteditable';
import { formatDateTimeText, formatDateText, formatDateToUTCString, newDate, formatToUTC } from '../utils/time_zones/TimeZones';

const Automation = ({ call }) => {
  const [username, setUsername] = useState('');
  const [orderData, setOrderData] = useState([]);
  const [orderDataGeneral, setOrderDataGeneral] = useState([]);
  let [inventoryData, setInventoryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermInventory, setSearchTermInventory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Open');
  const [selectedOrderUrgency, setSelectedOrderUrgency] = useState('');
  let [editedOrder, setEditedOrder] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditModalOpenBundle, setIsEditModalOpenBundle] = useState(false);
  const [isEditModalOpenFreeProduct, setIsEditModalOpenFreeProduct] = useState(false);
  const [editedTaxesPrice, setEditedTaxesPrice] = useState(0);
  const [editedShippingPrice, setEditedShippingPrice] = useState(0);
  const [editedDiscount, setEditedDiscount] = useState(0);
  const [bundleComment, setBundleComment] = useState(0);
  const [locationName, setLocationName] = useState('VIO Annapolis'); // Default location
  const [locations, setLocations] = useState([]); // Updated to store locations data
  let [bundlesData, setBundlesData] = useState([]);
  const [suppliersData, setSuppliersData] = useState(''); 
  const [selectedSupplierId, setSelectedSupplierId] = useState(0); 
  const [dueDateOption, setDueDateOption] = useState('immediate');
  const [shipped, setShipped] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState('');
  let [dueDate, setDueDate] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [selectedManufacturerInventory, setSelectedManufacturerInventory] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [freeProductQuantity, setFreeProductQuantity] = useState(0);
  const [optionOrder, setOptionOrder] = useState('platform');
  const [file, setFile] = useState('')
  const [fileName, setFileName] = useState("")
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [bundlesInvoices, setBundlesInvoices] = useState([]);
  const [haveToUpload, setHaveToUpload] = useState(true);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0)
  const [optionPrice, setOptionPrice] = useState('unit_price');
  const [isUpdateCommentModalOpen, setIsUpdateCommentModalOpen] = useState(false);

  // Gets unique category of products
  const orderStatusCategories = [
    'Placed',
    'Confirmed',
    'Backorder',
    'In Review',
    'Open',
    'Shipped',
    'Delivered',
    'Recommend',
    'Remove',
    'Moved',
    'Declined'
  ];

  // Gets unique category of bundles
  const bundleStatusCategories = [
    'Placed',
    'Confirmed',
    'Backorder',
    'Pending',
    'In Review',
    'Open',
    'Shipped',
    'Delivered',
    'Recommend',
    'Remove',
    'Declined'
  ];

  // Define the order urgency levels
  const orderUrgencyLevels = Array.from(new Set(orderData.map(item => item.order_urgency)));

  const contentRef = useRef();

  useEffect(() => {
    fetchUsername();
    fetchBundles();
    fetchInventoryData();
    fetchLocations();
    fetchOrderData();
    fetchFiles();
    fetchSuppliersData();

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '49px';

  }, [locationName]);

  // Function to format the date string to "yyyy-MM-dd" format
  const getFormattedDate = (dateString) => {
    if (!dateString) return ''; // Handle cases where dateString is null or undefined
    const dateObject = newDate(dateString);
    const year = dateObject.getFullYear();
    const month = `${dateObject.getMonth() + 1}`.padStart(2, '0'); // Adding 1 to month because JavaScript months are zero-based
    const day = `${dateObject.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchFiles = async () => {
    try {
        
        const response = await fetch(`${call}/get-aws-files`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ folder: "Bundles-invoices/" }), // Include the key in the request body as JSON
        });
    
        const data = await response.json();

        setBundlesInvoices(data.files);
        // Process the file names as needed
    } catch (error) {
        console.error('Error fetching file names:', error);
    }
};

  const fetchBundles = () => {
    // Fetch locations data from the /locations API
    fetch(`${call}/get_bundles`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setBundlesData(data); // Update the locations state with the fetched data
        bundlesData = data;

      })
      .catch(error => console.error('Error fetching locations data:', error));
  };

  const fetchLocations = () => {
    // Fetch locations data from the /locations API
    fetch(`${call}/locations`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLocations(data); // Update the locations state with the fetched data
      })
      .catch(error => console.error('Error fetching locations data:', error));
  };

  const fetchUsername = () => {
    const userEmail = localStorage.getItem('userEmail');

    if (userEmail) {
      fetch(`${call}/get-username?user_email=${userEmail}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setUsername(data.username);
        })
        .catch(error => console.error('Error fetching username:', error));
    }
  };

  const fetchOrderData = async () => {
    fetch(`${call}/orders`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const locationOrderData = data.filter(item => item.location_name === locationName);
        setOrderData(locationOrderData);
        setOrderDataGeneral(data);
      })
      .catch(error => console.error('Error fetching order data:', error));
  };

  const fetchInventoryData = async () => {
    try {
      const response = await fetch(`${call}/inventory`);
      const data = await response.json();
      const locationInventoryData = data.filter(item => item.location_name === locationName);
      setInventoryData(locationInventoryData);

      return locationInventoryData;
    } catch (error) {
        console.error('Error fetching inventory data:', error);
    }
  };

  // Function to handle the search term change
  const handleSearchTermChange = event => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the category selection change
  const handleCategoryChange = event => {
    setSelectedCategory(event.target.value);
  };

  // Function to handle the order urgency selection change
  const handleOrderUrgencyChange = event => {
    setSelectedOrderUrgency(event.target.value);
  };

  const uniqueManufacturers = Array.from(new Set(orderData
    .filter(item => item.manufacturer_name !== null) // Filter out null values
    .map(item => item.manufacturer_name)));
  
  // Filter orders based on search term, selected category, and selected order urgency
  const filteredOrders = orderData.filter(order => {
    const productName = (order.product_name || '').toLowerCase(); // Ensure product_name exists
    const status = (order.status_cat || '').toLowerCase(); // Ensure status_cat exists
    const urgency = (order.order_urgency || '').toLowerCase(); // Ensure order_urgency exists and convert to lowercase
    const manufacturer = (order.manufacturer_name || '').toLowerCase(); // Ensure manufacturer_name exists and convert to lowercase

    // Check if the order matches the search term, selected category, and selected order urgency
    return (
      productName.includes(searchTerm.toLowerCase()) &&
      (selectedCategory === '' || status === selectedCategory.toLowerCase()) &&
      (selectedOrderUrgency === '' || urgency === selectedOrderUrgency.toLowerCase()) &&
      (selectedManufacturer === '' || manufacturer === selectedManufacturer.toLowerCase())
    );
  });

    // Filter orders based on search term, selected category, and selected order urgency
    const filteredInventory = inventoryData.filter(order => {
      const productName = (order.product_name || '').toLowerCase(); // Ensure product_name exists
      const manufacturer = (order.manufacturer_name || '').toLowerCase(); // Ensure manufacturer_name exists and convert to lowercase
  
      // Check if the order matches the search term, selected category, and selected order urgency
      return (
        productName.includes(searchTermInventory.toLowerCase()) &&
        (selectedManufacturerInventory === '' || manufacturer === selectedManufacturerInventory.toLowerCase())
      );
    });

  const handleEditOrder = (order) => {
    setEditedOrder(order);
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      if (editedOrder) {

        const { order_id, order_code, product_id, unit_price, order_quantity, internal_description, status_cat } = editedOrder;
        
        // Fetch the old order data using the /orders API
        const oldOrderData = orderData.filter(order => order.order_id === order_id)[0];

        if (status_cat === 'Remove'){
          const response = await fetch(`${call}/update-order-code`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  order_id: order_id,
                  order_code: 0,
                  order_code_old: order_code,
                  location_name: locationName
              }),
          });

          await fetchBundles();
        }
        else if (status_cat === 'Recommend'){
          const response = await fetch(`${call}/update-order-code`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  order_id: order_id,
                  order_code: -1,
                  order_code_old: order_code,
                  location_name: locationName
              }),
          });

          await fetchBundles();
        }
        else if (status_cat === 'Declined'){
          const response = await fetch(`${call}/update-order-code`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  order_id: order_id,
                  order_code: -3,
                  order_code_old: order_code,
                  location_name: locationName
              }),
          });

          await fetchBundles();
        }
        else if (status_cat === 'Delivered') {
          fetchInventoryData().then(async (result)=>{
            
            // If the update was successful, fetch the current inventory quantity
            const productId = orderData.find(order => order.order_id === order_id).product_id;
            let currentInventoryData = result.find(item => item.product_id === productId);

            
            if (currentInventoryData) {

            //Update spotcheck to check order

            const requestBody = {
              productId: productId,
              locationName: locationName,
              orderId: order_id
            };

            // Make a PUT request to change the spotcheck status
            fetch(`${call}/change-spotcheck-status`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestBody)
            })
            .then(response => {
              if (response.ok) {
                console.log('Spotcheck status updated successfully');
              } else {
                console.error('Failed to update spotcheck status');
              }
            })
            .catch(error => {
              console.error('Error:', error);
            });

            // Edit quantity

              let currentQuantity = currentInventoryData.inventory_quantity;
              const orderQuantity = order_quantity;
              const newQuantity = currentQuantity + orderQuantity * currentInventoryData.quantity_conversion;

              // Send a PUT request to update the inventory quantity by product_id
              await fetch(`${call}/edit-quantity/${productId}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newQuantity, locationName }),
              })
                .then(response => response.json())
                .then(result => {
                  if (result.success) {
                    console.log('Inventory quantity updated successfully');
                    // Fetch updated order data after inventory update
                    fetchOrderData();
                    fetchInventoryData();

                    // Call the /insert-tracking-data API to insert tracking data
                    const location_name = currentInventoryData.location_name; 
                    const date_time = formatDateToUTCString(new Date());
                    const old_inventory_quantity = currentQuantity;
                    const product_id = currentInventoryData.product_id;
                    const new_inventory_quantity = newQuantity;
                    let origin = 'Order (+)';

                    fetch(`${call}/insert-tracking-data/${product_id}`, {
                        method: 'PUT',
                        headers: {
                        'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ location_name, username, product_id, old_inventory_quantity, new_inventory_quantity, date_time, origin }),
                    })
                        .then(response => response.json())
                        .then(result => {
                        if (result.success) {
                            console.log('Tracking data inserted successfully');
                        } else {
                            console.error('Failed to insert tracking data');
                        }
                        })
                        .catch(error => console.error('Error inserting tracking data:', error));

                  } else {
                    console.error('Failed to update inventory quantity');
                  }
                })
                .catch(error => console.error('Error updating inventory quantity:', error));

                try {

                  if (unit_price !== 0){
                    // Call the API to update the product price and insert a price tracking record
                    const updatePriceResponse = await fetch(`${call}/update-product-price`, {
                      method: 'PUT',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        price: unit_price, // Pass the updated unit price
                        product_id: product_id, // Pass the updated unit price
                        location_name: locationName, // Pass the updated unit price
                        username: username, // Pass the updated unit price
                        origin: 'Automation-Page', // Pass the updated unit price
                      }),
                    });
                    if (updatePriceResponse.ok) {
                      console.log('Product price updated successfully');
                    }
                  }

                }
                catch (error) {
                  console.error('Error updating product price:', error);
                }
                
            }
          })
        }

        // Make a PUT request to update the order details with new data
        const response = await fetch(`${call}/update-order/${order_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            unit_price,
            order_quantity,
            internal_description,
            status_cat,
            supplier_id: selectedSupplierId
          }),
        });
  
        if (!response.ok) {
          throw new Error('Error updating order details');
        }
  
        // Handle success response (you can show a success message if needed)
        console.log('Order details updated successfully');
        setIsEditModalOpen(false); // Close the edit modal
        setEditedOrder(null); // Clear the edited order data
        fetchOrderData(); // Refresh the order data after the update
        

        // Prepare data for order tracking
        const location_name = locationName; // Replace with the actual location name
        const date_time = formatDateToUTCString(new Date()); // Use the current date and time

        // Send a POST request to store order tracking data with the required fields
        fetch(`${call}/store-orders-tracking`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order_id,
            location_name,
            product_id,
            order_quantity_before: oldOrderData.order_quantity, // Get old order quantity from fetched old data
            order_quantity_after: order_quantity, // Get new order quantity from editedOrder
            unit_price_before: oldOrderData.unit_price, // Get old unit price from fetched old data
            unit_price_after: unit_price, // Get new unit price from editedOrder
            order_comment_before: oldOrderData.order_comment, // Get old internal description from fetched old data
            order_comment_after: username, // Get new internal description from editedOrder
            status_cat_before: oldOrderData.status_cat,
            status_cat_after: status_cat,
            change_datetime: date_time,
          }),
        })
        .then(response => response.json())
        .then(async (result) => {
          if (result.message) {
            console.log('Order tracking data inserted successfully');
            await fetchBundles();
          } else {
            console.error('Failed to insert order tracking data');
          }
        })
        .catch(error => console.error('Error inserting order tracking data:', error));
        

      }
      setOptionPrice("unit_price"); 
      setTotalOrderPrice(0);
    } catch (error) {
      // Handle errors, you can show an error message if needed
      console.error('Error updating order details:', error);
    }
  };

const [emailData, setEmailData] = useState(null);

const getTableOrder = (orders) => {
  return `
    <div style="border: 2px solid #87D3D7;
        width: 400px;
        box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);"
    >
      <table style="
              width: 400px;
              border-collapse: separate;
              border-spacing: 0;
              position: relative;"
      >
        <thead style="
                    top: 0;
                    z-index: 1;"
        >
          <tr style="font-size: 13px; background-color: white;">
              <th style="border-bottom: 2px solid black;
                        padding: 10px;
                        text-align: left;">Product Name</th>
              <th style="border-bottom: 2px solid black;
                        padding: 10px;
                        text-align: left;">Product Description</th>
              <th style="border-bottom: 2px solid black;
                        padding: 10px;
                        text-align: left;">Order Quantity</th>
          </tr>
        </thead>
        <tbody>
          ${orders.map((product, index) => `
              <tr key='${index}' style="font-size: 13px;
                                        background-color: ${index % 2 === 0 ? '#ebf4f5' : '#ffffff'};">
                  <td style="padding: 10px; text-align: left;">${product.product_name}</td>
                  <td style="padding: 10px;
                            text-align: left;">${product.product_description}</td>
                  <td style="padding: 10px;
                            text-align: left;">${product.order_quantity}</td>
              </tr>
          `).join('')}
        </tbody>
      </table>
    </div>

  `;
};

const handleBundleLoadMail = async (bundle) => {
  try {
      // Get the list of orders present inside the bundle
      const orderCode = getBundle(bundle).order_code;

      const orders = Object.values(bundles)
      .flatMap(orders => orders.filter(order => order.order_code === orderCode));

      // Check if all orders have the same supplier_id
      const uniqueSupplierIds = [...new Set(orders.map(order => order.supplier_id))];

      let resp_contact_email = '';
      let resp_contact_name = '';
      let supplierName = '';

      if (uniqueSupplierIds.length !== 1) {
          console.log('Orders in the bundle have different supplier IDs. Skipping email preparation.');    
      }
      else{
        const supplierId = uniqueSupplierIds[0];

        // Fetch the supplier data using the supplier_id
        const response = await fetch(`${call}/suppliers?supplier_id=${supplierId}`);
        if (!response.ok) {
            throw new Error('Error fetching supplier data');
        }
        const supplierData = await response.json();
  
        // Extract receiver_email and supplier_contact from supplierData
        const { supplier_name, contact_email, contact_name } = supplierData[0];

        resp_contact_email = contact_email;
        resp_contact_name = contact_name;
        supplierName = supplier_name;

      }

      // Prepare data to send to the /send_order_email API
      setEmailData({
        contact_email: resp_contact_email,
        client_name: orders[0].location_name,
        contact_name: resp_contact_name,
        subject: `${orders[0].location_name} <> ${supplierName} Order`,
        message: `Hi ${resp_contact_name},
          <br/>
          <br/>
          ${orders[0].location_name} would like to place the following order:
          <br/>
          <br/>
          ${getTableOrder(orders)}
          <br/>
          <br/>
          Please let us know if any adjustments to the order could favorably impact the pricing and once the order is confirmed. It would also be great if you could share the invoice and tracking number once available.
          <br/>
          <br/>
          Thank you very much and best regards,
          <br/>
          <br/>
          Medvelle Team`,
        });
    
  } catch (error) {
      // Handle errors appropriately
      console.error('Error processing order status change:', error);
  }
};

const handleSubmitPlaced = () => {
    // Make a POST request to the /send_email_order API
    fetch(`${call}/send-email-order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Error sending order email');
        }
        console.log('Order email sent successfully');
    })
    .catch(error => {
        console.error('Error sending order email:', error);
    });
};

  // Function to create bundles from filtered orders
  const createBundles = (filteredOrders) => {
    const bundles = {};

    // Group orders by order_code
    filteredOrders.forEach((order) => {
      if (!bundles[order.order_code]) {
        bundles[order.order_code] = [order];
      } else {
        bundles[order.order_code].push(order);
      }
    });

    // Convert object to array of bundles
    return Object.values(bundles);
  };

  // Use the function to create bundles from filtered orders
  const bundles = createBundles(filteredOrders);

  const [expandedBundles, setExpandedBundles] = useState([]);

  const handleBundleClick = (bundleId) => {
    if (expandedBundles.includes(bundleId)) {
      // If the bundle is already expanded, collapse it
      const ordersInBundle = Object.values(sortedFilteredBundles)
      .flatMap(orders => orders.filter(order => order.order_code === bundleId));

      const deselectedOrders = selectedOrders.filter(order => !ordersInBundle.includes(order));

      setSelectedOrders(deselectedOrders);

      setExpandedBundles(expandedBundles.filter(id => id !== bundleId));
    } else {
      // If the bundle is not expanded, add it to the list of expanded bundles
      setExpandedBundles([...expandedBundles, bundleId]);
    }
  };
  

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrderCode, setSelectedOrderCode] = useState('new_bundle');


  // Function to handle order checkbox change
  const handleOrderCheckboxChange = (order) => {
    if (selectedOrders.includes(order)) {
      setSelectedOrders(selectedOrders.filter(o => o !== order));
    } else {
      setSelectedOrders([...selectedOrders, order]);
    }
  };

  // Function to handle dropdown change
  const handleDropdownChange = (e) => {
    setSelectedOrderCode(e.target.value);
  };

  // Function to handle Apply button click
  const handleApplyClick = async () => {

    // Apply the selected order code to the selected orders
    let orderCodeToSend = selectedOrderCode;

    if (selectedOrderCode === 'new_bundle' && selectedOrders.length !== 0) {
      try {
        // Fetch the next order code from the API
        const response = await fetch(`${call}/get_max_order_code`);
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

    
        const data = await response.json();
        
        // Use the returned next order code
        orderCodeToSend = data.next_order_code;
    
      } catch (error) {
        console.error('Error fetching max order code:', error);
    
        // If there's an error, fallback to order code 1
        orderCodeToSend = 1;
      }

      await fetch(`${call}/create-bundle`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderCode: orderCodeToSend, // Example order code
          shipping: 0, // Example shipping
          taxes: 0, // Example taxes
          discount: 0,
          bundleDate: formatDateToUTCString(getBundleDate(selectedOrders)).split('T')[0].split(' ')[0],
          dueDate: formatDateToUTCString(getBundleDate(selectedOrders)).split('T')[0].split(' ')[0] // Example due date
        })
      })
      .then(response => {
        if (response.ok) {
          console.log('Bundle updated successfully');
        } else {
          console.error('Failed to update bundle');
        }
      })
      .catch(error => {
        console.error('Error updating bundle:', error);
      });

      await fetchBundles();
    }

    await updateOrdersCode(orderCodeToSend);

    await fetchOrderData();

    await fetchBundles();

    // Clear selected orders and order code
    setSelectedOrders([]);
    setSelectedOrderCode('new_bundle');

  };

  function updateOrdersCode(orderCodeToSend) {
    return new Promise((resolve, reject) => {
        const promises = selectedOrders.map(async (order) => {
            try {
                const response = await fetch(`${call}/update-order-code`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        order_id: order.order_id,
                        order_code: orderCodeToSend,
                        order_code_old: order.order_code,
                        location_name: locationName
                    }),
                });

                if (response.status === 200) {
                    console.log(`Order ${order.order_id} updated successfully with order code ${orderCodeToSend}`);
                } else {
                    console.error(`Failed to update order ${order.order_id}`);
                }
                return Promise.resolve(); // Resolve the individual promise
            } catch (error) {
                console.error('Error updating order:', error);
                return Promise.reject(error); // Reject the individual promise
            }
        });

        Promise.all(promises)
            .then(() => {
                resolve(); // Resolve the outer promise once all inner promises are resolved
            })
            .catch((error) => {
                reject(error); // Reject the outer promise if any of the inner promises are rejected
            });
    });
  }

  let [editedBundle, setEditedBundle] = useState(null);
  const [editedBundleStatus, setEditedBundleStatus] = useState('Open');

  const handleBundleEdit = async (bundle) => {

    await handleBundleLoadMail(bundle);

    setEditedBundle(bundle);

    if (getBundle(bundle).bundle_date == getBundle(bundle).due_date){
      setDueDateOption('immediate');
    }
    else{
      setDueDateOption('later');
    }

    setDueDate(getFormattedDate(getBundle(bundle).due_date));

    setIsEditModalOpenBundle(true);
    setTrackingNumber(getBundle(bundle).tracking_number);
    if (getBundleStatus(bundle) === 'Shipped'){
      setShipped(true);
    }
    else{
      setShipped(false);
    }
  };

  const handleBundleStatusChange = (e) => {
    const status = e.target.value;

    setEditedBundleStatus(status);

    if (status === 'Shipped' || status === 'Delivered'){
      setDueDateOption('');
    }

    if (status === 'Shipped'){
      setShipped(true);
    }
    else{
      setShipped(false);
    }
  };

  // Function to update the bundle
  const updateBundle = async (bundleDate, orderCode, shipping, taxes, discount, comment) => {
    try {

      // Check if dueDate has the format yyyy-mm-dd and is a valid date
      const dueDateFormat = /^\d{4}-\d{2}-\d{2}$/;
      if (!dueDateFormat.test(dueDate) || isNaN(Date.parse(dueDate))) {
          alert("Due date should be in the format yyyy-mm-dd and must be a valid date.");
          return false; // Return to stop the function
      }
      else if (dueDateOption == 'later' && newDate(dueDate) < newDate(getBundleDate(editedBundle))){
        alert("Due date has to be later than bundle date.");
        return false;
      }

      // Make a PUT request to the API endpoint
      const response = await fetch(`${call}/update-bundle`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bundleDate,
          orderCode,
          shipping,
          taxes,
          discount,
          comment,
          dueDate
        }),
      });

      // Handle the response
      const data = await response.json();

      return true;
    } catch (error) {
      // Handle errors
      console.error('Error updating bundle:', error);
    }
  };

  const getBundle = (bundle) => {
    const orderCode = bundle[0].order_code;

    let response = '';

    try
    {
      response = bundlesData.find(bundle_db => bundle_db.order_code === orderCode);

    }
    catch (error){
      console.log("Error: ", error);
    }

    if (!response){
      response = {order_code: -999, shipping: 0, taxes: 0, bundle_date: '', due_date: ''};
    }

    return response;
  };

  const updateTrackingNumber = async (bundleId, trackingNumber) => {
    try {
      const response = await fetch(`${call}/update-tracking-number`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bundleId, trackingNumber }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update tracking number');
      }
  
      console.log('Tracking number updated successfully');
    } catch (error) {
      console.error('Error updating tracking number:', error.message);
    }
  };

  const updateBundlePaidStatus = async (paidStatus, orderCode) => {
    try {
      const response = await fetch(`${call}/update-bundle-paid-status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ paid_status: paidStatus, order_code: orderCode }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update bundle paid status');
      }
  
      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error('Error updating bundle paid status:', error.message);
      throw error;
    }
  };
  

  const handleBundleSubmit = async () => {

    if (dueDateOption == '' && getBundleStatus(editedBundle) != 'Shipped'){
      alert('Please select a due date option.');
      return;
    }

    if (editedBundleStatus === 'Placed' && getBundleStatus(editedBundle) != 'Placed') {

      if (optionOrder === 'email'){
        handleSubmitPlaced();
      }
      
      const response = await fetch(`${call}/update-order-option`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            order_code: getBundle(editedBundle).order_code,
            order_option: optionOrder
        }),
      
      });

      if (response.status === 200){
        console.log("Order option updated successfully");
      }
      else{
        console.log("Error updating order option");
      }
    }

    if (dueDateOption == 'immediate'){
      dueDate = formatDateToUTCString(getBundleDate(editedBundle)).split('T')[0].split(' ')[0];
    }

    if (shipped){
      if (trackingNumber === '' || trackingNumber === null){
        alert('Enter a tracking number.');
        return;
      }
      updateTrackingNumber(getBundle(editedBundle).order_code, trackingNumber);
    }

    if ((editedBundleStatus === 'Shipped' || editedBundleStatus === 'Delivered') && getBundleStatus(editedBundle) != 'Shipped'){
      if (dueDateOption == 'immediate'){
        await updateBundlePaidStatus(1, getBundle(editedBundle).order_code);
      }
      if (haveToUpload){
        alert('Please upload the invoice.');
        return;
      }

      if (selectedSupplierId === '0'){
        alert('Please select a supplier.');
        return;
      }
    }

    const response = await updateBundle(getBundleDate(editedBundle), getBundle(editedBundle).order_code, editedShippingPrice, editedTaxesPrice, editedDiscount, bundleComment);

    if (response){
      processBundle();
    }

    // Close the modal
    setIsEditModalOpenBundle(false);
    setTrackingNumber('');
    setOptionOrder('platform');
    setEditedTaxesPrice(0);
    setEditedShippingPrice(0);
    setEditedDiscount(0);
    setEditedBundleStatus('Open');
    setBundleComment('');
    setSelectedSupplierId(0);
    fetchOrderData();
    fetchBundles();
  };

  async function processBundle() {
      const e = new Event('submit');

      for (const order of editedBundle) {
          if (order.status_cat !== 'Delivered' && editedBundleStatus != 'Pending') {
              editedOrder = {
                  ...order,
                  status_cat: editedBundleStatus,
                  supplier_id: selectedSupplierId
              };
              await handleEditSubmit(e);
          }
      }
  }

  const getBundleStatus = (bundle) => {

    const full_bundle = orderData.filter(item => item.order_code == bundle[0].order_code)

    const statuses = full_bundle
                    .map(order => order.status_cat);

    const uniqueStatuses = [...new Set(statuses)];
    if (uniqueStatuses.length === 1 && uniqueStatuses.includes('Moved')){
      return 'Delivered';
    }
    else if (uniqueStatuses.length === 1) {
      return uniqueStatuses[0];
    } else if (uniqueStatuses.length === 2 && uniqueStatuses.includes('Moved') && uniqueStatuses.includes('Delivered')){
      return 'Delivered';
    }else {
      return 'Pending';
    }
  };

  const getBundleUrgency = (bundle) => {
    const full_bundle = orderData.filter(item => item.order_code === bundle[0].order_code);

    let highestUrgency = ""; // Set the initial highest urgency to Low

    // Define combined urgency levels in order of priority
    const urgencyLevels = ["", "5+ business days", "3-5 business days", "2-3 business days", "1 business day"];

    full_bundle.forEach(order => {
        let urgency = order.order_urgency;

        // Check if urgency is in the defined levels
        if (urgencyLevels.includes(urgency)) {
            // Update the highest urgency based on the current order's urgency
            if (urgencyLevels.indexOf(urgency) > urgencyLevels.indexOf(highestUrgency)) {
                highestUrgency = urgency;
            }
        }
    });

    return highestUrgency;
};


  const [sortDirection, setSortDirection] = useState(''); // 'asc' or 'desc'
  const [sortBundle, setSortBundle] = useState('desc'); // 'asc' or 'desc'

  const changeSortDirection = (event) => {
    setSortDirection(event.target.value);
  };

  const changeSortBundle = (event) => {
    setSortBundle(event.target.value);
  };

  const getBundleDate = (bundle) => {
    // Extract order_date values from all objects in the bundle
    const full_bundle = orderData.filter(item => item.order_code == bundle[0].order_code)
    const allOrderDates = full_bundle.map(order => order.order_date);
    
    // Find the maximum date from the array of order dates
    const maxDate = new Date(Math.max(...allOrderDates.map(date => new Date(date))));


    return maxDate;
  };

  const getBundleManufacturer = (bundle) => {
    // Count occurrences of each manufacturer name
    const full_bundle = orderData.filter(item => item.order_code == bundle[0].order_code)
    
    const manufacturerCounts = {};
    full_bundle.forEach(order => {
        const manufacturerName = order.manufacturer_name;
        if (manufacturerName !== '') {
            manufacturerCounts[manufacturerName] = (manufacturerCounts[manufacturerName] || 0) + 1;
        }
    });

    // Find the manufacturer name with the highest count
    let mostPresentManufacturer = '';
    let maxCount = 0;
    for (const manufacturerName in manufacturerCounts) {
        if (manufacturerCounts[manufacturerName] > maxCount) {
            maxCount = manufacturerCounts[manufacturerName];
            mostPresentManufacturer = manufacturerName;
        }
    }

    if (Object.keys(manufacturerCounts).length === 1) {
      return mostPresentManufacturer;
    } else {
      return 'Multiple Manufacturers';
    }
  };

  const getBundlePrice = (bundle) => {
    let totalPrice = 0;

    const full_bundle = orderData.filter(item => item.order_code == bundle[0].order_code)

    // Iterate through each order in the bundle
    full_bundle.forEach(order => {
        // Calculate the subtotal for the current order
        const subtotal = order.unit_price * order.order_quantity;

        // Add the subtotal to the total price
        totalPrice += subtotal;
    });

    return parseFloat(totalPrice);
  };


  const sortedFilteredBundles = bundles.slice().sort((a, b) => {
    if (sortDirection === 'desc') {
      return new Date(getBundleDate(b)) - new Date(getBundleDate(a));
    } else if (sortDirection === 'asc') {
      return new Date(getBundleDate(a)) - new Date(getBundleDate(b));
    } else if (sortBundle === 'desc') {
      return b[0].order_code - a[0].order_code;
    } else {
      return a[0].order_code - b[0].order_code;
    }
  });

  const sortedFilteredOrders = filteredOrders.slice().sort((a, b) => {
    if (sortDirection === 'desc') {
      return new Date(b.order_date) - new Date(a.order_date);
    } else {
      return new Date(a.order_date) - new Date(b.order_date);
    }
  });

  const [selectedTab, setSelectedTab] = useState('Bundle');

  const handleTabClick = (tab) => {
      setSearchTerm('');
      setSelectedManufacturer('');
      setSelectedCategory('Open');
      setSelectedOrderUrgency('');
      setSelectedTab(tab);
  };

  // Function to handle due date option change
  const handleDueDateOptionChange = (event) => {
    const option = event.target.value;
    setDueDateOption(option);
  };

  const getCurrentInventory = () => {
        return (
          <>
            <input
                type="text"
                placeholder="Search"
                value={searchTermInventory}
                onChange={e => setSearchTermInventory(e.target.value)}
                style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}
            />
            <select
                value={selectedManufacturerInventory}
                onChange={(e) => setSelectedManufacturerInventory(e.target.value)}
                style={{ padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
                >
                <option value="">All Manufacturers</option>
                {uniqueManufacturers.sort().map(manufacturer => (
                    <option key={manufacturer} value={manufacturer}>
                    {manufacturer}
                    </option>
                ))}
            </select>
            <br/>
            <br/>
            <div style={{ 
                border: '2px solid #87D3D7', 
                display: 'flex', 
                justifyContent: 'left', 
                flexDirection: 'column', 
                width: '100%', 
                maxHeight: '310px', // Set a fixed height
                overflowY: 'auto', // Enable vertical scrolling
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                }}>
                    <thead style={{
                        position: 'sticky', // Stick the header
                        top: 0, // Stick to the top of the table
                        zIndex: 1 // Ensure it stays above the tbody rows
                    }}>
                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                            <th style={{  borderBottom:'2px solid black', padding: '10px', width: '20%' }}>Product Name</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '15%', textAlign: 'center' }}>Description</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Manufacturer</th>
                            <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Category</th>
                            <th style={{ borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center' }}>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredInventory.map((product, index) => (
                            <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                <td style={{  padding: '10px', }}>{product.product_name}</td>
                                <td style={{padding: '10px' ,textAlign: 'center' }}>{product.product_description}</td>
                                <td style={{ padding: '10px', textAlign: 'center'}}>{product.manufacturer_name}</td>
                                <td style={{  padding: '10px', textAlign: 'center'}}>{product.product_cat}</td>
                                <td style={{ padding: '10px' , textAlign: 'center'}}>
                                    <button 
                                        onClick={() => setSelectedProduct(product)}
                                        style={{ backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                    >
                                        Select
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
          </>
        );
  }

  const resetAllFreeProduct = () => {
    setSelectedProduct([]);
    setFreeProductQuantity(0);
    setSelectedManufacturerInventory('');
    setSearchTermInventory('');
  };

  const addFreeQuantity = async (productId, locationName, newQuantity) => {

    if (newQuantity === 0){
      alert('You cannot submit a quantity equal to 0.')
      return;
    }

    let status_order = getBundleStatus(editedBundle);

    if (status_order === 'Pending'){
      status_order = 'Open';
    }

    // Define the order data to be sent in the request body
    const orderData = {
      order_code: getBundle(editedBundle).order_code,
      location_name: locationName,
      product_id: productId,
      order_quantity: newQuantity,
      unit_price: 0,
      vat_percent: 0,
      batch_expiration: null,
      internal_description: 'Free product',
      client_comment: '',
      status_cat: status_order,
      status_rationale: '',
      recommendation_rationale: '',
      due_date: null,
      payment_date: null,
      order_comment: username,
      recommendation_quantity: null,
      order_urgency: '',
      bundle_date: formatDateToUTCString(getBundleDate(editedBundle))
    };

    let lastOrderId = -1;
  
    // Make a POST request to the server endpoint to add a new order
    const response = await fetch(`${call}/add-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderData)
    })
    .then(response => {
      // Check if the response is successful (status code 201)
      if (response.status === 201) {
        // Parse the JSON response
        return response.json();
      } else {
        // If response is not successful, throw an error
        throw new Error('Failed to add new order');
      }
    })
    .then(data => {
      // Extract the newly added order ID from the response data
      lastOrderId = data.order_id;
  
      // Do something with the newly added order ID
      console.log('Newly added order ID:', lastOrderId);
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      console.error('Error:', error.message);
    });
  
    if (lastOrderId != -1){
  
      // Create order tracking
      await fetch(`${call}/store-orders-tracking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          order_id: lastOrderId,
          location_name: locationName,
          product_id: productId,
          order_quantity_before: newQuantity, // Get old order quantity from fetched old data
          order_quantity_after: newQuantity, // Get new order quantity from editedOrder
          unit_price_before: 0, // Get old unit price from fetched old data
          unit_price_after: 0, // Get new unit price from editedOrder
          order_comment_before: '', // Get old internal description from fetched old data
          order_comment_after: username, // Get new internal description from editedOrder
          status_cat_before: 'Free product',
          status_cat_after: status_order,
          change_datetime: '', // Handle in server.js
        }),
      })
      .then(response => response.json())
      .then(result => {
        if (result.message) {
          console.log('Order tracking data inserted successfully');
        } else {
          console.error('Failed to insert order tracking data');
        }
        fetchInventoryData();
        fetchBundles();
        fetchOrderData();
      })
      .catch(error => console.error('Error inserting order tracking data:', error));
    
    }

    resetAllFreeProduct();
  
  };

  async function postFile({ file }) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("filename", "Bundles-invoices/" + fileName);

      const requestOptions = {
        method: 'POST',
        body: formData
      };
    
      try {
        const response = await fetch(`${call}/upload-files`, requestOptions);
        if (!response.ok) {
          throw new Error('Failed to upload image');
        }
        
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error; // Propagate the error further
      }
    }
    

  const submitUploadInvoice = async event => {
      event.preventDefault();
      try {
          const result = await postFile({ file: file });

          // Check if the result contains a message indicating success
          if (result.message === 'File uploaded successfully') {
              // Show success toast
              toast.success('File uploaded successfully');
              setHaveToUpload(false);
          } else {
              // Show error toast if the message is not as expected
              toast.error('Failed to upload file');
          }

          setModalUploadOpen(false);
          setFile('');
          await fetchFiles();

      } catch (error) {
          console.error('Error uploading file:', error);
          // Show error toast for any other errors
          toast.error('Failed to upload file');
      }
  };

  const fileSelected = event => {
      let file = event.target.files[0]
      setFile(file)
  }

  const fetchFile = async (filename) => {
      try {
        const key = "Bundles-invoices/" + filename; // Replace 'your-file-key' with the actual key
        const response = await fetch(`${call}/get-file`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ key }), // Include the key in the request body as JSON
        });
    
        if (!response.ok) {
          toast.error("Failed to fetch file");
          throw new Error('Failed to fetch file');
        }

        toast.success("File fetched successfully");
    
        // Trigger download when fetch is successful
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.pdf`; // Set the download filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    
        console.log('File downloaded successfully');
      } catch (error) {
        console.error('Error downloading file:', error);
      }
  };

  const checkFileExists = (fileName) => {
      return bundlesInvoices.includes(fileName);
  };

  // Fetch suppliers data
  const fetchSuppliersData = async () => {
    try {
        const response = await fetch(`${call}/get-all-suppliers`);
        if (!response.ok) {
            throw new Error('Failed to fetch suppliers data');
        }
        const data = await response.json();

        setSuppliersData(Array.from(new Set(data
          .filter(item => item.supplier_name !== null) // Filter out null values
          .map(item => item))));
    } catch (error) {
        console.error('Error fetching suppliers data:', error);
    }
  };

  function getSupplierId(bundle) {
      if (bundle.length === 0) return 0; // Return empty string if bundle is empty

      const firstSupplierId = bundle[0].supplier_id; // Get the supplier_id of the first order

      // Check if all orders in the bundle have the same supplier_id
      for (let i = 1; i < bundle.length; i++) {
          if (bundle[i].supplier_id !== firstSupplierId) {
              return 0; // Return empty string if supplier_ids are not all the same
          }
      }

      return firstSupplierId; // Return the supplier_id if all orders have the same supplier_id
  }

  // Function to get the appropriate content based on the order code
  function renderOrderCodeContent(orderCode) {
    switch (orderCode) {
      case 0:
        return <FontAwesomeIcon icon={faTrash} />;
      case -1:
        return 'Recommended';
      case -2:
        return 'Free Products';
      case -3:
        return 'Declined';
      case -4:
        return 'Web';
      case -5:
        return 'Rewards';
      case -999:
        return 'Errors';
      default:
        return null; // Or some default content if necessary
    }
  }

  const handleUpdateComment = (order) => {
    setEditedOrder(order);
    setIsUpdateCommentModalOpen(true);
  };

  const handleUpdateCommentSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    try {
      if (editedOrder) {
        const { unit_price, order_quantity, order_id, internal_description, status_cat, supplier_id } = editedOrder;
  
        // Make a PUT request to update the order's internal description
        const response = await fetch(`${call}/update-order/${order_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            unit_price,
            order_quantity,
            internal_description,
            status_cat,
            supplier_id
          }),
        });
  
        if (!response.ok) {
          throw new Error('Error updating order details');
        }
  
        // Handle success response (you can show a success message if needed)
        console.log('Order comment updated successfully');
        setIsUpdateCommentModalOpen(false); // Close the update comment modal
        setEditedOrder(null); // Clear the edited order data
        fetchOrderData(); // Refresh the order data after the update
      }
    } catch (error) {
      // Handle errors, you can show an error message if needed
      console.error('Error updating order details:', error);
    }
  };
  
  
  return (

    <div ref={contentRef} style={{ textAlign: 'left', margin: '20px' }}>
      <ToastContainer/>
      <h1 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7'}}>Orders Backend</h1>
      
      <div>
          <span 
            onClick={() => handleTabClick('Order')}
            style={{
              marginBottom: '15px',
              color: '#000000',
              borderBottom: selectedTab === 'Order' ? ' 2px solid black' : ' 2px solid transparent',
              fontSize: '30px', // Updated font size
              fontWeight: 'bold', // Added font weight
              cursor: 'pointer' // Set cursor to pointer
            }}
            >
            Orders
          </span>

          <span 
            onClick={() => handleTabClick('Bundle')}
            style={{
              marginLeft: '15px',
              color: '#000000',
              borderBottom: selectedTab === 'Bundle' ? ' 2px solid black' : ' 2px solid transparent',
              fontSize: '30px', // Updated font size
              fontWeight: 'bold', // Added font weight
              cursor: 'pointer' // Set cursor to pointer
            }}
            >
              Bundles
            </span>
      </div>
  
      <br/>
      <br/>

      <div>
        <select
          value={locationName}
          onChange={(e) => {setLocationName(e.target.value); setExpandedBundles([]); setSearchTerm('');}}
          style={{padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        >
          {locations.sort().map((location, index) => (
            <option key={index} value={location.location_name}>
              {location.location_name}
            </option>
          ))}
        </select>
        <select
          value={sortDirection}
          onChange={changeSortDirection}
          style={{ marginLeft: '20px', padding: '10px', fontStyle: 'italic', width: '150px' }}
        >
          <option value="">Sort Date</option>
          <option value="asc">Date: Ascending</option>
          <option value="desc">Date: Descending</option>
        </select>
        {selectedTab === 'Bundle' && (
          <>
            <select
              value={sortBundle}
              onChange={changeSortBundle}
              style={{ marginLeft: '20px', padding: '10px', fontStyle: 'italic', width: '150px' }}
            >
              <option value="">Sort Bundle</option>
              <option value="asc">Bundle: Ascending</option>
              <option value="desc">Bundle: Descending</option>
            </select>
          </>
        )}
      </div>
      <br/>

      {selectedTab === 'Bundle' && (
        <div>
          <h3>Bundles of Orders</h3>

          <div style={{display: 'flex', justifyContent: 'left', marginBottom: '20px'  }}>
            {/* Search bar */}

            <input
              type="text"
              placeholder="Search Product Name"
              value={searchTerm}
              onChange={handleSearchTermChange}
              style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}
            />
            {/* Category selection */}
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
              <option value="">All Categories</option>
              {orderStatusCategories.sort().map((category, index) => (
                <option key={index} value={category}>
                  {category.charAt(0).toUpperCase() + category.slice(1)} {/* Capitalize first letter */}
                </option>
              ))}
            </select>
      

            <select
              value={selectedOrderUrgency}
              onChange={handleOrderUrgencyChange}
              style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
              <option value="">All Urgencies</option>
              {orderUrgencyLevels.sort().map((urgency, index) => (
                <option key={index} value={urgency}>
                  {urgency}
                </option>
              ))}
            </select>

            <select
              value={selectedManufacturer}
              onChange={(e) => setSelectedManufacturer(e.target.value)}
              style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
              <option value="">All Manufacturers</option>
              {uniqueManufacturers.sort().map(manufacturer => (
                <option key={manufacturer} value={manufacturer}>
                  {manufacturer}
                </option>
              ))}
            </select>
          </div>


          <div style={{display: 'flex', justifyContent: 'left', marginBottom: '20px'  }}>

            <select value={selectedOrderCode} onChange={handleDropdownChange}
                style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
              >
                <option value="new_bundle">New Bundle</option>
                {bundles.sort().map((bundle, index) => {
                  if (getBundleStatus(bundle) !== 'Delivered' && bundle[0].order_code !== null) {
                    return (
                      <option key={index} value={bundle[0].order_code}>
                        {bundle[0].order_code !== 0 ? (<>{bundle[0].order_code}</>) : (<>Trash</>)}
                      </option>
                    );
                  }
                  return null;
                })}

            </select>

            <button 
                onClick={handleApplyClick}
                style={{ 
                    backgroundColor: '#87D3D7', 
                    color: '#fff', 
                    border: 'none', 
                    padding: '8px 12px', 
                    cursor: 'pointer', 
                    borderRadius: '8px',
                }} 
            >
                Apply
            </button>

          </div>
          
          <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
            <table style={{  
      
              width: '100%', 
              borderCollapse: 'separate', 
              borderSpacing: 0, 
              position: 'relative' // Ensure positioning context
            }}>
              <thead style={{
                position: 'sticky', // Stick the header
                top: 0, // Stick to the top of the table
                
                zIndex: 1 // Ensure it stays above the tbody rows
              }}>
                <tr style={{ fontSize: '14px', backgroundColor: 'white'  }}>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}></th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Code</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Date</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Last Update</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Status Update</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Bundle Comment</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Manufacturer</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Total Product</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Shipping</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Taxes</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Discount</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Total</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Tracking Number</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Bundle Status</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Method</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Bundle Urgency</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Due Date</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Free Product</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Edit</th>
                  <th style={{ borderBottom:'2px solid black', padding: '10px' }}>Download</th>
                </tr>
              </thead>
              <tbody>
                {sortedFilteredBundles.map((bundle, index) => (
                  <React.Fragment key={index}>
                    <tr style={{ fontSize: '14px', cursor: 'pointer', border: '1px solid #ddd', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                      <td 
                          onClick={() => handleBundleClick(bundle[0].order_code)} 
                          style={{ textAlign: 'center' }}
                      >
                          <span>
                              {expandedBundles.includes(bundle[0].order_code) ? 
                                  <FontAwesomeIcon icon={faAngleUp} /> :
                                  <FontAwesomeIcon icon={faAngleDown} />
                              }
                          </span>
                      </td>
                      {getBundle(bundle).order_code > 0 ? (
                        <>
                          <td onClick={() => handleBundleClick(getBundle(bundle).order_code)} style={{ padding: '10px', textAlign: 'center'  }}>{getBundle(bundle).order_code}</td>
                          <td onClick={() => handleBundleClick(getBundle(bundle).order_code)} style={{ padding: '10px' }}>{getBundleDate(bundle) && formatDateTimeText(getBundleDate(bundle))}</td>
                          <td style={{ padding: '10px' }}>{(getBundle(bundle).last_update_user && getBundle(bundle).last_update_time)  && `${formatDateTimeText(formatToUTC(getBundle(bundle).last_update_time))} by ${getBundle(bundle).last_update_user} from ${getBundle(bundle).status_cat_before} to ${getBundle(bundle).status_cat_after}`}</td> 
                          <td style={{ padding: '10px' }}>{(getBundle(bundle).last_update_user && getBundle(bundle).first_status_update_time)  && `In ${getBundle(bundle).status_cat_after} since ${formatDateTimeText(formatToUTC(getBundle(bundle).first_status_update_time))}`}</td> 
                          <td style={{ padding: '10px' }}>{getBundle(bundle).bundle_comment}</td>
                          <td style={{ padding: '10px' }}>{getBundleManufacturer(bundle)}</td>
                          <td style={{ padding: '10px' }}>${getBundlePrice(bundle).toFixed(2)}</td>
                          <td style={{ padding: '10px' }}>${getBundle(bundle).shipping.toFixed(2)}</td>
                          <td style={{ padding: '10px' }}>${getBundle(bundle).taxes.toFixed(2)}</td>
                          <td style={{ padding: '10px' }}>${getBundle(bundle).discount !== null ? (parseFloat(getBundle(bundle).discount).toFixed(2)) : (0)}</td>
                          <td style={{ padding: '10px' }}>${parseFloat(getBundlePrice(bundle) + getBundle(bundle).shipping + getBundle(bundle).taxes - getBundle(bundle).discount).toFixed(2)}</td>
                          <td style={{ padding: '10px' }}>{getBundle(bundle).tracking_number}</td>
                          <td style={{ padding: '10px' }}>{getBundleStatus(bundle)}</td>
                          <td style={{ padding: '10px' }}>{getBundle(bundle).order_option && getBundle(bundle).order_option}</td>
                          <td style={{ padding: '10px' }}>{getBundleUrgency(bundle)}</td>
                          <td style={{ padding: '10px' }}>{formatDateText(getBundle(bundle).due_date)}</td>
                          <td style={{ padding: '10px' }}>
                            {/* Button to edit the bundle */}
                            <button 
                                onClick={() => {
                                  setIsEditModalOpenFreeProduct(true);
                                  setEditedBundle(bundle);
                                }}
                                style={{ 
                                    backgroundColor: '#87D3D7', 
                                    color: '#fff', 
                                    border: 'none', 
                                    padding: '8px 12px', 
                                    cursor: 'pointer', 
                                    borderRadius: '8px',
                                    // Disable button if invoice status is not 'Open'
                                    pointerEvents: getBundleStatus(bundle) === 'Delivered' ? 'none' : 'auto',
                                    opacity: getBundleStatus(bundle) === 'Delivered' ? 0.5 : 1 // Optional: Dim the button when disabled
                                }} 
                                disabled={getBundleStatus(bundle) === 'Delivered'} // Optional: Alternative approach for disabling the button
                            >
                                Add
                            </button>
                          </td>
                          <td style={{ padding: '10px' }}>
                            {/* Button to edit the bundle */}
                            <button 
                                onClick={() => {
                                  setBundleComment(getBundle(bundle).bundle_comment);
                                  setEditedTaxesPrice(getBundle(bundle).taxes);
                                  setEditedShippingPrice(getBundle(bundle).shipping);
                                  setEditedDiscount(getBundle(bundle).discount);
                                  setEditedBundleStatus(getBundleStatus(bundle));
                                  setHaveToUpload(!checkFileExists("Bundles-invoices/bundle_id_" + getBundle(bundle).order_code));
                                  setSelectedSupplierId(getSupplierId(bundle));
                                  handleBundleEdit(bundle);
                                }}
                                style={{ 
                                    backgroundColor: '#87D3D7', 
                                    color: '#fff', 
                                    border: 'none', 
                                    padding: '8px 12px', 
                                    cursor: 'pointer', 
                                    borderRadius: '8px',
                                    // Disable button if invoice status is not 'Open'
                                    pointerEvents: getBundleStatus(bundle) === 'Delivered' ? 'auto' : 'auto',
                                    opacity: getBundleStatus(bundle) === 'Delivered' ? 1 : 1 // Optional: Dim the button when disabled
                                }} 
                                // disabled={getBundleStatus(bundle) === 'Delivered'} // Optional: Alternative approach for disabling the button
                            >
                                Edit
                            </button>
                          </td>
                          <td style={{ padding: '10px' }}>
                              {checkFileExists("Bundles-invoices/bundle_id_" + getBundle(bundle).order_code) ? (
                                  <button 
                                    onClick={() => {
                                      fetchFile("bundle_id_" + getBundle(bundle).order_code);
                                    }}
                                  
                                    style={{ 
                                        backgroundColor: '#87D3D7', 
                                        color: '#fff', 
                                        border: 'none', 
                                        padding: '8px 12px', 
                                        cursor: 'pointer', 
                                        borderRadius: '8px'
                                    }} 
                                  >
                                      Download
                                  </button>
                              )
                              :
                              (
                                  <></>
                              )
                              }
                          </td>
                        </>
                      ) :
                      (
                        <>
                          <td onClick={() => handleBundleClick(getBundle(bundle).order_code)} style={{ padding: '10px', textAlign: 'center' }}>{renderOrderCodeContent(getBundle(bundle).order_code)}</td>
                          <td onClick={() => handleBundleClick(getBundle(bundle).order_code)} style={{ padding: '10px' }}>{getBundleDate(bundle) && formatDateTimeText(getBundleDate(bundle))}</td>
                          <td colSpan="17">
                          </td>
                        </>
                      )
                      }
                    </tr>
                    {expandedBundles.includes(bundle[0].order_code) &&  
                        <tr>
                          {/* Render order details */}
                          <td colSpan="1"></td>
                          <td colSpan="19">
                            <div style={{ border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden' }}>
                              <table style={{  
                                
                                width: '100%', 
                                borderCollapse: 'separate', 
                                borderSpacing: 0, 
                                position: 'relative' // Ensure positioning context
                              }}>
                                <thead style={{
                                  position: 'sticky', // Stick the header
                                  top: 0, // Stick to the top of the table
                                }}>
                                  <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Select</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Date</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Name</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Description</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Manufacturer</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Supplier</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Unit Price</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Quantity</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Price</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Internal Description</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Client Comment</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Comment</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Status</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Urgency</th>
                                    <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Edit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {bundle.map((order, orderIndex) => (
                                  <tr key={orderIndex} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: orderIndex % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                    <td style={{ padding: '10px' }}>
                                      {(getBundleStatus(bundle) != 'Delivered' && getBundleStatus(bundle) != 'Shipped') ? (
                                          <input
                                            type="checkbox"
                                            checked={selectedOrders.includes(order)}
                                            onChange={() => handleOrderCheckboxChange(order)}
                                            style={{ width: '20px', height: '20px', alignItems: 'center' }}
                                          />
                                        )
                                        :
                                        (
                                          <span></span>
                                        )
                                      }
                                    </td>
                                    <td style={{ padding: '10px' }}>{order.order_date && formatDateTimeText(order.order_date)}</td>
                                    <td style={{ padding: '10px' }}>{order.product_name}</td>
                                    <td style={{ padding: '10px' }}>{order.product_description}</td>
                                    <td style={{ padding: '10px' }}>{order.manufacturer_name}</td>
                                    <td style={{ padding: '10px' }}>{suppliersData.find(item => item.supplier_id == order.supplier_id) && suppliersData.find(item => item.supplier_id == order.supplier_id).supplier_name}</td>
                                    <td style={{ padding: '10px' }}>${order.unit_price}</td>
                                    <td style={{ padding: '10px' }}>{order.order_quantity}</td>
                                    <td style={{ padding: '10px' }}>${parseFloat(order.order_quantity * order.unit_price).toFixed(2)}</td>
                                    <td style={{ padding: '10px' }}>{order.internal_description}</td>
                                    <td style={{ padding: '10px' }}>{order.client_comment}</td>
                                    <td style={{ padding: '10px' }}>{order.order_comment}</td>
                                    <td style={{ padding: '10px' }}>{order.status_cat}</td>
                                    <td style={{ padding: '10px' }}>{order.order_urgency}</td>
                                    <td style={{ padding: '10px' }}>

                                    <td style={{ padding: '10px' }}>
                                      {order.status_cat === 'Delivered' ? (
                                        <button
                                          onClick={() => handleUpdateComment(order)}
                                          style={{
                                            backgroundColor: '#87D3D7',
                                            color: '#fff',
                                            border: 'none',
                                            padding: '8px 12px',
                                            cursor: 'pointer',
                                            borderRadius: '8px'
                                          }}
                                        >
                                          Edit
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            handleEditOrder(order);
                                          }}
                                          style={{
                                            backgroundColor: '#87D3D7',
                                            color: '#fff',
                                            border: 'none',
                                            padding: '8px 12px',
                                            cursor: 'pointer',
                                            borderRadius: '8px',
                                            pointerEvents: (order.status_cat === 'Moved') ? 'none' : 'auto',
                                            opacity: (order.status_cat === 'Moved') ? 0.5 : 1
                                          }}
                                          disabled={order.status_cat === 'Moved'}
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </td>
                                    </td>
                                  </tr>))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                        }
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        )}
      
      {selectedTab === 'Order' && (
        <div>
          <h3>All Orders</h3>

          <div style={{display: 'flex', justifyContent: 'left', marginBottom: '20px'  }}>
            {/* Search bar */}

            <input
              type="text"
              placeholder="Search Product Name"
              value={searchTerm}
              onChange={handleSearchTermChange}
              style={{marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}
            />
            {/* Category selection */}
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
              <option value="">All Categories</option>
              {orderStatusCategories.sort().map((category, index) => (
                <option key={index} value={category}>
                  {category.charAt(0).toUpperCase() + category.slice(1)} {/* Capitalize first letter */}
                </option>
              ))}
            </select>
      
            <select
              value={selectedOrderUrgency}
              onChange={handleOrderUrgencyChange}
              style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
              <option value="">All Urgencies</option>
              {orderUrgencyLevels.sort().map((urgency, index) => (
                <option key={index} value={urgency}>
                  {urgency}
                </option>
              ))}
            </select>

            <select
              value={selectedManufacturer}
              onChange={(e) => setSelectedManufacturer(e.target.value)}
              style={{marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
              <option value="">All Manufacturers</option>
              {uniqueManufacturers.sort().map(manufacturer => (
                <option key={manufacturer} value={manufacturer}>
                  {manufacturer}
                </option>
              ))}
            </select>
          </div>
          
          <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
            <table style={{  
      
              width: '100%', 
              borderCollapse: 'separate', 
              borderSpacing: 0, 
              position: 'relative' // Ensure positioning context
            }}>
              <thead style={{
                position: 'sticky', // Stick the header
                top: 0, // Stick to the top of the table
                
                zIndex: 1 // Ensure it stays above the tbody rows
              }}>
                <tr style={{ fontSize: '12px', backgroundColor: 'white' }}>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Date</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Name</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Product Description</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Manufacturer Name</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Unit Price</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Quantity</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Price</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Internal Description</th> 
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Client Comment</th> 
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Comment</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Status</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Order Urgency</th>
                  <th style={{ borderBottom:'1px solid black', padding: '10px' }}>Edit</th>
                </tr>
                </thead>
                <tbody>
                {sortedFilteredOrders.map((order, index) => (
                  <tr key={index} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                    <td style={{ padding: '10px' }}>{order.order_date && formatDateTimeText(order.order_date)}</td>
                    <td style={{ padding: '10px' }}>{order.product_name}</td>
                    <td style={{ padding: '10px' }}>{order.product_description}</td>
                    <td style={{ padding: '10px' }}>{order.manufacturer_name}</td>
                    <td style={{ padding: '10px' }}>${order.unit_price}</td>
                    <td style={{ padding: '10px' }}>{order.order_quantity}</td>
                    <td style={{ padding: '10px' }}>${parseFloat(order.order_quantity * order.unit_price).toFixed(2)}</td>
                    <td style={{ padding: '10px' }}>{order.internal_description}</td>
                    <td style={{ padding: '10px' }}>{order.client_comment}</td>
                    <td style={{ padding: '10px' }}>{order.order_comment}</td>
                    <td style={{ padding: '10px' }}>{order.status_cat}</td>
                    <td style={{ padding: '10px' }}>{order.order_urgency}</td>
                    <td style={{ padding: '10px' }}>
                      <button 
                        onClick={() => {
                          handleEditOrder(order);
                        }}
                        style={{ 
                            backgroundColor: '#87D3D7', 
                            color: '#fff', 
                            border: 'none', 
                            padding: '8px 12px', 
                            cursor: 'pointer', 
                            borderRadius: '8px',
                            // Disable button if invoice status is not 'Open'
                            pointerEvents: (order.status_cat === 'Delivered' || order.status_cat === 'Moved') ? 'none' : 'auto',
                            opacity: (order.status_cat === 'Delivered' || order.status_cat === 'Moved') ? 0.5 : 1 // Optional: Dim the button when disabled
                        }} 
                        disabled={(order.status_cat === 'Delivered' || order.status_cat === 'Moved')} // Optional: Alternative approach for disabling the button
                      >
                          Edit
                      </button>
                    </td>
                  </tr>
                  ))}
                </tbody>
            </table>
          </div>
        </div>
      )}

      <br/>
      
      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={isEditModalOpen}
        onRequestClose={() => {setOptionPrice("unit_price"); setTotalOrderPrice(0); setIsEditModalOpen(false);}}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 2
          },
        }}
      >
        <h3>Edit Order</h3>
        {editedOrder && (
          <div>
            <form onSubmit={handleEditSubmit}>
              {(editedOrder.status_cat !== 'Shipped' && editedOrder.status_cat !== 'Delivered') && (
                <>
                  <div className="input-group">

                    <label>
                      Order Price:
                      <select
                        value={optionPrice}
                        onChange={(e) => setOptionPrice(e.target.value)}
                        >
                        <option value="unit_price">Unit Price</option>
                        <option value="total_price">Total Price</option>
                      </select>
                    </label>

                    { optionPrice === 'unit_price' ? 
                    
                      (
                        <label>
                          <i style={{fontSize: '12px'}}>Unit Price:</i>
                          <input
                            type="number"
                            value={editedOrder.unit_price}
                            onChange={(e) =>
                              setEditedOrder({
                                ...editedOrder,
                                unit_price: e.target.value,
                              })
                            }
                          />
                        </label>
                      )
                      :
                      (
                        <label>
                          <i style={{fontSize: '12px'}}>Total Price:</i>
                          <input
                            type="number"
                            value={totalOrderPrice}
                            onChange={(e) =>
                              setTotalOrderPrice(e.target.value)
                            }
                          />
                        </label>
                      )
                    }
                  </div>
                  <div className="input-group">
                    <label>
                      Order Quantity:
                      <input
                        type="number"
                        value={editedOrder.order_quantity}
                        onChange={(e) =>
                          setEditedOrder({
                            ...editedOrder,
                            order_quantity: e.target.value,
                          })
                        }
                      />
                    </label>
                  </div>
                </>
              )}
              <div className="input-group">
                <label>
                  Internal Description:
                  <textarea
                    value={editedOrder.internal_description}
                    onChange={(e) =>
                      setEditedOrder({
                        ...editedOrder,
                        internal_description: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div className="input-group">
                <label htmlFor="status">
                  Status:
                  {editedOrder.status_cat !== 'Shipped' ? (
                    <>
                      <select id="status" value={editedOrder.status_cat} onChange={(e) => setEditedOrder({ ...editedOrder, status_cat: e.target.value })}
                        disabled={editedOrder.product_cat === 'web'}
                      >
                        {orderStatusCategories.sort().map(status => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </>
                  )
                  :
                  (
                    <>
                      <select id="status" value={editedOrder.status_cat} onChange={(e) => setEditedOrder({ ...editedOrder, status_cat: e.target.value })}>
                          <option value="Shipped">Shipped</option>
                          <option value="Delivered">Delivered</option>
                      </select>
                    </>
                  )
                  }
                </label>
              </div>

              <div className="input-group">
                <button 
                  type="submit"
                  style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                  onClick={() => 
                    {
                      if (optionPrice !== 'unit_price'){
                        setEditedOrder({
                          ...editedOrder,
                          unit_price: totalOrderPrice / editedOrder.order_quantity,
                        })
                      }
                    }
                  }
                >
                  Submit
                </button>
                <button 
                  onClick={() => {setOptionPrice("unit_price"); setTotalOrderPrice(0); setIsEditModalOpen(false);}}
                  style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        )}
      </Modal>

      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={isEditModalOpenFreeProduct}
        onRequestClose={() => {resetAllFreeProduct(); setEditedBundle(null); setIsEditModalOpenFreeProduct(false);}}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 2
          },
          content: {
              width: '60%',
              height: '70%',
          }
        }}
      >
        <h3>Add Free Product</h3>
        
        <>
          {getCurrentInventory()}
          <br/>
          { selectedProduct.length != 0 ? 
          (
            <>
              <h4>{selectedProduct.product_name}</h4>
              <div className="input-group">
                Quantity:
                <input
                  type="number"
                  value={freeProductQuantity}
                  onChange={(e) => setFreeProductQuantity(parseFloat(e.target.value))}
                  style={{width:'20%', marginLeft: '20px', marginRight: '20px'}}
                />
                <button 
                    onClick={() => addFreeQuantity(selectedProduct.product_id, locationName, parseFloat(freeProductQuantity))}
                    style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                    Submit
                </button>
              </div>
            </>
          )
          :
          (<><h4>No Product Selected.</h4></>)
          }
        </>

      </Modal>


      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={isEditModalOpenBundle}
        onRequestClose={() => {setTrackingNumber(''); setOptionOrder('platform'); setSelectedSupplierId(0); setIsEditModalOpenBundle(false);}}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 2
          },
          content: {
              width: '30%',
              height: '90%'
          }
        }}
      >
        <h3>Edit Bundle</h3>
        {editedBundle && (
          <div style={{ maxHeight: '650px', maxWidth: '450px', overflowY: 'auto' }}>
            <form>

                {(getBundleStatus(editedBundle) === 'Shipped' || getBundleStatus(editedBundle) === 'Delivered') ? (
                  <div className="input-group">
                    <label htmlFor="bundleStatus">Status:</label>
                    <select
                      id="bundleStatus"
                      value={editedBundleStatus}
                      onChange={handleBundleStatusChange}
                      disabled = {getBundleStatus(editedBundle) === 'Delivered'}
                    >
                      <option value="Shipped">Shipped</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                    <label htmlFor="discount">Comment:</label>
                    <input
                      type="text"
                      id="comment"
                      value={bundleComment}
                      onChange={(e) => setBundleComment(e.target.value)}
                    />
                    {shipped && (
                      <div>
                        <label htmlFor="trackingNumber">Tracking Number:</label>
                        <input 
                          type="text"
                          id="trackingNumber" 
                          value={trackingNumber} 
                          onChange={(e) => {
                            setTrackingNumber(e.target.value); 
                          }} 
                        />
                      </div>
                    )}
                    
                  </div>
                  
                ) :
                (
                <>
                  <div className="input-group">
                    <label htmlFor="bundleStatus">Status:</label>
                    <select
                      id="bundleStatus"
                      value={editedBundleStatus}
                      onChange={handleBundleStatusChange}
                    >
                      {bundleStatusCategories.sort().map(status => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                    {shipped && (
                      <div>
                        <label htmlFor="trackingNumber">Tracking Number:</label>
                        <input 
                          type="test"
                          id="trackingNumber" 
                          value={trackingNumber} 
                          onChange={(e) => {
                            setTrackingNumber(e.target.value); 
                          }} 
                        />
                      </div>
                    )}
                  </div>
                  <div className="input-group">
                    <label htmlFor="shippingPrice">Shipping Price:</label>
                    <input
                      type="number"
                      id="shippingPrice"
                      value={editedShippingPrice}
                      onChange={(e) => setEditedShippingPrice(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                  <label htmlFor="taxesPrice">Taxes Price:</label>
                    <input
                      type="number"
                      id="taxesPrice"
                      value={editedTaxesPrice}
                      onChange={(e) => setEditedTaxesPrice(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                  <label htmlFor="discount">Discount:</label>
                    <input
                      type="number"
                      id="discount"
                      value={editedDiscount}
                      onChange={(e) => setEditedDiscount(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="discount">Comment:</label>
                    <input
                      type="text"
                      id="comment"
                      value={bundleComment}
                      onChange={(e) => setBundleComment(e.target.value)}
                    />
                  </div>
                  {editedBundleStatus === 'Placed' && getBundleStatus(editedBundle) != 'Placed' && (
                      <div className="input-group">
                        <label htmlFor="dueDateOption">Order Option:</label>
                        <select
                          value={optionOrder}
                          onChange={(e) => setOptionOrder(e.target.value)}
                        >
                          <option value="platform">Platform</option>
                          <option value="email">Email</option>
                          <option value="phone">Phone</option>
                        </select>
                        { optionOrder === 'email' && (
                          <>
                            <label htmlFor="emailAddress">Recipient Email:</label>
                            <input
                                type="email"
                                id="emailAddress"
                                value={emailData && emailData.contact_email}
                                onChange={(e) => setEmailData({ ...emailData, contact_email: e.target.value })}
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="emailAddress">Subject Email:</label>
                            <input
                                type="email"
                                id="emailAddress"
                                value={emailData && emailData.subject}
                                onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
                                style={{ width: '100%' }}
                            />
                            <label htmlFor="emailMessage">Edit Email:</label>
                            <ContentEditable
                                html={emailData.message} // Use "html" prop instead of "value"
                                onChange={(e) => { setEmailData({ ...emailData, message: e.target.value }); }} // Handle changes
                                style={{ 
                                    width: '100%', 
                                    maxHeight: '120px', 
                                    padding: '5px', 
                                    overflowY: 'auto',
                                    backgroundColor: '#fff', // Add white background color
                                    marginTop: '10px',
                                    marginBottom: '10px'
                                }}
                            />
                          </>
                          )
                        }
                        <div className="input-group">
                          <label htmlFor="emailAddress">Supplier:</label>
                          <select
                            value={selectedSupplierId}
                            onChange={(e) => setSelectedSupplierId(e.target.value)}
                            >
                            <option value="0">All suppliers</option>
                            {suppliersData.sort((a, b) => a.supplier_name.localeCompare(b.supplier_name)).map(supplier => (
                                <option key={supplier.supplier_id} value={supplier.supplier_id}>
                                {supplier.supplier_name}
                                </option>
                            ))}
                          </select>
                        </div>
                      </div>
                  )}

                  { (editedBundleStatus === 'Shipped' || editedBundleStatus === 'Delivered') &&
                    <>
                      <div className="input-group">
                        <label htmlFor="dueDateOption">Due Date Option:</label>
                        <select
                          id="dueDateOption"
                          value={dueDateOption}
                          onChange={handleDueDateOptionChange}
                        >
                          <option value="">Select Option</option>
                          <option value="immediate">Immediate</option>
                          <option value="later">Later</option>
                        </select>
                        {dueDateOption == 'later' && (
                          <div>
                            <label htmlFor="dueDate">Due Date:</label>
                            <input 
                              type="date"
                              id="dueDate" 
                              value={dueDate} 
                              onChange={(event) => {
                                const formattedDate = event.target.value.replace(/[^0-9-]/g, ''); 
                                setDueDate(formattedDate); 
                              }} 
                              placeholder="YYYY-MM-DD"
                            />
                          </div>
                        )}
                      </div>
                      { haveToUpload && (
                        <>
                          <div className="input-group">
                            <label htmlFor="emailAddress">Upload Invoice:</label>
                            <br/>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setFileName("bundle_id_" + getBundle(editedBundle).order_code);
                                setModalUploadOpen(true);
                              }}
                              style={{
                                fontSize: '14px',
                                display: 'inline-block',
                                marginTop: '10px',
                                padding: '4px 10px',
                                backgroundColor: '#31bac1',
                                color: '#fff',
                                border: '3px solid #31bac1',
                                borderRadius: '8px',
                                textDecoration: 'none', // Remove underline from anchor tag
                                cursor: 'pointer',
                                marginBottom: '10px'
                              }}
                            >
                              Upload
                            </a>
                          </div>
                        </>
                      )}
                    </>
                  }
                </>
                )}

                <div className="input-group">
                  <button 
                    type="button" 
                    onClick={handleBundleSubmit}
                    style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                  >
                    Submit
                  </button>
                  <button 
                    type="button" 
                    onClick={() => {setTrackingNumber(''); setOptionOrder('platform'); setSelectedSupplierId(0); setIsEditModalOpenBundle(false);}}
                    style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                  >
                    Cancel
                  </button>
              </div>
            </form>
          </div>
        )}
      </Modal>

      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={modalUploadOpen}
        onRequestClose={() => {setModalUploadOpen(false); setFile('');} }
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2,
            },
            content: {
                width: '20%',
                height: '30%',
                textAlign: 'center'
            }
        }}
    >
        <form onSubmit={submitUploadInvoice}>
            <div style={{ marginTop: '-15px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <h3>Upload File</h3>

                <input
                    type="file"
                    accept="*"
                    onChange={fileSelected}
                    style={{ display: 'none' }} // Hide the actual file input
                    id="fileInput" // Assign an ID for the label to reference
                />
                <label htmlFor="fileInput" 
                    style={{ marginBottom: '30px', border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                >
                    Choose File
                </label>
                {file && 
                    <>
                        <br/>
                        <br/>
                        <span>{file.name}</span>
                        <br/>
                        <br/>
                        <button 
                            type="submit"
                            style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            Submit
                        </button>
                    </>
                }
            </div>
            
            
        </form>
    </Modal>  

    <Modal
      overlayClassName="modal-overlay"
      className="modal-content"
      isOpen={isUpdateCommentModalOpen}
      onRequestClose={() => setIsUpdateCommentModalOpen(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 2
        },
      }}
    >
      <h3>Update Comment</h3>
      {editedOrder && (
        <div>
          <form onSubmit={handleUpdateCommentSubmit}>
            <div className="input-group">
              <label>
                Internal Description:
                <textarea
                  value={editedOrder.internal_description}
                  onChange={(e) =>
                    setEditedOrder({
                      ...editedOrder,
                      internal_description: e.target.value,
                    })
                  }
                />
              </label>
            </div>
            <div className="input-group">
              <button 
                type="submit"
                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
              >
                Submit
              </button>
              <button 
                onClick={() => setIsUpdateCommentModalOpen(false)}
                style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Modal>               


    </div>
  );
};

export default Automation;