import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tick from '../assets/images/green-tick.png';
import cross from '../assets/images/red-cross.png';
import '../assets/style/index.css'
import bell from '../assets/images/bell.png';
import bell_notification from '../assets/images/bell_notification.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp, faLongArrowAltDown, faPencil } from '@fortawesome/free-solid-svg-icons';
import SupportForm from '../features/support/SupportForm'
import OrderModal from '../components/OrderModal';
import { fetchUserInfo } from '../utils/users/userUtils';
import { fetchMessages, markNotificationRead } from '../utils/notifications/notificationsUtils';
import {fetchBudget, fetchProcessedOrders, fetchUnprocessedOrders, fetchOrderRecs } from '../utils/orders/orderInfoUtils';
import { fetchRecommendationsPredictions } from '../utils/inventory/inventoryManagement';
import {fetchFlagsToResolve, fetchFlagDetails } from '../utils/flags/flagInfo';
import {acceptOrderRecommendation, declineOrder, setManagerReview} from '../utils/orders/orderManagementUtils';
import { fetchProductUsage } from '../utils/products/productInfoUtils';
import NotificationsModal from '../features/home/NotificationsModal';
import FlagDetailsModal from '../features/home/FlagDetailsModal';
import RecommendationDetailsModal from '../features/home/RecommendationDetailsModal';
import DeclineRecommendationModal from '../features/home/DeclineRecommendationModal';
import ResolveRecommendationModal from '../features/home/ResolveRecommendationModal';
import {createAbortController, getAbortSignal, closeAbortController} from '../utils/controller/abortUtils';
import {fetchManagerReviewStatus} from '../utils/locations/locationsUtils';
import OrderEditModal from '../features/home/OrderEditModal';
import {formatDateText, formatSimpleDateText} from '../utils/time_zones/TimeZones';
import {getArrow, handleSortChange, sortArrayByAttribute} from '../utils/tables/sortUtils';

const Home = ({locationName, permissionCode}) => {
  const contentRef = useRef();
  const abortControllerRef = useRef(null);  // Create a ref to hold the AbortController

  const tableContainerRef = useRef(null);
  const [isLoadingRecs, setIsLoadingRecs] = useState(true);
  const [isLoadingFlags, setIsLoadingFlags] = useState(true);
  const [orderRecData, setOrderRecData] = useState([]); // To handle order recommendation data
  const [username, setUsername] = useState('');
  const [orderThreshold, setOrderThreshold ] = useState([]);
  // New state variables for order placement popup
  const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [messages, setMessages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // State variables for notifications and notification modal
  const [notifications, setNotifications] = useState(false);
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false);
  const [flagsToResolve, setFlagsToResolve] = useState([]);
  const [flagToResolve, setFlagToResolve] = useState({});
  const [recommendationDetails, setRecommendationDetails] = useState([]);

  const [processedOrders, setProcessedOrders] = useState('');
  const [unprocessedOrders, setUnprocessedOrders] = useState('');
  const [budget, setBudget] = useState('');
  const [productUsageData, setProductUsageData] = useState([]);
  const [recommendationPrediction, setRecommendationPrediction] = useState([]);

  const [flagsModalIsOpen, setFlagsModalIsOpen] = useState(false);
  const [FlagsDetailsModalIsOpen, setFlagsDetailsModalIsOpen] = useState(false);
  const [recommendationsDetailsModalIsOpen, setRecommendationsDetailsModalIsOpen] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const [flagDetails, setFlagDetails] = useState([]);
  const [isManagerReviewed, setIsManagerReviewed] = useState(false)
  const [isOrderEditOpen, setIsOrderEditOpen] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [sortedOrders, setSortedOrders] = useState([])
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortAsc, setSortAsc] = useState(true);

  const userEmail = localStorage.getItem('userEmail');

  const totalOrders = processedOrders + unprocessedOrders;
  const remainingBudget = budget - totalOrders;

  const barColor = remainingBudget >= 0 ? '' : '#e9453b';

  const remainingText = remainingBudget >= 0
    ? `$${ Math.round(remainingBudget).toLocaleString() } Remaining`
    : `$${ Math.abs(Math.round(remainingBudget)).toLocaleString() } Over Budget`;

  const processedPercent = (processedOrders / budget) * 100;

  const unprocessedPercent = (unprocessedOrders / budget) * 100;


  useEffect(() => {
    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '49px';
  }, [])

  useEffect(() => {
    createAbortController(abortControllerRef);
    const signal = getAbortSignal(abortControllerRef);
    setIsLoadingRecs(true);
    setIsLoadingFlags(true);

    fetchManagerReviewStatus({locationName, setIsManagerReviewed, signal})
    fetchOrderRecs({setOrderRecData, locationName, setIsLoading: setIsLoadingRecs, signal});
    fetchUnprocessedOrders({locationName, setUnprocessedOrders, signal});
    fetchProcessedOrders({locationName, setProcessedOrders, signal});
    fetchBudget({locationName, setBudget, signal});
    fetchMessages({userEmail, locationName, setMessages, setNotifications, signal});
    fetchUserInfo({locationName, callBack: (data) => {
      setUsername(data.username);
      setOrderThreshold(data.order_approval_limit);
    }, signal});

    fetchFlagsToResolve({locationName, setFlagsToResolve, setIsLoading: setIsLoadingFlags, signal});

    return () => {
      closeAbortController(abortControllerRef)
    };
  }, [locationName]);

  useEffect(()=>{
    setSortedOrders(sortArrayByAttribute([...orderRecData], sortAttribute, sortAsc))
  }, [orderRecData, sortAttribute, sortAsc])

  // Function to handle mouse hover over the progress bar
  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  // Function to handle mouse leave from the progress bar
  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const toggleFlagsModal = () => {
    setFlagsModalIsOpen(!flagsModalIsOpen);
  };

  const toggleFlagsDetailsModal = () => {
    setFlagsDetailsModalIsOpen(!FlagsDetailsModalIsOpen);
  };

  const toggleRecommendationsDetailsModal = () => {
    setRecommendationsDetailsModalIsOpen(!recommendationsDetailsModalIsOpen);
  };

  const handleOrder = (order) => {
    setSelectedProduct(order)
    setOrderModalIsOpen(true)
  }

  const toggleContactInfo = () => {
    setShowContact(!showContact);
  }

  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked)
    setOrderRecData([...orderRecData].map(obj => ({
      ...obj,         // Spread existing properties
      isChecked: isChecked  // Add new boolean attribute
    })));
  }
  
  const handleSelect = (isChecked, orderId) => {
    setOrderRecData([...orderRecData].map(obj => obj.order_id === orderId ? ({
      ...obj,         // Spread existing properties
      isChecked: isChecked  // Add new boolean attribute
    }) : obj));
  }

  
  // This handles the accept-decline button that is there on the home page table, logic needs to be built yet on the server side
  const handleDecline = async (orderId) => {
    // If the order is declined, prompt the user for a reason
    setSelectedOrderId([orderId]); // Set the selected order ID
    setDeclineModalIsOpen(true);
  }

  const handleBulkDecline = async () => {
    const declineOrders = [...orderRecData].filter(order => order.isChecked).map(order => order.order_id);
    setSelectedOrderId(declineOrders); // Set the selected order ID
    setDeclineModalIsOpen(true);
  };

  const handleBulkAccept = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      const acceptOrders = [...orderRecData].filter(order => order.isChecked);
      let success = false;
      for (const order of acceptOrders) {
        const orderId = order.order_id;
        const orderQuantity = order.order_quantity;
        const orderUrgency = order.order_urgency ? order.order_urgency : '5+ business days';
        const selectedProduct = order;
        const orderComment = order.client_comment;
        const result = await acceptOrderRecommendation({orderId, orderQuantity, orderComment, orderUrgency, username, selectedProduct, locationName});
        if (result) success = true;
      }
      if (success) {
        const signal = getAbortSignal(abortControllerRef);
        fetchOrderRecs({setOrderRecData, locationName, setIsLoading: setIsLoadingRecs, signal});

        toast.success("Order(s) accepted by system for processing");
      } else {
        console.error('Error accepting order.');
      }
      setIsSubmitting(false)
    }
  }


  // Function to toggle notification modal visibility
  const toggleNotificationModal = () => {
    setNotificationModalIsOpen(!notificationModalIsOpen);
    fetchMessages({userEmail, locationName, setMessages, setNotifications});
  };

  const handleMessage = async (message) => {
    const success = await markNotificationRead(message);
    if (success) {
      // If message is marked as read successfully, fetch messages again to update the window
      await fetchMessages({userEmail, locationName, setMessages, setNotifications});
    } 
  };

  const resetAll = () => {
    setFlagToResolve({});
  };


  // Function to submit the order from the popup
  const submitOrder = async (orderQuantity, orderUrgency, orderComment) => {
    if (orderQuantity <= 0) {
      toast.error('Please input an order quantity.');
      return;
    }

    const orderId = selectedProduct.order_id;
    const success = await acceptOrderRecommendation({orderId, orderQuantity, orderComment, orderUrgency, username, selectedProduct, locationName});

    if (success) {
      const signal = getAbortSignal(abortControllerRef);
      fetchOrderRecs({setOrderRecData, locationName, setIsLoading: setIsLoadingRecs, signal});

      toast.success("Order accepted by system for processing");
    } else {
      console.error('Error accepting order.');
    }

    // Close the order modal
    setOrderModalIsOpen(false);

    // Reset order details
    setSelectedProduct(null);
  };

  function scrollDownTable() {
    setTimeout(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
      }
    }, 100);
  }

  return (
    <div ref={contentRef}>
      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      
        <h2 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#F49C7D',}}>Order Recommendations</h2>

        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <div style={{textAlign: 'center', marginRight: '50px'}}>
            <div className="progress-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div className="progress-bar">
                <div className="progress-processed" style={{ width: `${processedPercent}%`, backgroundColor: barColor }}></div>
                <div className="progress-unprocessed" style={{ width: `${unprocessedPercent}%`, backgroundColor: barColor }}></div>
              </div>
              <span className="progress-text">{remainingText}</span>

              {isTooltipVisible && (
                <div className="tooltip">
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: remainingBudget <= 0 ? '#e9453b' : '#f49c7d',
                        marginRight: '5px'
                      }}
                    ></div>
                    <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                      Processed: ${Math.round((processedOrders ? processedOrders : 0)).toLocaleString()}
                    </p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: remainingBudget <= 0 ? '#e9453b' : '#fad5c0',
                        marginRight: '5px'
                      }}
                    ></div>
                    <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                      Pending: ${Math.round((unprocessedOrders ? unprocessedOrders : 0)).toLocaleString()}
                    </p>
                  </div>
                  {remainingBudget > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                      <div style={{ width: '10px', height: '10px', backgroundColor: '#e0e0e0', marginRight: '5px' }}></div>
                      <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                        Remaining: ${Math.round((remainingBudget ? remainingBudget : 0)).toLocaleString()}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <span className="progress-text" style={{ marginTop: '5px', display: 'block', marginLeft: '150px' }}>{`Monthly Budget: $${Number(parseFloat(budget).toFixed(2)).toLocaleString()}`}</span>
          </div>

          {/* Notifications bell */}
          <div>
            <button className="support-btn" onClick={toggleNotificationModal} style={{ backgroundColor: '#F49C7D', marginTop: '-2px' }}>
              <img src={notifications ? bell_notification : bell} alt="notifications" style={{ width: '30px', height: '30px' }} />
            </button>
          </div>
          {/* Notifications modal */}
          <NotificationsModal isOpen={notificationModalIsOpen} setIsOpen={setNotificationModalIsOpen} onClose={toggleNotificationModal} messages={messages} handleMessage={handleMessage} locationName={locationName }/>

          <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: '#F49C7D', marginTop: '-2px'}}>
          
            <h1 style={{ fontSize: '20px' }}>?</h1>
          </button>
          
          {showContact && (
            <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
          )}

        </div> 
      </div> 
      <div style={{display:'flex', gap:'8px'}}>
        <button className='action-button' style={{fontSize: 'medium'}} disabled={!orderRecData.some(item => item.isChecked)} onClick={handleBulkAccept}>
            Accept Selected
          </button>
        <button className='decline-button' style={{fontSize: 'medium'}} disabled={!orderRecData.some(item => item.isChecked)} onClick={handleBulkDecline}>
            Decline Selected
        </button>
        <div className='select-box' style={{cursor:'text', minWidth:'120px'}}>
          ${orderRecData.reduce((sum, obj) => {
            return obj.isChecked ? sum + (obj.order_quantity * obj.unit_price || 0) : sum;
          }, 0).toFixed(2)} Total
        </div> 
      </div>
      <div style={{ marginTop: '10px' , display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%' }}>
        
        <div style={{ 
          border: '2px solid #F49C7D', 
          display: 'flex', 
          justifyContent: 'left', 
          flexDirection: 'column', 
          width: '100%', 
          maxHeight: '310px', // Set a fixed height
          minHeight: '310px', // Set a fixed height
          overflowY: 'auto', // Enable vertical scrolling
          boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>

          <table style={{  
            backgroundColor: '#fffff', 
            width: '100%', 
            borderCollapse: 'separate', 
            borderSpacing: 0,       
            position: 'relative' // Ensure positioning context
          }}>
            <thead style={{
              position: 'sticky', // Stick the header
              top: 0, // Stick to the top of the table
              backgroundColor: '#ffffff', // Background to cover underlying rows
            
            }}>
              <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                <th style={{borderBottom: '2px solid black', padding: '10px', textAlign: 'center'}}>
                  <input
                    checked={selectAllChecked}
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    style={{width: '15px', height: '15px'}}
                  />
                </th>
                <th onClick={() => handleSortChange('order_date', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '200px', textAlign: 'left'}}>Date {getArrow('order_date', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('product_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '300px', textAlign: 'left'}}>Product Name {getArrow('product_name', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('product_description', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '300px', textAlign: 'left'}}>Description {getArrow('product_description', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('product_cat', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '100px', textAlign: 'left'}}>Category {getArrow('product_cat', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('manufacturer_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '100px', textAlign: 'left'}}>Manufacturer {getArrow('manufacturer_name', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('unit_price', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '40px', textAlign: 'left'}}>Price {getArrow('unit_price', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('order_quantity', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '40px', textAlign: 'center'}}>Quantity {getArrow('order_quantity', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('total', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '50px', textAlign: 'left'}}>Total {getArrow('total', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('recommendation_rationale', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '140px', textAlign: 'left'}}>Order Reason {getArrow('recommendation_rationale', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('order_urgency', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '5px', width: '140px', textAlign: 'left'}}>Order Urgency {getArrow('order_urgency', sortAttribute, sortAsc)}</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '140px',textAlign: 'center'}}>Details</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '150px',textAlign: 'center' }}>
                    Actions
                </th>
                <th style={{borderBottom: '2px solid black', padding: '5px', width: '50px', textAlign: 'center'}}>
                  Edit
                </th>
                {isManagerReviewed && (
                  <th style={{borderBottom: '2px solid black', padding: '5px', width: '50px', textAlign: 'center'}}>
                    Reviewed
                  </th>
                )}
                
              </tr>

            </thead>

            <tbody>
            {(sortedOrders.length === 0 || isLoadingRecs) ? (
                <tr>
                  <td colSpan={isManagerReviewed ? "15" : "14"} style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: "1px", padding: '50px', height: '160px'}}>
                    {isLoadingRecs ? 'Loading Recommendations...' : 'Order Recommendations Up-to-Date'}
                  </td>
                      </tr>
                  ) : (
                      sortedOrders.map((item, index) => (
                        <tr key={item.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff' }} >
                          <td style={{ padding: '10px', textAlign: 'center'}}>
                            <input
                              checked={item.isChecked}
                              type="checkbox"
                              onChange={(e) => handleSelect(e.target.checked, item.order_id)}
                              style={{width: '15px', height: '15px'}}
                            />
                          </td>
                          <td className='table-row' style={{padding: '5px', }}>{formatSimpleDateText(item.order_date)}</td>
                          <td className='table-row' style={{  padding: '5px',  }}>{item.product_name}</td>
                          <td className='table-row' style={{  padding: '5px', }}>{item.product_description}</td>
                          <td className='table-row' style={{padding: '5px', }}>{item.product_cat}</td>
                          <td className='table-row' style={{ padding: '5px', }}>{item.manufacturer_name}</td>
                          <td className='table-row' style={{  padding: '5px',  }}>{item.unit_price === 0 || item.unit_price === null || item.product_cat === 'web' ? 'TBD' : `$${parseFloat(item.unit_price).toFixed(2)}` }</td>
                          <td className='table-row' style={{  padding: '5px', textAlign: 'center' }}>{item.order_quantity}</td>
                          <td className='table-row' style={{ padding: '5px' }}>{item.unit_price === 0 || item.unit_price === null || item.product_cat === 'web' ? 'TBD' : `$${parseFloat(item.unit_price * item.order_quantity).toFixed(2)}`}</td>
                          <td className='table-row' style={{padding: '5px', }}>{item.recommendation_rationale}</td>
                          <td className='table-row' style={{padding: '5px', }}>{item.order_urgency}</td>
                          <td className='table-row' style={{  padding: '5px', textAlign: 'center' }}>
                            {//item.recommendation_rationale !== 'Staff Request' &&
                              <button className='action-button-accent' onClick={async () => {setRecommendationDetails(item); await fetchProductUsage(locationName, item.product_id, setProductUsageData); await fetchRecommendationsPredictions(locationName, item.product_id, setRecommendationPrediction); toggleRecommendationsDetailsModal();}} >
                                Details
                              </button>
                            }
                          </td>
                          <td className='table-row' style={{ padding: '5px', textAlign:'center', whiteSpace: 'nowrap'}}>
                            {permissionCode === 22 ? (
                              <>
                                {(item.unit_price * item.order_quantity) < orderThreshold ? (
                                  <button onClick={() => handleOrder(item)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', marginRight: '10px', border: 'none', cursor: 'pointer' }}>
                                    <img src={tick} alt="green-tick" style={{ height: '20px', width: '20px' }} />
                                  </button>
                                ) : (
                                  <span style={{ display: 'inline-block', width: '42px' }}> {/* Adjust the width to match the button size */} </span>
                                )}
                                <button onClick={() => handleDecline(item.order_id)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', border: 'none', cursor: 'pointer' }}>
                                  <img src={cross} alt="red-cross" style={{ height: '20px', width: '20px', marginLeft: '12px' }} />
                                </button>
                              </>
                            ) : (
                              <div style={{}}>
                                <button onClick={() => handleOrder(item)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', marginRight: '10px', border: 'none', cursor: 'pointer' }}>
                                  <img src={tick} alt="green-tick" style={{ height: '20px', width: '20px' }} />
                                </button>
                                <button onClick={() => handleDecline(item.order_id)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', border: 'none', cursor: 'pointer' }}>
                                  <img src={cross} alt="red-cross" style={{ height: '20px', width: '20px' }} />
                                </button>
                              </div>
                            )}
                          </td>
                          <td className='table-row' style={{padding: '5px', textAlign:'center'}}> 
                            <button onClick={() => {setIsOrderEditOpen(true); setRecommendationDetails(item);}} style={{cursor: 'pointer', border: 'none', backgroundColor: 'transparent', fontSize: 'medium', }}>
                              <FontAwesomeIcon className='icon-hover-accent' icon={faPencil} />
                            </button>
                          </td>
                          {isManagerReviewed && (
                            <td className='table-row' style={{padding: '5px', textAlign: 'center'}}>
                              <input
                                type="checkbox"
                                style={{height: '15px', width: '15px', backgroundColor: '#E4846E'}}
                                checked={item.manager_reviewed}
                                onChange={async () => {
                                  const isReviewed = item.manager_reviewed
                                  const success = await setManagerReview({orderId: item.order_id, isReviewed: !isReviewed, signal: getAbortSignal(abortControllerRef)})
                                  if (success) {
                                    const updatedItems = orderRecData.map(o =>
                                      o.order_id === item.order_id ? {...o, manager_reviewed: !isReviewed} : o
                                    );
                                    setOrderRecData(updatedItems)
                                  }
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    )}
            </tbody>
          </table>
        </div>
        <DeclineRecommendationModal
          isOpen={declineModalIsOpen}
          setIsOpen={setDeclineModalIsOpen}
          orderIds={selectedOrderId}
          username={username}
          onSubmit={() => fetchOrderRecs({setOrderRecData, locationName, setIsLoading: setIsLoadingRecs, signal: getAbortSignal(abortControllerRef)})
          }
        />

        <OrderModal isOpen={orderModalIsOpen} setIsOpen={setOrderModalIsOpen} product={selectedProduct} submitOrder={submitOrder} quantity={selectedProduct?.order_quantity} permissionCode={permissionCode}/>
      </div>
      

      <div>        
        <h2 style={{ fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>Flagging Resolution</h2>
        <div style={{ 
          border: '2px solid #87D3D7', 
          display: 'flex', 
          justifyContent: 'left', 
          flexDirection: 'column', 
          width: '100%', 
          maxHeight: '310px', // Set a fixed height
          overflowY: 'auto', // Enable vertical scrolling
          boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>

          <table style={{  
            width: '100%', 
            borderCollapse: 'separate', 
            borderSpacing: 0, 
            position: 'relative' // Ensure positioning context
          }}>
            <thead style={{
              position: 'sticky', // Stick the header
              top: 0, // Stick to the top of the table
            }}>
              <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Product Name</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Description</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Manufacturer</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Total Change</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Details</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Resolve</th>
              </tr>
            </thead>
            
            <tbody>
              {(flagsToResolve.length === 0 || isLoadingFlags) ?
              ( <tr>
                  <td colSpan="6" style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '16px',fontStyle:'italic', padding:"1px", padding: '50px', height: '160px'  }}>
                      {isLoadingFlags ? 'Loading flags...' : 'Flagging Resolution Up-to-Date'}
                  </td>
                </tr>
              )
              :
              ( <>
                  {flagsToResolve.map((flag, index) => (
                    <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{flag.product_name}</td>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{flag.product_description}</td>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{flag.manufacturer_name}</td>
                      <td style={{ padding: '10px', textAlign: 'left', width: '6%' }}>
                        {flag.total_delta >= 0 ? (
                          <span style={{ float: 'left', color: 'rgba(93, 158, 82, 1)' }}>
                            <FontAwesomeIcon icon={faLongArrowAltUp} />
                          </span>
                        ) : (
                          <span style={{ float: 'left', color: 'rgba(207, 100, 84, 1)' }}>
                            <FontAwesomeIcon icon={faLongArrowAltDown} />
                          </span>
                        )}
                        <span style={{ float: 'right' }}>{Math.abs(flag.total_delta).toFixed(1)}</span>
                      </td>
                      <td style={{ padding: '10px', textAlign: 'center' }}>
                        <button className='action-button' onClick={async () => {setFlagToResolve(flag); await fetchFlagDetails(flag.product_id, locationName, setFlagDetails); setFlagToResolve(flag); toggleFlagsDetailsModal(); scrollDownTable();}}>Details</button>
                      </td>
                      <td style={{ padding: '10px', textAlign: 'center' }}>
                        <button className='action-button' onClick={async () => {
                          toggleFlagsModal();
                          setFlagToResolve(flag);
                        }}
                        >Resolve</button>
                      </td>
                    </tr>
                  ))}
                </>
              )
              }
            </tbody>
          </table>
        </div>
      </div>
      <br/>

      <ToastContainer />
      {flagToResolve && flagsModalIsOpen && (
        <ResolveRecommendationModal
          isOpen={flagsModalIsOpen}
          setIsOpen={setFlagsModalIsOpen}
          flagToResolve={flagToResolve}
          locationName={locationName}
          username={username}
          onSubmit={() => {
            fetchFlagsToResolve({locationName, setFlagsToResolve, setIsLoading: setIsLoadingFlags, signal: getAbortSignal(abortControllerRef)});
          }}
          onClose={() => {
            toggleFlagsModal(); 
            resetAll();
          }} />
      )}

      {flagDetails && (
        <FlagDetailsModal
          isOpen={FlagsDetailsModalIsOpen}
          setIsOpen={setFlagsDetailsModalIsOpen}
          flagToResolve={flagToResolve}
          flagDetails={flagDetails}
          onClose={() => {
            toggleFlagsDetailsModal();
            setFlagToResolve({});
            setFlagDetails([]);
            resetAll();
          }}
          onResolveClick={async () => {
            toggleFlagsDetailsModal();
            setFlagDetails([]);
            toggleFlagsModal();
          }}
        />
      )}
      {productUsageData && (
        <RecommendationDetailsModal
          isOpen={recommendationsDetailsModalIsOpen}
          setIsOpen={setRecommendationsDetailsModalIsOpen}
          recommendationDetails={recommendationDetails}
          recommendationPrediction={recommendationPrediction}
          productUsageData={productUsageData}
          onClose={() => {
            toggleRecommendationsDetailsModal();
            setRecommendationDetails([]);
            setRecommendationPrediction([]);
          }}
          onAccept={() => {
            toggleRecommendationsDetailsModal();
            setRecommendationDetails([]);
            setRecommendationPrediction([]);
            handleOrder(recommendationDetails);
          }}
          onDecline={() => {
            toggleRecommendationsDetailsModal();
            setRecommendationDetails([]);
            setRecommendationPrediction([]);
            handleDecline(recommendationDetails.order_id);
          }}
        />
      )
      }
      <OrderEditModal order={recommendationDetails} isOpen={isOrderEditOpen} setIsOpen={setIsOrderEditOpen} onSubmit={() => fetchOrderRecs({setOrderRecData, locationName, setIsLoading: setIsLoadingRecs, signal:getAbortSignal(abortControllerRef)})} username={username}/>

    </div>
  );
};

export default Home;
