import React, { useState, useEffect, useRef } from 'react';
import ProfileSettings from '../features/settings/ProfileSettings';
import SpotCheckSettings from '../features/settings/SpotCheckSettings';
import InventorySettings from '../features/settings/InventorySettings';
import TopBar from '../components/TopBar';

const Settings = ({ locationName, permissionCode }) => {
  const contentRef = useRef();

  useEffect(() => {
    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '49px';
  }, []);

  const [activeTab, setActiveTab] = useState('Profile');

  const tabStyle = (tabName) => ({
    marginBottom: '0px',
    color: '#000000',
    padding: '0px',
    borderBottom: activeTab === tabName ? '2px solid black' : '2px solid transparent',
    fontWeight: activeTab === tabName ? 'bold' : 'normal',
    cursor: 'pointer',
  });

  return (
    <div ref={contentRef}>
      <TopBar title={'Settings'} color={'#87D3D7'} locationName={locationName} permissionCode={permissionCode} />

      <div 
        style={{
          display: 'flex',
          columnGap: '40px',
          marginBottom: '40px'
        }}
      >
        <h2 onClick={() => setActiveTab('Profile')} style={tabStyle('Profile')}>Profile</h2>
        <h2 onClick={() => setActiveTab('SpotCheck')} style={tabStyle('SpotCheck')}>SpotCheck</h2>
        <h2 onClick={() => setActiveTab('Inventory')} style={tabStyle('Inventory')}>Inventory</h2>
      </div>
      <div className="tab-content">
        {activeTab === 'Profile' && <ProfileSettings locationName={locationName} permissionCode={permissionCode} />}
        {activeTab === 'SpotCheck' && <SpotCheckSettings locationName={locationName} permissionCode={permissionCode} />}
        {activeTab === 'Inventory' && <InventorySettings locationName={locationName} permissionCode={permissionCode} />}
      </div>
    </div>
  );
};

export default Settings;