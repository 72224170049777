import React, { useState } from 'react';

const InfoPopup = ({ title, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <button 
        className="info-button" 
        onClick={togglePopup} 
        style={{ marginTop: '2px', marginLeft: '5px', cursor: 'pointer' }}
      >
        &#x2139;
      </button>

      {isVisible && (
        <div className="popup" style={{ display: 'flex', marginLeft: '38px', fontWeight: 'normal', fontSize: '16px' }}>
          <div className="popup-inner">
            <h2><b>{title}</b></h2>
            {content.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            <button 
              className="submit-button" 
              onClick={togglePopup} 
              style={{
                width: '100px',
                border: '3px solid #31bac1',
                color: 'white',
                backgroundColor: '#31bac1',
                padding: '5px',
                marginTop: '20px',
                cursor: 'pointer'
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoPopup;