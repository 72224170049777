import React, {useState} from 'react';

const IconTooltip = ({icon, hoverText, onClick}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div
            style={{
                position: 'relative', // Set position relative for tooltip positioning
                cursor: 'pointer',
                marginLeft: '8px',
                alignContent: 'flex-end',
                marginBottom: '10px',
                display:'inline-block'
            }}
            onClick={() => {
                if (onClick) onClick(); // Call the passed onClick handler if provided
            }}
            onMouseEnter={() => setShowTooltip(true)} // Show tooltip on hover
            onMouseLeave={() => {
                setShowTooltip(false);
            }} // Hide tooltip when not hovering
        >
            {icon}
            {(showTooltip && hoverText) && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '110%', // Position above the icon
                        left: '0%',
                        backgroundColor: '#333',
                        color: '#fff',
                        padding: '8px 12px', // Adjust padding for better readability
                        borderRadius: '5px',
                        zIndex: 1, // Ensure it appears above other elements
                        fontSize: '12px',
                        minWidth:'350px',
                        maxWidth: '400px', // Set a max width to prevent the tooltip from becoming too wide
                        width: 'auto', // Let the width adjust based on content, but don't exceed maxWidth
                        wordWrap: 'break-word', // Ensure long words break to the next line
                        textAlign: 'center', // Optional: Align text in the center
                    }}
                >
                    {hoverText}
                </div>
            )}

        </div>
    );
};

export default IconTooltip;
