import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

const Suppliers = ({ call, permissionCode }) => { 
    const contentRef = useRef();
    const [medvelleSuppliers, setMedvelleSuppliers] = useState([]);
    const [editedSupplier, setEditedSupplier] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [clientSuppliers, setClientSuppliers] = useState([]);
    const [activeTab, setActiveTab] = useState(localStorage.getItem('tab') || 'Medvelle');
    const [searchLocation, setSearchLocation] = useState('');
    const [representatives, setRepresentatives] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [searchID, setSearchID] = useState('');
    const [editedRepresentative, setEditedRepresentative] = useState({});
    const [pendingUpdate, setPendingUpdate] = useState(null);
    const [editedClientSuppliers, setEditedClientSuppliers] = useState({});
    const [isClientModalOpen, setIsClientModalOpen] = useState(false);
    const [isMedvelleModalOpen, setIsMedvelleModalOpen] = useState(false);
    const [alllocations, setAllLocations] = useState([]);

    const [newSupplier, setNewSupplier] = useState({
        supplier_id: '',
        supplier_name: '',
        description: '',
        rep_ids: '',
        escalation_rep_ids: '',
        cust_service_phone: '',
        cust_service_email: '',
        ordering_preference: '1',
        website: '',
        username: '',
        password: '',
        ordering_email: '',
        terms: '30',
        email_updated: '0',
        automatic_email_updates: '0',
        credit_card_updated: '0',
        min_order_quantity: '',
        min_free_shipping_value: '',
        min_order_value: '',
        order_multiple_quantity: '',
        promo_code: '',
        comments: '',
        status: '1'
    });
    
    const handleNewSupplierChange = (e, field) => {
        const value = e.target.value;
        setNewSupplier((prevNewSupplier) => ({
            ...prevNewSupplier,
            [field]: value
        }));
    };
    
    const openMedvelleModal = () => {
        setIsMedvelleModalOpen(true);
    };
    
    const closeMedvelleModal = () => {
        const highestSupplierId = Math.max(...medvelleSuppliers.map(supplier => supplier.supplier_id), 0);
        setIsMedvelleModalOpen(false);
        setNewSupplier({
            supplier_id: highestSupplierId + 1,
            supplier_name: '',
            description: '',
            rep_ids: '',
            escalation_rep_ids: '',
            cust_service_phone: '',
            cust_service_email: '',
            ordering_preference: '1',
            website: '',
            username: '',
            password: '',
            ordering_email: '',
            terms: '30',
            email_updated: '0',
            automatic_email_updates: '0',
            credit_card_updated: '0',
            min_order_quantity: '',
            min_free_shipping_value: '',
            min_order_value: '',
            order_multiple_quantity: '',
            promo_code: '',
            comments: '',
            status: '1'
        });
    };
    
    const addNewSupplier = async () => {
        const { ordering_preference, username, password, rep_ids, website } = newSupplier;
        if (ordering_preference === '0' && (!username || !password)) {
            toast.error('Username and Password must be filled out to select Platform.');
            return;
        }

        if (ordering_preference === '1' && (!rep_ids)) {
            toast.error('If ordering preference is representative then please mention rep');
            return;
        }

        if(!rep_ids && !website){
            toast.error("Please either enter rep id or website details")
            return
        }
    
        try {
            const response = await fetch(`${call}/add-medvelle-supplier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newSupplier)
            });
            if (response.ok) {
                fetchMedvelleSuppliers();
                closeMedvelleModal();
                toast.success('Supplier added successfully');
            } else {
                toast.error('Failed to add supplier');
            }
        } catch (error) {
            console.error('Error adding supplier:', error);
            toast.error('Failed to add supplier');
        }
    };
    

    const [newClientSupplier, setNewClientSupplier] = useState({
        location: '',
        supplier_id: '',
        location_min_order_value: '',
        rep_ids: '',
        escalation_rep_ids: '',
        terms: '30',
        ordering_preference: '1',
        website: '',
        username: '',
        password: '',
        email_updated: '0',
        automatic_email_updates: '0',
        credit_card_updated: '0',
        comments: '',
        status: '1'
    });

    const handleNewClientSupplierChange = (e, field) => {
        const value = e.target.value;
        setNewClientSupplier((prevNewClientSupplier) => ({
            ...prevNewClientSupplier,
            [field]: value
        }));
    };

    const handleNewSupplierDropdownChange = (e, field) => {
        const value = e.target.value;
        setNewSupplier((prevNewSupplier) => ({
            ...prevNewSupplier,
            [field]: value
        }));
    };

    const renderNewSupplierDropdownCell = (field, value) => (
        <select value={value} onChange={(e) => handleNewSupplierDropdownChange(e, field)} style={{ width: '100%' }}>
            {field === 'ordering_preference' && (
                <>
                    <option value="0">Platform</option>
                    <option value="1">Representative</option>
                </>
            )}
            {field === 'terms' && (
                <>
                    <option value="7">7</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="60">60</option>
                    <option value="90">90</option>
                    <option value="120">120</option>
                </>
            )}
            {['email_updated', 'automatic_email_updates', 'credit_card_updated'].includes(field) && (
                <>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </>
            )}
            {field === 'status' && (
                <>
                    <option value="0">Inactive</option>
                    <option value="1">Active</option>
                </>
            )}
        </select>
    );
    
    

    const openClientModal = () => {
        setIsClientModalOpen(true);
    };

    const closeClientModal = () => {
        setIsClientModalOpen(false);
        setNewClientSupplier({
            location: '',
            supplier_id: '',
            location_min_order_value: '',
            rep_ids: '',
            escalation_rep_ids: '',
            terms: '30',
            ordering_preference: '1',
            website: '',
            username: '',
            password: '',
            email_updated: '0',
            automatic_email_updates: '0',
            credit_card_updated: '0',
            comments: '',
            status: '1'
        });
    };

    const handleNewClientSupplierDropdownChange = (e, field) => {
        const value = e.target.value;
        setNewClientSupplier((prevNewClientSupplier) => ({
            ...prevNewClientSupplier,
            [field]: value
        }));
    };

    const renderNewClientSupplierDropdownCell = (field, value) => (
        <select value={value} onChange={(e) => handleNewClientSupplierDropdownChange(e, field)} style={{ width: '100%' }}>
            {field === 'ordering_preference' && (
                <>
                    <option value="0">Platform</option>
                    <option value="1">Representative</option>
                </>
            )}
            {field === 'terms' && (
                <>
                    <option value="7">7</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="60">60</option>
                    <option value="90">90</option>
                    <option value="120">120</option>
                </>
            )}
            {['email_updated', 'automatic_email_updates', 'credit_card_updated'].includes(field) && (
                <>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </>
            )}
            {field === 'status' && (
                <>
                    <option value="0">Inactive</option>
                    <option value="1">Active</option>
                </>
            )}
        </select>
    );    
    

    const addNewClientSupplier = async () => {
        const { ordering_preference, username, password, rep_ids, website } = newClientSupplier;
        if (ordering_preference === '0' && (!username || !password)) {
            toast.error('Username and Password must be filled out to select Platform.');
            return;
        }

        if (ordering_preference === '1' && (!rep_ids)) {
            toast.error('If ordering preference is representative then please mention rep');
            return;
        }

        if(!rep_ids && !website){
            toast.error("Please either enter rep id or website details")
            return;
        }

        try {
            const response = await fetch(`${call}/add-client-supplier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newClientSupplier)
            });
            if (response.ok) {
                fetchClientSuppliers();
                closeClientModal();
                toast.success('Client supplier added successfully');
            } else {
                toast.error('Failed to add client supplier');
            }
        } catch (error) {
            console.error('Error adding client supplier:', error);
            toast.error('Failed to add client supplier');
        }
    };


    const [isRepresentativeModalOpen, setIsRepresentativeModalOpen] = useState(false);

    const [newRepresentative, setNewRepresentative] = useState({
        rep_id: '',
        rep_first_name: '',
        rep_last_name: '',
        rep_email: '',
        rep_phone: '',
        rep_title: '',
        comments: '',
        status: '1'
    });

    const handleNewRepresentativeChange = (e, field) => {
        const value = e.target.value;
        setNewRepresentative((prevNewRepresentative) => ({
            ...prevNewRepresentative,
            [field]: value
        }));
    };

    const openRepresentativeModal = () => {
        const highestRepId = Math.max(...representatives.map(rep => rep.rep_id), 0);
        setNewRepresentative({
            rep_id: highestRepId + 1,
            rep_first_name: '',
            rep_last_name: '',
            rep_email: '',
            rep_phone: '',
            rep_title: '',
            comments: '',
            status: '1'
        });
        setIsRepresentativeModalOpen(true);
    };
    

    const closeRepresentativeModal = () => {
        setIsRepresentativeModalOpen(false);
        setNewRepresentative({
            rep_id: '',
            rep_first_name: '',
            rep_last_name: '',
            rep_email: '',
            rep_phone: '',
            rep_title: '',
            comments: '',
            status: '1'
        });
    };

    const addNewRepresentative = async () => {
        const { rep_phone, rep_email } = newRepresentative;
    
        // Check if either phone or email is filled
        if (!rep_phone && !rep_email) {
            alert('Please fill out either the phone number or email address.');
            return; // Prevent form submission
        }
    
        try {
            const response = await fetch(`${call}/add-representative`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newRepresentative)
            });
            if (response.ok) {
                fetchRepresentatives();
                closeRepresentativeModal();
                toast.success('Representative added successfully');
            } else {
                toast.error('Failed to add representative');
            }
        } catch (error) {
            console.error('Error adding representative:', error);
            toast.error('Failed to add representative');
        }
    };    



    useEffect(() => {
        fetchMedvelleSuppliers();
        fetchClientSuppliers();
        fetchRepresentatives();
        fetchLocations();
        contentRef.current.style.marginLeft = '220px';
    }, []);

    useEffect(() => {
        if (pendingUpdate !== null) {
            updateSupplier(pendingUpdate);
            setPendingUpdate(null);
        }
    }, [editedSupplier]);

    useEffect(() => {
        localStorage.setItem('tab', activeTab);
    }, [activeTab]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const fetchClientSuppliers = async () => {
        try {
            const response = await fetch(`${call}/client-suppliers`);
            const data = await response.json();
            setClientSuppliers(data);
            return data;
        } catch (error) {
            console.error('Error fetching client suppliers data:', error);
        }
    };

    const fetchRepresentatives = async () => {
        try {
            const response = await fetch(`${call}/representatives`);
            const data = await response.json();
            setRepresentatives(data);

            const highestRepId = Math.max(...data.map(rep => rep.rep_id), 0);
            setNewRepresentative(prev => ({ ...prev, rep_id: highestRepId + 1 }));

            return data;
        } catch (error) {
            console.error('Error fetching reps data:', error);
        }
    };

    const fetchMedvelleSuppliers = async () => {
        try {
            const response = await fetch(`${call}/medvelle-suppliers`);
            const data = await response.json();
            setMedvelleSuppliers(data);
            setEditedSupplier(data);
            const highestSupplierId = Math.max(...data.map(supplier => supplier.supplier_id), 0);
            setNewSupplier(prev => ({ ...prev, supplier_id: highestSupplierId + 1 }));
    
            return data;
        } catch (error) {
            console.error('Error fetching medvelle suppliers data:', error);
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await fetch(`${call}/get-locations`);
            const data = await response.json();
            setAllLocations(data);
            return data;
        } catch (error) {
            console.error('Error fetching medvelle suppliers data:', error);
        }
    };

    const renderEditableCell = (supplierId, field, value) => {
        if (field === 'terms') {
            return renderDropdownTermsCell(supplierId, field, value);
        }

        if (field === 'email_updated' || field === 'email_updated' || field === 'automatic_email_updates' || field === 'credit_card_updated' ) {
            return renderDropdown01(supplierId, field, value);
        }

        if (field === 'status') {
            return renderDropdownStatus(supplierId, field, value);
        }

        let displayValue = editedSupplier.find(s => s.supplier_id === supplierId)?.[field];
       
    
        return (
            <input
                type="text"
                value={displayValue}
                onChange={(e) => handleInputChange(e, supplierId, field)}
                style={{ width: '100%' }}
            />
        );

       
    };
    

    const handleInputChangeDropDown = (e, supplierId, field) => {

        const value = e.target.value;

        // Validation for ordering preference
        if (field === 'ordering_preference' && value === '0') {
            
            const { username, password } = editedSupplier.find(s => s.supplier_id === supplierId);
            if (!username || !password) {
                toast.error('Username and Password must be filled out to select Platform.');
                return;
            }
        }

        setEditedSupplier((prevEditedSupplier) => {
            const updatedSuppliers = [...prevEditedSupplier];
            const supplierIndex = updatedSuppliers.findIndex(s => s.supplier_id === supplierId);
            if (supplierIndex !== -1) {
                updatedSuppliers[supplierIndex] = {
                    ...updatedSuppliers[supplierIndex],
                    [field]: value
                };
            }
            return updatedSuppliers;
        });
    };
    
    const renderDropdownCell = (supplierId, field, value) => (
        <select
            value={editedSupplier.find(s => s.supplier_id === supplierId)?.[field] ?? value}
            onChange={(e) => handleInputChangeDropDown(e, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="0">Platform</option>
            <option value="1">Representative</option>
        </select>
    );

    const updateSupplier = async (supplierId) => {
        const updatedSupplier = editedSupplier.find(s => s.supplier_id === supplierId);

        try {
            const response = await fetch(`${call}/update-medvelle-suppliers/${supplierId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedSupplier)
            });
            if (response.ok) {
                fetchMedvelleSuppliers();
            } else {
                toast.error('Failed to update supplier');
            }
        } catch (error) {
            console.error('Error updating supplier:', error);
            toast.error('Failed to update supplier');
        }
    };

    const handleInputChange = (e, supplierId, field) => {
        let value = e.target.value;
    
        setEditedSupplier((prevEditedSupplier) => {
            const updatedSuppliers = [...prevEditedSupplier];
            const supplierIndex = updatedSuppliers.findIndex(s => s.supplier_id === supplierId);
            if (supplierIndex !== -1) {
                updatedSuppliers[supplierIndex] = {
                    ...updatedSuppliers[supplierIndex],
                    [field]: value
                };
            }
            return updatedSuppliers;
        });
    };

    const renderDropdownTermsCell = (supplierId, field, value) => (
        <select
            value={editedSupplier.find(s => s.supplier_id === supplierId)?.[field] ?? value}
            onChange={(e) => handleInputChangeDropDown(e, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="7">7</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
            <option value="120">120</option>
        </select>
    );

    const renderDropdown01 = (supplierId, field, value) => (
        <select
            value={editedSupplier.find(s => s.supplier_id === supplierId)?.[field] ?? value}
            onChange={(e) => handleInputChangeDropDown(e, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="0">No</option>
            <option value="1">Yes</option>
           
        </select>
    );

    const renderDropdownStatus = (supplierId, field, value) => (
        <select
            value={editedSupplier.find(s => s.supplier_id === supplierId)?.[field] ?? value}
            onChange={(e) => handleInputChangeDropDown(e, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="0">Inactive</option>
            <option value="1">Active</option>
           
        </select>
    );
    

    const updateAllSuppliers = async () => {
        const updates = editedSupplier.map(supplier => updateSupplier(supplier.supplier_id));
        await Promise.all(updates);
        toast.success('All suppliers updated successfully');
    };

    const renderClientEditableCell = (location, supplierId, field, value) => {

        if (field === 'terms') {
            return renderClientDropdownTermsCell(location, supplierId, field, value);
        }

        if (field === 'email_updated' || field === 'email_updated' || field === 'automatic_email_updates' || field === 'credit_card_updated' ) {
            return renderClientDropdownCell01(location, supplierId, field, value);
        }

        if (field === 'status') {
            return renderClientDropdownStatusCell(location, supplierId, field, value);
        }

        return (
        <input
            type="text"
            value={
                editedClientSuppliers[`${location}-${supplierId}`]?.[field] ?? 
                clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId)?.[field] ?? 
                ''
            }
            onChange={(e) => handleClientInputChange(e, location, supplierId, field)}
            style={{ width: '100%' }}
        />
        )
        };

    const handleClientInputChangeDropDown = (e, location, supplierId, field) => {
        const value = e.target.value;
        const key = `${location}-${supplierId}`;
    
        if (field === 'ordering_preference' && value === '0') {
            const supplier = {
                ...clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId),
                ...editedClientSuppliers[key],
            };
            const { username, password } = supplier;
            if (!username || !password) {
                toast.error('Username and Password must be filled out to select Platform.');
                return;
            }
        }

    
        setEditedClientSuppliers((prevEditedClientSuppliers) => {
            const currentSupplier = clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId);
            const updatedSupplier = {
                ...currentSupplier,
                ...prevEditedClientSuppliers[key],
                [field]: value
            };
            return {
                ...prevEditedClientSuppliers,
                [key]: updatedSupplier
            };
        });
    };
    
    const renderClientDropdownCell = (location, supplierId, field, value) => (
        <select
            value={
                editedClientSuppliers[`${location}-${supplierId}`]?.[field] ?? 
                clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId)?.[field] ?? 
                value
            }
            onChange={(e) => handleClientInputChangeDropDown(e, location, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="0">Platform</option>
            <option value="1">Representative</option>
        </select>
    );

    const renderClientDropdownCell01 = (location, supplierId, field, value) => (
        <select
            value={
                editedClientSuppliers[`${location}-${supplierId}`]?.[field] ?? 
                clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId)?.[field] ?? 
                value
            }
            onChange={(e) => handleClientInputChangeDropDown(e, location, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="0">No</option>
            <option value="1">Yes</option>
        </select>
    );

    const renderClientDropdownStatusCell = (location, supplierId, field, value) => (
        <select
            value={
                editedClientSuppliers[`${location}-${supplierId}`]?.[field] ?? 
                clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId)?.[field] ?? 
                value
            }
            onChange={(e) => handleClientInputChangeDropDown(e, location, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="0">Inactive</option>
            <option value="1">Active</option>
        </select>
    );

    const renderClientDropdownTermsCell = (location, supplierId, field, value) => (
        <select
            value={
                editedClientSuppliers[`${location}-${supplierId}`]?.[field] ?? 
                clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId)?.[field] ?? 
                value
            }
            onChange={(e) => handleClientInputChangeDropDown(e, location, supplierId, field)}
            style={{ width: '100%' }}
        >
            <option value="7">7</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
            <option value="120">120</option>


        </select>
    );

    const updateClientSupplier = async (location, supplierId) => {
        const key = `${location}-${supplierId}`;
        const updatedSupplier = editedClientSuppliers[key];
    
        try {
            const response = await fetch(`${call}/update-client-suppliers/${supplierId}/${location}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedSupplier)
            });
            if (response.ok) {
                fetchClientSuppliers();
            } else {
                toast.error('Failed to update client supplier');
            }
        } catch (error) {
            console.error('Error updating client supplier:', error);
            toast.error('Failed to update client supplier');
        }
    };
    

    const handleClientInputChange = (e, location, supplierId, field) => {
        const value = e.target.value;
        const key = `${location}-${supplierId}`;
        setEditedClientSuppliers((prevEditedClientSuppliers) => {
            const currentSupplier = clientSuppliers.find(s => s.location === location && s.supplier_id === supplierId);
            const updatedSupplier = {
                ...currentSupplier,
                ...prevEditedClientSuppliers[key],
                [field]: value
            };
            return {
                ...prevEditedClientSuppliers,
                [key]: updatedSupplier
            };
        });
    };

    const updateAllClientSuppliers = async () => {
        const updates = Object.keys(editedClientSuppliers).map(key => {
            const [location, supplierId] = key.split('-');
            return updateClientSupplier(location, supplierId);
        });
        await Promise.all(updates);
        setEditedClientSuppliers({});
        toast.success('All client suppliers updated successfully');
    };

    const renderRepresentativeEditableCell = (repId, field, value) => {
        if (field === 'status') {
            return renderDropdownStatusCell(repId, field, value);
        }
    
        return (
            <input
                type="text"
                value={editedRepresentative[repId]?.[field] || value}
                onChange={(e) => handleRepresentativeInputChange(e, repId, field)}
                style={{ width: '100%' }}
            />
        );
    };    


    const handleRepresentativeInputChange = (e, repId, field) => {
        const value = e.target.value;
        setEditedRepresentative((prevEditedRepresentative) => {
            const updatedRepresentatives = { ...prevEditedRepresentative };
            if (!updatedRepresentatives[repId]) {
                updatedRepresentatives[repId] = { ...representatives.find(r => r.rep_id === repId) };
            }
            updatedRepresentatives[repId] = {
                ...updatedRepresentatives[repId],
                [field]: value
            };
            return updatedRepresentatives;
        });
    };

    const renderDropdownStatusCell = (repId, field, value) => (
        <select
            value={editedRepresentative[repId]?.[field] || value}
            onChange={(e) => handleRepresentativeInputChange(e, repId, field)}
            style={{ width: '100%' }}
        >
            <option value="0">Inactive</option>
            <option value="1">Active</option>
        </select>
    );
    
    

    const updateRepresentative = async (repId) => {
        const updatedRepresentative = editedRepresentative[repId];

        try {
            const response = await fetch(`${call}/update-representative/${repId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedRepresentative)
            });
            if (response.ok) {
                fetchRepresentatives();
            } else {
                toast.error('Failed to update representative');
            }
        } catch (error) {
            console.error('Error updating representative:', error);
            toast.error('Failed to update representative');
        }
    };

    const updateAllRepresentatives = async () => {
        const updates = Object.keys(editedRepresentative).map(repId => updateRepresentative(repId));
        await Promise.all(updates);
        toast.success('All representatives updated successfully');
    };

    const filteredSuppliers = medvelleSuppliers.filter(supplier =>
        supplier.supplier_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredClientSuppliers = clientSuppliers.filter(supplier =>
        supplier.location.toLowerCase().includes(searchLocation.toLowerCase())
    );

    const filteredReps = representatives.filter(rep => {
        const fullName = `${rep.rep_first_name.toLowerCase()} ${rep.rep_last_name.toLowerCase()}`;
        return (
            fullName.includes(searchName.toLowerCase()) &&
            (!searchID || rep.rep_id === parseInt(searchID))
        );
    });
    

    return (
        <div ref={contentRef} style={{ textAlign: 'left', margin: '20px' }}>
            <h1 style={{ fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>Supplier Dashboard</h1>

            <div style={{ width: '480px', margin: '0 auto' }}>
                <span 
                    onClick={() => handleTabClick('Medvelle')}
                    style={{
                        marginBottom: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Medvelle' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px', 
                        fontWeight: 'bold', 
                        cursor: 'pointer', 
                        marginRight: '20px'
                    }}
                >
                    Medvelle
                </span>

                <span 
                    onClick={() => handleTabClick('Client')}
                    style={{
                        marginLeft: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Client' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px', 
                        fontWeight: 'bold',
                        cursor: 'pointer', 
                        marginRight: '20px'
                    }}
                >
                    Client
                </span>

                <span 
                    onClick={() => handleTabClick('Representative')}
                    style={{
                        marginLeft: '15px',
                        color: '#000000',
                        borderBottom: activeTab === 'Representative' ? '2px solid black' : '2px solid transparent',
                        fontSize: '30px', 
                        fontWeight: 'bold', 
                        cursor: 'pointer' 
                    }}
                >
                    Representative
                </span>
            </div>

            {activeTab === 'Medvelle' && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '20px', marginTop: '40px' }}>
                        <input
                            type="text"
                            placeholder="Search Supplier Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px' }} 
                        />
                    </div>

                    <button 
                        onClick={updateAllSuppliers} 
                        style={{
                            marginBottom: '20px',
                            backgroundColor: '#87D3D7',
                            color: 'white',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Update All
                    </button>
                    
                    <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
                        <table style={{
                            width: '300%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative'
                        }}>
                            <thead 
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1
                                }}>
                                <tr style={{ fontSize: '14px', backgroundColor: 'white' }}>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Supplier ID</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Supplier Name</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Description</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Rep IDs</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Esc Rep IDs</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Cust Service Phone</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Cust Service Email</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Ordering Preference</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Website</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Username</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Password</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Ordering Email</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Terms</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Email Updated</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Auto Email Updates</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>CC Updated</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>MOQ</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Min Free Shipping</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>MOV</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Order Qt Multiple</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Promo Code</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Comments</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSuppliers.map((supplier) => (
                                    <tr key={supplier.supplier_id} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: supplier.supplier_id % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                        <td style={{ padding: '10px' }}>{supplier.supplier_id}</td>
                                        <td style={{ padding: '10px' }}>{supplier.supplier_name}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'description', supplier.description)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'rep_ids', supplier.rep_ids)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'escalation_rep_ids', supplier.escalation_rep_ids)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'cust_service_phone', supplier.cust_service_phone)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'cust_service_email', supplier.cust_service_email)}</td>
                                        <td style={{ padding: '10px' }}>{renderDropdownCell(supplier.supplier_id, 'ordering_preference', supplier.ordering_preference)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'website', supplier.website)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'username', supplier.username)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'password', supplier.password)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'ordering_email', supplier.ordering_email)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'terms', supplier.terms)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'email_updated', supplier.email_updated)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'automatic_email_updates', supplier.automatic_email_updates)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'credit_card_updated', supplier.credit_card_updated)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'min_order_quantity', supplier.min_order_quantity)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'min_free_shipping_value', supplier.min_free_shipping_value)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'min_order_value', supplier.min_order_value)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'order_multiple_quantity', supplier.order_multiple_quantity)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'promo_code', supplier.promo_code)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'comments', supplier.comments)}</td>
                                        <td style={{ padding: '10px' }}>{renderEditableCell(supplier.supplier_id, 'status', supplier.status)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </>
            )}
            {activeTab === 'Client' && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '20px', marginTop: '40px' }}>
                        <input
                            type="text"
                            placeholder="Search Location Name"
                            value={searchLocation}
                            onChange={(e) => setSearchLocation(e.target.value)}
                            style={{ marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px' }} 
                        />
                    </div>


                    
                    <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
                        <table style={{
                            width: '200%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative'
                        }}>
                            <thead 
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1
                                }}>
                                <tr style={{ fontSize: '14px', backgroundColor: 'white' }}>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Location</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Supplier ID</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Loc Min Order Value</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Rep ID</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Escalation Rep ID</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Terms</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Ordering Preference</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Website</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Username</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Password</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Email Updated</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Automatic Email Updates</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Credit Card Updated</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Comments</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Status</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Update</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredClientSuppliers.map((supplier) => (
                                    <tr key={`${supplier.location}-${supplier.supplier_id}`} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: supplier.supplier_id % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                        <td style={{ padding: '10px' }}>{supplier.location}</td>
                                        <td style={{ padding: '10px' }}>{supplier.supplier_id}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'location_min_order_value', supplier.location_min_order_value)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'rep_ids', supplier.rep_ids)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'escalation_rep_ids', supplier.escalation_rep_ids)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'terms', supplier.terms)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientDropdownCell(supplier.location, supplier.supplier_id, 'ordering_preference', supplier.ordering_preference)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'website', supplier.website)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'username', supplier.username)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'password', supplier.password)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'email_updated', supplier.email_updated)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'automatic_email_updates', supplier.automatic_email_updates)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'credit_card_updated', supplier.credit_card_updated)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'comments', supplier.comments)}</td>
                                        <td style={{ padding: '10px' }}>{renderClientEditableCell(supplier.location, supplier.supplier_id, 'status', supplier.status)}</td>
                                        <td>                    
                                            <button 
                                                onClick={updateAllClientSuppliers} 
                                                style={{
                                                    backgroundColor: '#87D3D7',
                                                    color: 'white',
                                                    padding: '6px 8px',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                            Update Row
                                        </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {activeTab === 'Representative' && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '20px', marginTop: '40px' }}>
                        <input
                            type="text"
                            placeholder="Search Representative Name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            style={{ marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px' }} 
                        />

                        <input
                            type="text"
                            placeholder="Search Rep ID"
                            value={searchID}
                            onChange={(e) => setSearchID(e.target.value)}
                            style={{ marginRight: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '100px' }} 
                        />
                    </div>

                    <button 
                        onClick={updateAllRepresentatives} 
                        style={{
                            marginBottom: '20px',
                            backgroundColor: '#87D3D7',
                            color: 'white',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        Update All
                    </button>
                    
                    <div style={{ border: '2px solid #87D3D7', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%', height: '610px', overflow: 'auto' }}>
                        <table style={{
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative'
                        }}>
                            <thead 
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1
                                }}>
                                <tr style={{ fontSize: '14px', backgroundColor: 'white' }}>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Rep ID</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>First Name</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Last Name</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Email</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Phone</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Title</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Comments</th>
                                    <th style={{ borderBottom: '2px solid black', padding: '10px' }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredReps.map((rep) => (
                                    <tr key={rep.rep_id} style={{ fontSize: '12px', border: '1px solid #ddd', backgroundColor: rep.rep_id % 2 === 0 ? '#ebf4f5' : '#ffffff'  }}>
                                        <td style={{ padding: '10px' }}>{rep.rep_id}</td>
                                        <td style={{ padding: '10px' }}>{renderRepresentativeEditableCell(rep.rep_id, 'rep_first_name', rep.rep_first_name)}</td>
                                        <td style={{ padding: '10px' }}>{renderRepresentativeEditableCell(rep.rep_id, 'rep_last_name', rep.rep_last_name)}</td>
                                        <td style={{ padding: '10px' }}>{renderRepresentativeEditableCell(rep.rep_id, 'rep_email', rep.rep_email)}</td>
                                        <td style={{ padding: '10px' }}>{renderRepresentativeEditableCell(rep.rep_id, 'rep_phone', rep.rep_phone)}</td>
                                        <td style={{ padding: '10px' }}>{renderRepresentativeEditableCell(rep.rep_id, 'rep_title', rep.rep_title)}</td>
                                        <td style={{ padding: '10px' }}>{renderRepresentativeEditableCell(rep.rep_id, 'comments', rep.comments)}</td>
                                        <td style={{ padding: '10px' }}>{renderRepresentativeEditableCell(rep.rep_id, 'status', rep.status)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isClientModalOpen}
                onRequestClose={closeClientModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000
                    },
                    content: {
                        width: 'auto',
                        height: '500px',
                        overflowY: 'auto',
                        zIndex: 1000
                    }
                }}
            >
                <h2>Add Client Supplier</h2>
                <form onSubmit={(e) => { e.preventDefault(); addNewClientSupplier(); }}>
                    <div className="input-group">
                        <label>Location</label>
                        <select value={newClientSupplier.location} onChange={(e) => handleNewClientSupplierChange(e, 'location')} required>
                            <option value="" disabled>Select Location</option>
                            {alllocations.map((location) => (
                                <option key={location.location_name} value={location.location_name}>{location.location_name}</option>
                            ))}
                        </select>                    
                    </div>
                    <div className="input-group">
                        <label>Supplier ID</label>
                        <input type="text" value={newClientSupplier.supplier_id} onChange={(e) => handleNewClientSupplierChange(e, 'supplier_id')} required />
                    </div>
                    <div className="input-group">
                        <label>Location Min Order Value</label>
                        <input type="text" value={newClientSupplier.location_min_order_value} onChange={(e) => handleNewClientSupplierChange(e, 'location_min_order_value')} />
                    </div>
                    <div className="input-group">
                        <label>Rep ID</label>
                        <input type="text" value={newClientSupplier.rep_ids} onChange={(e) => handleNewClientSupplierChange(e, 'rep_ids')} />
                    </div>
                    <div className="input-group">
                        <label>Escalation Rep ID</label>
                        <input type="text" value={newClientSupplier.escalation_rep_ids} onChange={(e) => handleNewClientSupplierChange(e, 'escalation_rep_ids')} />
                    </div>
                    <div className="input-group">
                        <label>Terms</label>
                        {renderNewClientSupplierDropdownCell('terms', newClientSupplier.terms)}
                    </div>
                    <div className="input-group">
                        <label>Ordering Preference</label>
                        {renderNewClientSupplierDropdownCell('ordering_preference', newClientSupplier.ordering_preference)}
                    </div>
                    <div className="input-group">
                        <label>Website</label>
                        <input type="text" value={newClientSupplier.website} onChange={(e) => handleNewClientSupplierChange(e, 'website')} />
                    </div>
                    <div className="input-group">
                        <label>Username</label>
                        <input type="text" value={newClientSupplier.username} onChange={(e) => handleNewClientSupplierChange(e, 'username')} />
                    </div>
                    <div className="input-group">
                        <label>Password</label>
                        <input type="text" value={newClientSupplier.password} onChange={(e) => handleNewClientSupplierChange(e, 'password')} />
                    </div>
                    <div className="input-group">
                        <label>Email Updated</label>
                        {renderNewClientSupplierDropdownCell('email_updated', newClientSupplier.email_updated)}
                    </div>
                    <div className="input-group">
                        <label>Automatic Email Updates</label>
                        {renderNewClientSupplierDropdownCell('automatic_email_updates', newClientSupplier.automatic_email_updates)}
                    </div>
                    <div className="input-group">
                        <label>Credit Card Updated</label>
                        {renderNewClientSupplierDropdownCell('credit_card_updated', newClientSupplier.credit_card_updated)}
                    </div>
                    <div className="input-group">
                        <label>Comments</label>
                        <input type="text" value={newClientSupplier.comments} onChange={(e) => handleNewClientSupplierChange(e, 'comments')} />
                    </div>
                    <div className="input-group">
                        <label>Status</label>
                        {renderNewClientSupplierDropdownCell('status', newClientSupplier.status)}
                    </div>
                    <button type="submit" style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', padding: '5px' }}>
                        Submit New Client Supplier
                    </button>
                    <button onClick={closeClientModal} style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', padding: '5px' }}>
                        Cancel
                    </button>
                </form>
            </Modal>

            {activeTab === 'Client' && (
            <button 
                onClick={openClientModal} 
                style={{
                    marginTop: '20px',
                    backgroundColor: '#87D3D7',
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
            >
                Add Client Supplier
            </button>

            )}

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isMedvelleModalOpen}
                onRequestClose={closeMedvelleModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000
                    },
                    content: {
                        width: 'auto',
                        height: '500px',
                        overflowY: 'auto',
                        zIndex: 1000
                    }
                }}
            >
                <h2>Add Medvelle Supplier</h2>
                <form onSubmit={(e) => { e.preventDefault(); addNewSupplier(); }}>
                    <div className="input-group">
                        <label>Supplier ID</label>
                        <input type="text" value={newSupplier.supplier_id} onChange={(e) => handleNewSupplierChange(e, 'supplier_id')} readOnly disabled required />
                    </div>
                    <div className="input-group">
                        <label>Supplier Name</label>
                        <input type="text" value={newSupplier.supplier_name} onChange={(e) => handleNewSupplierChange(e, 'supplier_name')} required />
                    </div>
                    <div className="input-group">
                        <label>Description</label>
                        <input type="text" value={newSupplier.description} onChange={(e) => handleNewSupplierChange(e, 'description')} />
                    </div>
                    <div className="input-group">
                        <label>Rep ID</label>
                        <input type="text" value={newSupplier.rep_ids} onChange={(e) => handleNewSupplierChange(e, 'rep_ids')} />
                    </div>
                    <div className="input-group">
                        <label>Escalation Rep ID</label>
                        <input type="text" value={newSupplier.escalation_rep_ids} onChange={(e) => handleNewSupplierChange(e, 'escalation_rep_ids')} />
                    </div>
                    <div className="input-group">
                        <label>Customer Service Phone</label>
                        <input type="text" value={newSupplier.cust_service_phone} onChange={(e) => handleNewSupplierChange(e, 'cust_service_phone')} />
                    </div>
                    <div className="input-group">
                        <label>Customer Service Email</label>
                        <input type="text" value={newSupplier.cust_service_email} onChange={(e) => handleNewSupplierChange(e, 'cust_service_email')} />
                    </div>
                    <div className="input-group">
                        <label>Ordering Preference</label>
                        {renderNewSupplierDropdownCell('ordering_preference', newSupplier.ordering_preference)}
                    </div>
                    <div className="input-group">
                        <label>Website</label>
                        <input type="text" value={newSupplier.website} onChange={(e) => handleNewSupplierChange(e, 'website')} />
                    </div>
                    <div className="input-group">
                        <label>Username</label>
                        <input type="text" value={newSupplier.username} onChange={(e) => handleNewSupplierChange(e, 'username')} />
                    </div>
                    <div className="input-group">
                        <label>Password</label>
                        <input type="text" value={newSupplier.password} onChange={(e) => handleNewSupplierChange(e, 'password')} />
                    </div>
                    <div className="input-group">
                        <label>Ordering Email</label>
                        <input type="text" value={newSupplier.ordering_email} onChange={(e) => handleNewSupplierChange(e, 'ordering_email')} />
                    </div>
                    <div className="input-group">
                        <label>Terms</label>
                        {renderNewSupplierDropdownCell('terms', newSupplier.terms)}
                    </div>
                    <div className="input-group">
                        <label>Email Updated</label>
                        {renderNewSupplierDropdownCell('email_updated', newSupplier.email_updated)}
                    </div>
                    <div className="input-group">
                        <label>Automatic Email Updates</label>
                        {renderNewSupplierDropdownCell('automatic_email_updates', newSupplier.automatic_email_updates)}
                    </div>
                    <div className="input-group">
                        <label>Credit Card Updated</label>
                        {renderNewSupplierDropdownCell('credit_card_updated', newSupplier.credit_card_updated)}
                    </div>
                    <div className="input-group">
                        <label>Min Order Quantity</label>
                        <input type="text" value={newSupplier.min_order_quantity} onChange={(e) => handleNewSupplierChange(e, 'min_order_quantity')} />
                    </div>
                    <div className="input-group">
                        <label>Min Free Shipping Value</label>
                        <input type="text" value={newSupplier.min_free_shipping_value} onChange={(e) => handleNewSupplierChange(e, 'min_free_shipping_value')} />
                    </div>
                    <div className="input-group">
                        <label>Min Order Value</label>
                        <input type="text" value={newSupplier.min_order_value} onChange={(e) => handleNewSupplierChange(e, 'min_order_value')} />
                    </div>
                    <div className="input-group">
                        <label>Order Multiple Quantity</label>
                        <input type="text" value={newSupplier.order_multiple_quantity} onChange={(e) => handleNewSupplierChange(e, 'order_multiple_quantity')} />
                    </div>
                    <div className="input-group">
                        <label>Promo Code</label>
                        <input type="text" value={newSupplier.promo_code} onChange={(e) => handleNewSupplierChange(e, 'promo_code')} />
                    </div>
                    <div className="input-group">
                        <label>Comments</label>
                        <input type="text" value={newSupplier.comments} onChange={(e) => handleNewSupplierChange(e, 'comments')} />
                    </div>
                    <div className="input-group">
                        <label>Status</label>
                        {renderNewSupplierDropdownCell('status', newSupplier.status)}
                    </div>
                    <button type="submit" style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', padding: '5px' }}>
                        Submit New Supplier
                    </button>
                    <button onClick={closeMedvelleModal} style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', padding: '5px' }}>
                        Cancel
                    </button>
                </form>
            </Modal>
            {activeTab === 'Medvelle' && (

            <button 
                onClick={openMedvelleModal} 
                style={{
                    marginTop: '20px',
                    backgroundColor: '#87D3D7',
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
            >
                Add Medvelle Supplier
            </button>

            )}

            <Modal
                overlayClassName="modal-overlay"
                className="modal-content"
                isOpen={isRepresentativeModalOpen}
                onRequestClose={closeRepresentativeModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1000
                    },
                    content: {
                        width: 'auto',
                        height: '500px',
                        overflowY: 'auto',
                        zIndex: 1000
                    }
                }}
            >
                <h2>Add Representative</h2>
                <form onSubmit={(e) => { e.preventDefault(); addNewRepresentative(); }}>
                    <div className="input-group">
                        <label>Rep Id</label>
                        <input type="text" value={newRepresentative.rep_id} onChange={(e) => handleNewRepresentativeChange(e, 'rep_id')} readOnly disabled required />
                    </div>
                    <div className="input-group">
                        <label>First Name</label>
                        <input type="text" value={newRepresentative.rep_first_name} onChange={(e) => handleNewRepresentativeChange(e, 'rep_first_name')} required />
                    </div>
                    <div className="input-group">
                        <label>Last Name</label>
                        <input type="text" value={newRepresentative.rep_last_name} onChange={(e) => handleNewRepresentativeChange(e, 'rep_last_name')}  />
                    </div>
                    <div className="input-group">
                        <label>Email</label>
                        <input type="email" value={newRepresentative.rep_email} onChange={(e) => handleNewRepresentativeChange(e, 'rep_email')} />
                    </div>
                    <div className="input-group">
                        <label>Phone</label>
                        <input type="text" value={newRepresentative.rep_phone} onChange={(e) => handleNewRepresentativeChange(e, 'rep_phone')} />
                    </div>
                    <div className="input-group">
                        <label>Title</label>
                        <input type="text" value={newRepresentative.rep_title} onChange={(e) => handleNewRepresentativeChange(e, 'rep_title')} />
                    </div>
                    <div className="input-group">
                        <label>Comments</label>
                        <input type="text" value={newRepresentative.comments} onChange={(e) => handleNewRepresentativeChange(e, 'comments')} />
                    </div>
                    <div className="input-group">
                        <label>Status</label>
                        <select value={newRepresentative.status} onChange={(e) => handleNewRepresentativeChange(e, 'status')} style={{ width: '100%' }}>
                            <option value="0">Inactive</option>
                            <option value="1">Active</option>
                        </select>
                    </div>
                    <button type="submit" style={{ border: '3px solid #31bac1', backgroundColor: '#31bac1', padding: '5px' }}>
                        Submit New Representative
                    </button>
                    <button onClick={closeRepresentativeModal} style={{ marginLeft: '10px', border: '3px solid #31bac1', backgroundColor: '#31bac1', padding: '5px' }}>
                        Cancel
                    </button>
                </form>
            </Modal>

            {activeTab === 'Representative' && (

            <button 
                onClick={openRepresentativeModal} 
                style={{
                    marginTop: '20px',
                    backgroundColor: '#87D3D7',
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
            >
                Add Representative
            </button>

            )}


            <ToastContainer/>
        </div>
    );
};

export default Suppliers;
