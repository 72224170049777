
export const webSearch = async (query) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/search-google-shopping?q=${ encodeURIComponent(query) }`);
        const data = await response.json();

        const shoppingResults = data.shopping_results;
        if (shoppingResults) {
            const processedResults = shoppingResults.map(item => ({
                ...item,
                price: parseFloat(item.price.match(/[0-9.]+/)[0]),
                minPrice: (parseFloat(item.price.match(/[0-9.]+/)[0]) * .9).toFixed(2),
                maxPrice: (parseFloat(item.price.match(/[0-9.]+/)[0]) * 1.25).toFixed(2)
            }));
            return processedResults;
        }
        else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching search results:', error);
    }
};

export const getGlobalInventory = async (locationName, setGlobalInventory) => {
    try {
        const requestBody = JSON.stringify({location_name: locationName});
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-global-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();
        setGlobalInventory(data)
        return data
    } catch (error) {
        console.error('Error getting inventory product:', error);
        return null;
    }
};

export const addToInventory = async (productId, locationName, inventoryQuantity, productClass) => {
    try {
        const requestBody = JSON.stringify({productID: productId, selectedLocation: locationName, new_inventory_quantity: inventoryQuantity, product_class: productClass});
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/insert-inventory`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: requestBody
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();
        if (data.affectedRows > 0) {
            return true;
        }
        else return false;
    } catch (error) {
        console.error('Error getting inventory product:', error);
        return null;
    }
};

export const getProductClass = async (productId) => {
    try {
        const response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }/get-product-class/${ productId }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error('Failed to get inventory product');
        }

        const data = await response.json();

        return data?.class ? data.class : 3; // default to 3
    } catch (error) {
        console.error('Error getting product class:', error);
        return 3; // default to 3
    }
};