import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { formatDateText, formatDateTimeText } from '../utils/time_zones/TimeZones';
import { sortArrayByAttribute, handleSortChange, getArrow } from '../utils/tables/sortUtils';
import { fetchCheckDeliveries } from '../utils/locations/locationsUtils';
import SupportForm from '../features/support/SupportForm'
import { fuzzySearch } from '../utils/products/search';

const Orders = ({locationName, call, permissionCode}) => {
  const [orderData, setOrderData] = useState([]); // to handle order data
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState(''); // State to manage order status filter
  const contentRef = useRef();
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [sortAttribute, setSortAttribute] = useState('order_date');
  const [sortAsc, setSortAsc] = useState(false);
  const [isCheckDeliveries, setIsCheckDeliveries] = useState(false);

  useEffect(() => {

    fetchOrders();

    fetchCheckDeliveries(locationName, setIsCheckDeliveries);

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';

  }, [locationName]); 

  const fetchOrders = async () => {

    await fetch(`${call}/orders`) // see Server.js under server folder to see the api call
    .then(response => response.json())
    .then(data => {
      setOrderData(data);
    })
    .catch(error => console.error('Error fetching order recommendation data:', error));

  }

  const statusCategories = ['Placed', 'Backorder', 'Confirmed', 'Open', 'In Review', 'Delivered', 'Shipped'];
  
  const openOrders = orderData
    .filter(item => item.location_name === locationName)
    .filter(item => statusCategories.includes(item.status_cat));
  
  const uniqueManufacturers = Array.from(new Set(openOrders.map(item => item.manufacturer_name)));

  const filteredOrders = sortArrayByAttribute(
    fuzzySearch(openOrders, searchTerm, ['product_name', 'product_description', 'manufacturer_name'])
      .filter(item =>
        (filterStatus === '' || item.status_cat.toLowerCase() === filterStatus.toLowerCase()) &&
        (selectedManufacturer === '' || item.manufacturer_name.toLowerCase() === selectedManufacturer.toLowerCase())
      ),
    sortAttribute,
    sortAsc
  );  

  const uniqueCategories = Array.from(new Set(openOrders.map(item => item.status_cat))); // Get unique categories

  const [showContact, setShowContact] = useState(false);
  const toggleContactInfo = () => {
    setShowContact(!showContact);
  }
  
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState(false);
  const [isTrackingAmazonModalOpen, setIsTrackingAmazonModalOpen] = useState(false);
  const [trackingData, setTrackingData] = useState(null);

  // Function to handle the PUT API call and open modal
  const handleSubmitTrackingNumber = async (trackingNumberInput) => {
      try {
          const response = await fetch(`${call}/get-tracking-order-data`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ tracking_number: trackingNumberInput }),
          });
          const data = await response.json();
          if (response.ok) {
            console.log("Tracking Numbers");
            console.log(data.return_value.tracking_number);
            console.log(trackingNumberInput);
            if (data.return_value.tracking_number === trackingNumberInput){
              setTrackingData(data.return_value);
            }
          } else {
              console.error('Error fetching tracking data:', data);
          }
          setIsTrackingModalOpen(true);
      } catch (error) {
          console.error('Error fetching tracking data:', error);
      }
  };

  // Function to handle the PUT API call and open modal
  const handleSubmitTrackingNumberAmazon = async (trackingNumberInput) => {
    try {
        const response = await fetch(`${call}/get-tracking-order-data-amazon`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tracking_number: trackingNumberInput }),
        });
        const data = await response.json();
        if (response.ok) {
          if (data.data.tracking_number === trackingNumberInput){
            setTrackingData(data.data);
          }
        } else {
            console.error('Error fetching tracking data:', data);
        }
        setIsTrackingAmazonModalOpen(true);
    } catch (error) {
        console.error('Error fetching tracking data:', error);
    }
  };

  const handleTrackButtonClick = async (orderId) => {
    try {
        // Call API to get tracking number
        const response = await fetch(`${call}/get-tracking-number`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ order_id: orderId }),
          });
        const data = await response.json();

        if (response.ok) {
            // If tracking number is received successfully, set it as input
            const { trackingNumber } = data;

            // Then, initiate tracking
            if (trackingNumber == '' || trackingNumber == null){
              setIsTrackingModalOpen(true);
            }
            else if (trackingNumber.startsWith('TBA')) {
              handleSubmitTrackingNumberAmazon(trackingNumber);
            }
            else{
              handleSubmitTrackingNumber(trackingNumber);
            }
        } else {
            setIsTrackingModalOpen(true);
            console.error('Error fetching tracking number:', data.error);
        }
    } catch (error) {
        console.error('Error fetching tracking number:', error);
    }
  };

  const updateOrdersDeliveredManual = async (location_name, order_id, delivered_manual_check) => {
    
    // Toggle the recommendations_active value
    const opposite_delivered_manual_check = !delivered_manual_check ? 1 : 0;

    try {
      const response = await fetch(`${call}/update-orders-delivered-manual`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ location_name, order_id, delivered_manual_check: opposite_delivered_manual_check })
      });

      await fetchOrders();

      if (response.ok) {
        console.log('Orders delivered state updated successfully!');
        // Optionally, you can redirect the user or perform any other action after successful submission
      } else {
        console.error('Failed to update Orders delivered state.');

      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  return (
    <div ref={contentRef} style={{ textAlign: 'left' }}>

    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <h2 style={{ marginTop: '27px', display: 'flex', fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>Your Orders</h2>

    <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: '#87D3D7',  marginTop: '0px',  marginRight: '-5px'}}>
    
    <h1 style={{ fontSize: '20px' }}>?</h1>
    </button>
    {showContact && (
      <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
    )}

      </div>

      <div style={{display: 'flex', justifyContent: 'left', marginBottom: '20px'  }}>
        <input
          type="text"
          placeholder="Search Product Name"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          style={{  marginTop: '-10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        />
        

        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          style={{  marginTop: '-10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        >
          <option value="">All Order Status</option>
          {uniqueCategories.map(status_cat => (
            <option key={status_cat} value={status_cat}>{status_cat}</option>
          ))}
        </select>

        <select
          value={selectedManufacturer}
          onChange={(e) => setSelectedManufacturer(e.target.value)}
          style={{  marginTop: '-10px', marginRight: '20px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
        >
          <option value="">All Manufacturers</option>
          {uniqueManufacturers.sort().map(manufacturer => (
            <option key={manufacturer} value={manufacturer}>
              {manufacturer}
            </option>
          ))}
        </select>

      </div>

      <div style={{ 
        border: '2px solid #87D3D7', 
        display: 'flex', 
        justifyContent: 'left', 
        flexDirection: 'column', 
        width: '100%', 
        maxHeight: '610px', // Set a fixed height
        overflowY: 'auto', // Enable vertical scrolling
        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
      }}>

      <table style={{  
        
        width: '100%', 
        borderCollapse: 'separate', 
        borderSpacing: 0, 
        position: 'relative' // Ensure positioning context
      }}>
        <thead style={{
          position: 'sticky', // Stick the header
          top: 0, // Stick to the top of the table
          
          zIndex: 1 // Ensure it stays above the tbody rows
        }}>
          
            <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
            
              <th onClick={() => handleSortChange('order_date', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{ borderBottom:'2px solid black', padding: '10px', width: '15%'  }}>Date {getArrow('order_date', sortAttribute, sortAsc)}</th>
              <th onClick={() => handleSortChange('product_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '30%'}}>Product Name {getArrow('product_name', sortAttribute, sortAsc)}</th>
              <th onClick={() => handleSortChange('product_description', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '20%'}}>Description {getArrow('product_description', sortAttribute, sortAsc)}</th>
              <th onClick={() => handleSortChange('manufacturer_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Manufacturer {getArrow('manufacturer_name', sortAttribute, sortAsc)}</th>
              <th onClick={() => handleSortChange('unit_price', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell'}}>Unit Price {getArrow('unit_price', sortAttribute, sortAsc)}</th>
              <th onClick={() => handleSortChange('order_quantity', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '5%', textAlign: 'center'}}>Quantity {getArrow('order_quantity', sortAttribute, sortAsc)}</th>
              <th onClick={() => handleSortChange('total', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '10%', textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell'}}>Total {getArrow('total', sortAttribute, sortAsc)}</th>
              <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Status</th>
              <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Track</th>
              {isCheckDeliveries &&     
                <th style={{  borderBottom:'2px solid black', padding: '10px', width: '10%', textAlign: 'center'}}>Delivered?</th>
              }
            </tr>

          </thead>
        
          <tbody>
          {/* <div style={{height: "2px", backgroundColor: "#000000", width: "200%"}}></div> */}
            {filteredOrders.map((item,index) => (
              <tr key={item.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                
                <td style={{ padding: '10px', }}>{formatDateText(item.order_date)}</td>
                <td style={{  padding: '10px', }}>{item.product_name}</td>
                <td style={{ padding: '10px',  }}>{item.product_description}</td>
                <td style={{padding: '10px' ,textAlign: 'center' }}>{item.manufacturer_name}</td>
                <td style={{ padding: '10px', textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell' }}>{item.status_cat === "In Review" ? '' : `$${parseFloat(item.unit_price).toFixed(2)}`}</td>
                <td style={{  padding: '10px', textAlign: 'center'}}>{item.order_quantity}</td>
                <td style={{ padding: '10px' , textAlign: 'center', display: permissionCode === 26 ? 'none' : 'table-cell' }}>{item.status_cat === "In Review" ? "" : `$${parseFloat(item.unit_price * item.order_quantity).toFixed(2)}`}</td>
                <td style={{ padding: '10px' , textAlign: 'center'}}>{item.status_cat}</td>
                <td style={{ padding: '10px', textAlign: 'center' }}>
                    {(item.status_cat === 'Delivered' || item.status_cat === 'Shipped') && (
                        <button className='support-button' onClick={() => handleTrackButtonClick(item.order_id)} style={{ fontSize:'small' }}>Track</button>
                    )}
                </td>
                {isCheckDeliveries &&     
                  <td style={{ padding: '10px', textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        checked={item.delivered_manual_check}
                        onChange={() => updateOrdersDeliveredManual(locationName, item.order_id, item.delivered_manual_check)}
                        style={{width:'100%'}}
                      />
                  </td>                
                }
              </tr>
            ))}
          </tbody>
        
          </table>
      </div>

      {/* Modal to display tracking information */}
      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={isTrackingModalOpen}
        onRequestClose={() => {setIsTrackingModalOpen(false); setTrackingData(null);}}
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2,
            },
            content: {
              width: '50%',
              height: '80%',
              overflowY: 'auto',
              padding: '20px',
            }
        }}
    >
      <div>
        
        {trackingData && trackingData.success ? (
          <div>
              <h2 style={{textAlign: 'center'}}>Tracking Information</h2>
              <div style={{ 
                margin: 'auto', 
                textAlign: 'left', 
                maxWidth: 'fit-content', 
                marginBottom: '50px',
                backgroundColor: '#fff', // White background color
                borderRadius: '10px', // Rounded border
                padding: '20px' // Add padding for spacing
              }}>
                <p><h4>Carrier:</h4> <p style={{color: 'black'}}>{trackingData.carrier}</p></p>
                <p><h4>Summary:</h4> <p style={{color: 'black'}}>{trackingData.summary}</p></p>
                <p><h4>Is Delivered:</h4><p style={{color: 'black'}}>{trackingData.is_delivered ? 'Yes' : 'No'}</p></p>
                {!trackingData.is_delivered && <p><h4>Estimated delivery:</h4><p style={{color: 'black'}}> {trackingData.estimated_delivery}</p></p>}
              </div>


              <br/>

              <VerticalTimeline>
                  {trackingData.scans_detail.map((scan, index) => (
                      <VerticalTimelineElement
                          key={index}
                          contentStyle={{ background: '#f0f0f0', color: '#000' }}
                          contentArrowStyle={{ borderRight: '7px solid  #f0f0f0' }}
                          date={<div style={{marginTop:'-20px'}}><p style={{margin:'20px'}}>{scan.timestamp}</p></div>}
                          iconStyle={{ background: '#F49C7D', color: '#000' }}
                      >
                          <p style={{textAlign:'center', marginTop: '5px'}}>{scan.status} - {scan.location}</p>
                      </VerticalTimelineElement>
                  ))}
              </VerticalTimeline>

              <br/>
              <br/>
              <div style={{textAlign:'center'}}>
                <button onClick={() => setIsTrackingModalOpen(false)} style={{ backgroundColor: '#87D3D7', color: 'black', border: '1px solid black', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Close</button>
              </div>
          </div>
        )
        :
        (
          <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} >
            <h2 style={{textAlign: 'center'}}>Tracking Information</h2>
            <div style={{textAlign: 'center'}}>Could not find tracking data for this order.</div>

            <br/>
            <br/>
            <div style={{textAlign:'center'}}>
              <button onClick={() => setIsTrackingModalOpen(false)} style={{ backgroundColor: '#87D3D7', color: 'black', border: '1px solid black', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Close</button>
            </div>
          </div>

        )
        }
        </div>
      </Modal>

      {/* Modal to display tracking information */}
      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={isTrackingAmazonModalOpen}
        onRequestClose={() => {setIsTrackingAmazonModalOpen(false); setTrackingData(null);}}
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2,
            },
            content: {
              width: '50%',
              height: '80%',
              overflowY: 'auto',
              padding: '20px',
            }
        }}
    >
      <div>
        
        {trackingData && trackingData.events && trackingData.events.length > 0 ? (
          <div>
              <h2 style={{textAlign: 'center'}}>Tracking Information</h2>
              <div style={{ 
                margin: 'auto', 
                textAlign: 'left', 
                maxWidth: 'fit-content', 
                marginBottom: '50px',
                backgroundColor: '#fff', // White background color
                borderRadius: '10px', // Rounded border
                padding: '20px' // Add padding for spacing
              }}>
                <p><h4>Carrier:</h4> <p style={{color: 'black'}}>{trackingData.service}</p></p>
                <p><h4>Summary:</h4> <p style={{color: 'black'}}>{trackingData.dispatch_code.desc}</p></p>
                <p><h4>Is Delivered:</h4><p style={{color: 'black'}}>{trackingData.dispatch_code.desc === 'Delivered' ? 'Yes' : 'No'}</p></p>
              </div>


              <br/>

              <VerticalTimeline>
                  {trackingData.events.map((scan, index) => (
                      <VerticalTimelineElement
                          key={index}
                          contentStyle={{ background: '#f0f0f0', color: '#000' }}
                          contentArrowStyle={{ borderRight: '7px solid  #f0f0f0' }}
                          date={<div style={{marginTop:'-20px'}}><p style={{margin:'20px'}}>{formatDateTimeText(scan.timestamp)}</p></div>}
                          iconStyle={{ background: '#F49C7D', color: '#000' }}
                      >
                          <p style={{textAlign:'center', marginTop: '5px'}}>{scan.status} - {scan.location}</p>
                      </VerticalTimelineElement>
                  ))}
              </VerticalTimeline>

              <br/>
              <br/>
              <div style={{textAlign:'center'}}>
                <button onClick={() => setIsTrackingAmazonModalOpen(false)} style={{ backgroundColor: '#87D3D7', color: 'black', border: '1px solid black', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Close</button>
              </div>
          </div>
        )
        :
        (
          <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} >
            <h2 style={{textAlign: 'center'}}>Tracking Information</h2>
            <div style={{textAlign: 'center'}}>Could not find tracking data for this order.</div>

            <br/>
            <br/>
            <div style={{textAlign:'center'}}>
              <button onClick={() => setIsTrackingAmazonModalOpen(false)} style={{ backgroundColor: '#87D3D7', color: 'black', border: '1px solid black', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}>Close</button>
            </div>
          </div>

        )
        }
        </div>
      </Modal>


      
    </div>
  );
};

export default Orders;