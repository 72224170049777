// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7; /* Light grey background */
}

.login-form {
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: white; /* White background for the form */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Spacing between elements */
}

.input-field {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd; /* Light grey border */
    font-size: 16px;
}

.login-button {
    padding: 15px;
    border-radius: 5px;
    border: none;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-button:hover {
    background-color: #0056b3; /* Darken the button on hover */
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,0BAA0B;AACzD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2CAA2C;IAC3C,iBAAiB,EAAE,kCAAkC;IACrD,aAAa;IACb,sBAAsB;IACtB,SAAS,EAAE,6BAA6B;AAC5C;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB,EAAE,sBAAsB;IAC9C,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,yBAAyB,EAAE,4BAA4B;IACvD,YAAY;IACZ,eAAe;IACf,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB,EAAE,+BAA+B;AAC9D","sourcesContent":[".login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f7f7f7; /* Light grey background */\n}\n\n.login-form {\n    padding: 40px;\n    border-radius: 15px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    background: white; /* White background for the form */\n    display: flex;\n    flex-direction: column;\n    gap: 20px; /* Spacing between elements */\n}\n\n.input-field {\n    padding: 15px;\n    border-radius: 5px;\n    border: 1px solid #ddd; /* Light grey border */\n    font-size: 16px;\n}\n\n.login-button {\n    padding: 15px;\n    border-radius: 5px;\n    border: none;\n    background-color: #007bff; /* Bootstrap primary color */\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.login-button:hover {\n    background-color: #0056b3; /* Darken the button on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
