import React, {useEffect, useState, useRef} from 'react';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane, faTimes} from '@fortawesome/free-solid-svg-icons';
import '../../assets/style/index.css'; // Make sure to create a corresponding CSS file for styling
import {sendEmail} from '../../utils/notifications/notificationsUtils';
import {emailTemplates} from '../../utils/notifications/emailTemplates';
import {toast} from 'react-toastify';
import {insertData, updateData, fetchLastSupportTicketId, parseEmails} from '../../utils/support/supportUtils';
import {fetchUser, fetchUsername, fetchUserListAll} from '../../utils/users/userUtils';
import {fetchLocations} from '../../utils/locations/locationsUtils';
import loadingSpinner from '../../assets/images/loading.gif';

const SupportForm = ({callBack, locationName, permissionCode, supportCategory = ''}) => {
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [selectedSupportCategory, setSelectedSupportCategory] = useState('');
    const [selectedUrgencyCategory, setSelectedUrgencyCategory] = useState('');
    const [isSent, setIsSent] = useState(false); 
    const [isSuccessful, setIsSuccessful] = useState(true); //todo: change

    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [user, setUser] = useState('');
    const [username, setUsername] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [userList, setUserList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const supportEmail = 'info@medvelle.com';
    const supportNumber = '(646) 665-7066';

    const isInternal = permissionCode?.toString().startsWith('1') ?? false;
    const [sendToEmail, setSendToEmail] = useState(isInternal ? [''] : [localStorage.getItem('userEmail')]);
    const supportCategories = [
        'Billing and Payment',
        'Feedback',
        'Order Status',
        'Returns and Exchanges',
        'Shipping and Delivery',
        'Technical Support',
        'Promotion Submission'
    ];
    const urgencyCategories = ['Low', 'Medium', 'High'];


    useEffect(() => {
        if (isInternal) {
            fetchUserListAll(setUserList);
            fetchLocations(setLocationList);
        }
        fetchUser(setUser);
        fetchUsername(setUsername);
        setSelectedSupportCategory(supportCategory)
    }, []);
    useEffect(() => {
        if (selectedUser?.email) {
            setSendToEmail([selectedUser.email]);
        }
    }, [selectedUser]);


    const closeModal = () => {
        setModalIsOpen(false);
        callBack(false);
    };


    const handleSendSupportForm = async () => {
        setIsSending(true);
        if (isInternal && (!selectedLocation || !selectedUser)) {
            setError('user_location');
            toast.error('Please select user and location.'); // Optional
            setIsSending(false);
            return;
        }
        if (!selectedSupportCategory) {
            setError('category');
            toast.error('Please select a support inquiry.'); // Optional
            setIsSending(false);
            return;
        }
        if (!selectedUrgencyCategory) {
            setError('urgency');
            toast.error('Please select an urgency level.'); // Optional
            setIsSending(false);
            return;
        }

        setError('');

        const ticket_increment = 5;
        const last_ticket_id = await fetchLastSupportTicketId();
        const new_ticket_id = last_ticket_id + ticket_increment

        const origin = isInternal ? 'internal' : 'email';

        // Create a new support ticket.
        const supportData = {
            ticket_id: new_ticket_id,
            username: isInternal ? selectedUser : username,
            location_name: isInternal ? selectedLocation : locationName,
            ticket_urgency: selectedUrgencyCategory,
            tag: selectedSupportCategory,
            ticket_subject: subject,
            origin: origin,
            ticket_description: description,
        };
        const insertInfo = await insertData(supportData, 'support_tickets');
        if (!insertInfo) {
            setIsSuccessful(false);
            setIsSent(true);
            setIsSending(false);
            return;
        }
        // Send a confirmation email
        const details = {
            user: isInternal ? userList.find(user => user.username === selectedUser).name : user.name,
            tag: selectedSupportCategory,
            ticket_urgency: selectedUrgencyCategory,
            ticket_subject: subject,
            ticket_description: description,
            ticket_id: new_ticket_id
        };
        const emailTemplate = isInternal ? 'Support Ticket Internal' : 'Support Ticket';
        const emailData = {
            recipient_emails: sendToEmail,
            sender_email: supportEmail, 
            cc_emails: [],
            subject: `${ subject }: Support Ticket ${ new_ticket_id }`,
            message: emailTemplates[emailTemplate](details)
        };
        const conversationInfo = await sendEmail(emailData);
        if (!conversationInfo) {
            setIsSuccessful(false);
            setIsSent(true);
            setIsSending(false);
            return;
        }
        const supportUpdateData = {conversation_id: conversationInfo.conversation_id, email_id: conversationInfo.email_id, };

        // Update with the new email
        await updateData(supportUpdateData, {'ticket_id': new_ticket_id}, 'support_tickets', ['last_email_datetime']);
        setIsSent(true);
        setIsSuccessful(true);
        setIsSending(false);
    };

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={modalIsOpen}
            appElement={document.getElementById('#root')}

            onRequestClose={() => {
                // Call toggleNotificationModal only when the modal is closed
                if (modalIsOpen) {
                    closeModal();
                }
            }}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2,

                },
                content: {
                    height: 'fit-content',
                    background: '#f5f5f5',
                    border: '2px solid #87D3D7',
                    borderRadius: '8px',
                    overflow: 'auto'
                }
            }}
        >
            {!isSent && (<div style={{
                flexDirection: "column", display: 'flex',
                gap: '15px',
            }}>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center', // Center the header
                    alignItems: 'center',
                    overflow: 'auto',
                    position: 'sticky',
                    top: '0px',
                    right: '0px'
                }}>
                    <div style={{width: '20px'}}></div>
                    <h2 style={{
                        textAlign: 'center',
                        flex: 1 // Allow the header to take up remaining space
                    }}>
                        {`${ isInternal ? 'Internal ' : '' }Support Form`}
                    </h2>
                    <button className="support-button" style={{
                        marginLeft: 'auto', // Push the button to the far right
                        fontSize: 'medium',
                        postion: 'absolute',
                        top: '0',
                        right: '0'

                    }} onClick={closeModal}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                </div>

                {isInternal && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between', /* Evenly space items along the main axis */
                        alignItems: 'left', /* Align items vertically centered */
                        width: '100%',
                    }}>
                        <select value={selectedUser ? selectedUser : ''}
                            style={{
                                marginRight: '10px',
                                borderColor: error === 'user_location' ? 'red' : undefined, // Set border color conditionally
                                width: '50%',
                            }}
                            onChange={e => {
                                const selectedEmail = e.target.selectedOptions[0].getAttribute('data-email');
                                const userLocation = e.target.selectedOptions[0].getAttribute('data-location');
                                setSelectedUser(e.target.value);
                                setSendToEmail([selectedEmail]);
                                setSelectedLocation(userLocation);
                            }} // jumpy
                            className="select-box"
                        >
                            <option key={''} value={''} data-email={''} data-location={''}>Select user</option>
                            <option key={'external'} value={'external'} data-email={''} data-location={''}>External</option>
                            {userList.map(item => (
                                <option key={item.username} value={item.username} data-email={item.email} data-location={item.location}>
                                    {item.name} ({item.username})
                                </option>
                            ))}
                        </select>
                        <select value={selectedLocation ? selectedLocation : ''}
                            style={{
                                width: '50%',

                                borderColor: error === 'user_location' ? 'red' : undefined, // Set border color conditionally
                            }}
                            onChange={e => {
                                setSelectedLocation(e.target.value);
                            }} // jumpy
                            className="select-box"
                        >
                            <option key={''} value={''}>Select location</option>
                            <option key={'external'} value={'external'} data-email={''} data-location={''}>External</option>
                            {locationList.map(item => (
                                <option key={item.location_name} value={item.location_name}>
                                    {item.location_name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <select value={selectedSupportCategory ? selectedSupportCategory : ''}
                    style={{
                        borderColor: error === 'category' ? 'red' : undefined, // Set border color conditionally
                    }}
                    onChange={e => {
                        setSelectedSupportCategory(e.target.value);
                    }} // jumpy
                    className="select-box"
                >
                    <option key={''} value={''}>Select support category</option>
                    {supportCategories.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>

                <select value={selectedUrgencyCategory ? selectedUrgencyCategory : ''} onChange={e => {
                    setSelectedUrgencyCategory(e.target.value);
                }} // jumpy
                    className="select-box"
                    style={{
                        borderColor: error === 'urgency' ? 'red' : undefined, // Set border color conditionally
                    }}
                >

                    <option key={''} value={''}>Select urgency</option>
                    {urgencyCategories.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>

                <label htmlFor="email" >Email:</label>
                <input
                    type="text"
                    className="select-box"
                    value={sendToEmail}
                    onChange={(e) => setSendToEmail(parseEmails(e.target.value))} // Call handleChange on input change
                    placeholder="Your email..."
                    style={{cursor: 'text'}}
                />

                <label htmlFor="subject">Subject:</label>
                <input
                    type="subject"
                    className="select-box"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)} // Call handleChange on input change
                    placeholder="Email subject..."
                    style={{cursor: 'text'}}

                />
                <label htmlFor="description">Provide Details:</label>

                <textarea
                    id="description"
                    className="select-box"

                    name="description"
                    onChange={(e) => setDescription(e.target.value)} // Update state on change
                    rows="4"
                    required
                    style={{height: '120px', cursor: 'text'}} // Adjust size as needed
                ></textarea>
                {isSending && (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                        <img
                            src={loadingSpinner}
                            alt="loading-spinner"
                            style={{
                                height: '20px',
                                marginRight: '10px',
                                padding: '10px'
                            }}
                        />
                    </div>
                )}
                {!isSending && (
                    <button
                        type='notsubmit'
                        onClick={() => {
                            setIsSending(true);

                            handleSendSupportForm();
                        }
                        }
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        className='support-button'
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{marginRight: '8px'}} />
                        Send
                    </button>
                )}
            </div>
            )}

            {(isSent && isSuccessful) && (
                <>
                    <div>
                        <h2 style={{textAlign: 'center'}}>
                            Submitted!
                        </h2>
                        <div>We will try to email you in the next 48 hours with a response.</div>
                        <br />
                        <div>If you have an urgent issue please call: {supportNumber}.</div>
                    </div>
                    <div style={{
                        borderRadius: '5px',
                        width: '90%',
                        padding: '20px',
                        flexDirection: "column", display: 'flex',
                        gap: '15px',

                        // boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                    }}>
                        <button onClick={() => {closeModal();}} style={{
                            width: '50%',
                            alignContent: 'center',
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#87D3D7',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '4px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                            outline: 'none',
                        }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#6CA0A7')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#87D3D7')}
                        >Finish</button>
                    </div>
                </>
            )}
            {(isSent && !isSuccessful) && (
                <>
                    <div>
                        <h2 style={{textAlign: 'center'}}>
                            Not Submitted!
                        </h2>
                        <div>Your support request did not process.</div>
                        <br />
                        <div>Please contact us directly at {supportEmail} or call us at {supportNumber}.</div>
                    </div>
                    <div style={{
                        borderRadius: '5px',
                        width: '90%',
                        padding: '20px',
                        flexDirection: "column", display: 'flex',
                        gap: '15px',

                        // boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                    }}>
                        <button 
                            onClick={() => {closeModal();}} 
                            style={{
                                width: '50%',
                                alignContent: 'center',
                                alignSelf: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#87D3D7',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '10px 20px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',
                                outline: 'none',
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#6CA0A7')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#87D3D7')}
                        >
                            Finish
                        </button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default SupportForm;
