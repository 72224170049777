import React, { useState } from 'react';
import Modal from 'react-modal';
import ProgressBar from '../../components/ProgressBar.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const DeliveryIssueModal = ({ isOpen, onClose, orderDetails, submitReturn }) => {
  const steps = [
    { title: 'Policy' },
    { title: 'Delivery Issue' },
    { title: 'Additional Details' },
    { title: 'Next Steps' }
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [reason, setReason] = useState('');
  const [returnOrReplace, setReturnOrReplace] = useState('Return');
  const [urgency, setUrgency] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleNextStep = async () => {
    if (currentStep === 1 && !isCheckboxChecked) {
      alert('Please acknowledge the policy before proceeding');
      return;
    }
    if (currentStep === 2 && !reason) {
      alert('Please select a reason');
      return;
    }

    if (currentStep === 3 && (!additionalDetails || !urgency)) {
      alert('Please fill out the details and urgency');
      return;
    }
    if (currentStep === steps.length - 1) {
      handleReturn();
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleModalClose = () => {
    setCurrentStep(1);
    setReason('');
    setReturnOrReplace('');
    setUrgency('');
    setAdditionalDetails('');
    setIsCheckboxChecked('');
    onClose();
  };

  const handleReturn = () => {
    if (!additionalDetails && !urgency) {
      return;
    }
    const returnData = {
      reason: reason,
      returnMethod: 'Return',
      additionalDetails: additionalDetails,
      urgency: urgency,
    };
    submitReturn(returnData);
    toast.success('Dispute request submitted successfully');

    setReason('');
    setUrgency('');
    setReturnOrReplace('');
    setAdditionalDetails('');
    setIsCheckboxChecked('');
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div style={{ height: '80%', overflowY: 'auto', padding: '0.5rem' }}>
            <p style={{ marginBottom: '3rem' }}>
              Medvelle’s return policy aligns with the policies set by our suppliers. We will make every effort to assist
              you throughout the return process, adhering to the guidelines and conditions established by the respective supplier. Please note that
              the final decision regarding returns is subject to the supplier’s approval and terms.
            </p>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                style={{  marginLeft: '39%', marginRight: '-1%'  }}
              />
              I understand
            </label>
          </div>
        );
      case 2:
        return (
          <div>
            <h3>Select Delivery Issue:</h3>
            <select
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ padding: '0.5rem', width: '100%' }}
            >
              <option value="">Select Reason</option>
              <option value="Late Delivery">Late Delivery</option>
              <option value="Never Arrived">Never Arrived</option>
              <option value="Damaged Item">Damaged Item</option>
              <option value="Not Needed">Not Needed</option>
            </select>
          </div>
        );
      case 3:
        return (
          <div>
            <h3 style={{ marginTop: '1.5rem' }}>Additional Details:</h3>
            <textarea
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
              style={{ width: '100%', height: '5rem', padding: '0.5rem' }}
              placeholder="Enter additional details here..."
            />
            <h3>Return Urgency:</h3>
            <select
              value={urgency}
              onChange={(e) => setUrgency(e.target.value)}
              style={{ padding: '0.5rem', width: '100%', marginBottom: '1rem' }}
            >
              <option value="">Select Option</option>
              <option value="1 business day">Urgent Request</option>
              <option value="2-3 business days">Quick Turnaround</option>
              <option value="3-5 business days">Standard Timeline</option>
              <option value="5+ business days">Flexible Schedule</option>
            </select>
          </div>
        );
      case 4:
        return (
          <div>
            <h3>What Medvelle Will Do Next:</h3>
            <ol style={{ textAlign: 'left' }}>
              <li>Review your return request.</li>
              <li>Contact the supplier for approval.</li>
              <li>Arrange for the return shipping label.</li>
              <li>Process the refund or replacement.</li>
              <li>Send you confirmation once complete.</li>
            </ol>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      overlayClassName="white-modal-overlay"
      className="white-modal"
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      style={{
        content: {
          width: '75vw',
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '0.15rem solid #87D3D7',
        },
      }}
    >
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div>
          <h1 style={{ color: '#87D3D7', marginBottom: '-0.5rem', textAlign: 'center' }}>
            Medvelle Dispute Resolution Assistant
          </h1>
          <button
            className="support-button"
            style={{
              marginLeft: 'auto',
              fontSize: '1rem',
              display: 'flex',
              marginTop: '-2rem',
              marginRight: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              height: '2.5rem',
              width: '2.5rem'
            }}
            onClick={handleModalClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <h3 style={{ color: 'green', marginBottom: '3vh', textAlign: 'center' }}>
          Helping you resolve delivery issue for {orderDetails?.product_name}!
        </h3>

        <ProgressBar steps={steps} currentStep={currentStep} totalSteps={steps.length} />

        <div
          style={{
            width: '40vw',
            height: '30vh',
            marginTop: '3vh',
            marginLeft: '20%',
            border: '0.15rem solid #87D3D7',
            borderRadius: '0.6rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2.5vw'
          }}
        >
          <div>{renderStepContent()}</div>
        </div>
        <div style={{display:'flex', justifyContent:'space-around', marginTop:'8px', paddingBottom: '10px'}}>
          <div style={{display: 'flex', gap: '1rem'}}>
            {(currentStep > 1 && currentStep < steps.length) && (
              <button
                onClick={handleBackStep}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: '#31bac1',
                  fontSize: '1rem',
                  color: 'white',
                  border: 'none',
                  borderRadius: '0.5rem',
                  cursor: 'pointer'
                }}
              >
                {"<"}
              </button>
            )}
          </div>

          <div style={{display: 'flex', gap: '1rem'}}>
            {currentStep < steps.length && (
              <button
                onClick={handleNextStep}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: '#31bac1',
                  fontSize: '1rem',
                  color: 'white',
                  border: 'none',
                  borderRadius: '0.5rem',
                  cursor: 'pointer'
                }}
              >
                {">"}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeliveryIssueModal;