import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tick from '../assets/images/green-tick.png';
import cross from '../assets/images/red-cross.png';
import Modal from 'react-modal';
import '../assets/style/index.css'
import bell from '../assets/images/bell.png';
import bell_notification from '../assets/images/bell_notification.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import LineGraph from '../components/LineGraph';
import SupportForm from '../features/support/SupportForm'

import { formatDateText } from '../utils/time_zones/TimeZones';
import OrderModal from '../components/OrderModal';
import ModalHeader from '../components/ModalHeader';

const Home = ({locationName, call, permissionCode}) => {

  const [orderrecData, setorderrecData] = useState([]); // To handle order recommendation data

  const contentRef = useRef();
  const [inventoryData, setInventoryData] = useState([]);
  const [origin, setOrigin] = useState('');
  const [username, setUsername] = useState('');
  const [orderThreshold, setOrderThreshold ] = useState([]);
  const [isSubmittingFlagResolution, setIsSubmittingFlagResolution] = useState(false);

  // New state variables for order placement popup
  const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [queryResult, setQueryResult] = useState([]);

  const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [messages, setMessages] = useState([]);

  // State variables for notifications and notification modal
  const [notifications, setNotifications] = useState(false);
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState(false);
  const [flagsToResolve, setFlagsToResolve] = useState([]);
  const [flagToResolve, setFlagToResolve] = useState([]);
  const [recommendationDetails, setRecommendationDetails] = useState([]);
  const [moveHistoryNumber, setMoveHistoryNumber] = useState(0);
  const [freeHistoryNumber, setFreeHistoryNumber] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [processedOrders, setProcessedOrders] = useState('');
  const [unprocessedOrders, setUnProcessedOrders] = useState('');
  const [budget, setBudget] = useState('');
  const [productUsageData, setProductUsageData] = useState([]);
  const [recommendationPrediction, setRecommendationPrediction] = useState([]);



  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    fetch(`${call}/get-processed-orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location_name: locationName }),
    })
      .then(response => response.json())
      .then(data => {
        setProcessedOrders(data.processed_orders);
      })
      .catch(error => console.error('Error fetching processed orders:', error));

      fetch(`${call}/get-unprocessed-orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location_name: locationName }),
      })
        .then(response => response.json())
        .then(data => {
          setUnProcessedOrders(data.unprocessed_orders);
        })
        .catch(error => console.error('Error fetching unprocessed orders:', error));

        fetch(`${call}/get-budget`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ location_name: locationName }),
        })
          .then(response => response.json())
          .then(data => {
            setBudget(data.budget);
          })
          .catch(error => console.error('Error fetching budget:', error));

  })


  const userEmail = localStorage.getItem('userEmail');

  const totalOrders = processedOrders + unprocessedOrders;
  const remainingBudget = budget - totalOrders;

  const barColor = remainingBudget >= 0 ? '' : '#e9453b';

  const remainingText = remainingBudget >= 0 
  ? `$${Math.round(remainingBudget).toLocaleString()} Remaining` 
  : `$${Math.abs(Math.round(remainingBudget)).toLocaleString()} Over Budget`

  const processedPercent = (processedOrders/budget) * 100;

  const unprocessedPercent = (unprocessedOrders/budget) * 100;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  // Function to handle mouse hover over the progress bar
  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  // Function to handle mouse leave from the progress bar
  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };


  const [FlagsModalIsOpen, setFlagsModalIsOpen] = useState(false);
  const [FlagsDetailsModalIsOpen, setFlagsDetailsModalIsOpen] = useState(false);
  const [RecommendationsDetailsModalIsOpen, setRecommendationsDetailsModalIsOpen] = useState(false);

  const toggleFlagsModal = () => {
    setFlagsModalIsOpen(!FlagsModalIsOpen);
  };

  const toggleFlagsDetailsModal = () => {
    setFlagsDetailsModalIsOpen(!FlagsDetailsModalIsOpen);
  };

  const toggleRecommendationsDetailsModal = () => {
    setRecommendationsDetailsModalIsOpen(!RecommendationsDetailsModalIsOpen);
  };

  const fetchMessages = async () => {
    try {
      const response = await fetch(`${call}/get-messages`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userEmail: userEmail }) // Include username in the request body
      });
      const data = await response.json();
  
      // Sort messages by date from most recent to oldest
      const sortedMessages = data.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
  
      // Check if any message has read_status = 0
      const hasUnreadMessages = sortedMessages.some(message => message.read_status === 0 && message.location_name === locationName);
  
      if (hasUnreadMessages) {
        setNotifications(true);
      } else {
        setNotifications(false);
      }
  
      setMessages(sortedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };
  

  // Function to open the order placement popup
  const handleOrder = (product_id, title, orderId, quantity, origin, manufacturer_name, orderComment, orderUrgency, item) => {
    if (orderUrgency === null || orderUrgency.trim() === ''){
      orderUrgency = '5+ business days';
    }

    setOrderModalIsOpen(true);
    setOrigin(origin);
    const old_order_quantity = quantity;
    setSelectedProduct({product_id, orderUrgency, old_order_quantity,  title, orderId, quantity, manufacturer_name, item});
    setQueryResult(orderId);
  };

  const [showContact, setShowContact] = useState(false);
  const toggleContactInfo = () => {
    setShowContact(!showContact);
  }

  useEffect(() => {

    fetchMessages();
    fetchFlagsToResolve();
    fetchUsername();
    // Fetch order recommendation data from the server
    // For now, orders will be recommended manually, and the order rec table will be populated manually too
    fetch(`${call}/orders`) // see Server.js under server folder to see the api call
      .then((response) => response.json())
      .then((data) => {
        setorderrecData(data);
      })
      .catch((error) =>
        console.error('Error fetching order recommendation data:', error)
      );

      // Fetch inventory data from the server
    fetch(`${call}/inventory`) 
    .then(response => response.json())
    .then(data => {
      setInventoryData(data);
    })
    .catch(error => console.error('Error fetching inventory data:', error));

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '49px';




  }, [locationName]);

  const fetchRecommendationsPredictions = async (location_name, product_id) => {
    try {
        const response = await fetch(`${call}/recommendation-predictions`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                location_name,
                product_id
            }),
        });

        if (!response.ok) {
            throw new Error('Error fetching recommendation predictions');
        }

        const data = await response.json();

        console.log("data fetch Rc");
        console.log(data);

        setRecommendationPrediction(data);
    } catch (error) {
        console.error('Error fetching recommendation predictions:', error);
    }
  };

  const submitFlagResolution = async (explanation, deltaAfter) => {

    if (!isSubmittingFlagResolution){
      setIsSubmittingFlagResolution(true);

      try {
        await fetch(`${call}/store-flags-resolution`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            location_name: locationName,
            product_id: flagToResolve.product_id,
            tracking_ids: flagToResolve.tracking_ids,
            delta_quantity_initial: flagToResolve.total_delta,
            delta_quantity_before: deltaBeforeProcess,
            delta_quantity_after: deltaAfter,
            explanation: explanation,
            orders: orderDeliveredCheckNumber,
            moved: moveProductNumber,
            free_products: freeProductNumber,
            broken_expired: brokenExpiredNumber,
            gave_away: gaveProductNumber,
            external_orders: externalOrderNumber,
            username: username
          }),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          console.log('Flags resolution stored successfully');
          // Handle success if needed
        })
        .catch(error => {
          console.error('Error submitting flag resolution:', error);
          // Handle error if needed
        });
    
        await handleSubmitResolvedQuantity();
    
        setIsSubmittingFlagResolution(false);
  
        allDone();
      } catch (error) {
        console.log(`Error while submitting flagging resolution: ${error}.`);
        setIsSubmittingFlagResolution(false);
  
        allDone();
      }
      
    }
  };
  

  // Function to fetch the username
  const fetchUsername = () => {
    // Fetch username using the API and the email from local storage
    const userEmail = localStorage.getItem('userEmail');

    if (userEmail) {
      fetch(`${call}/get-user-info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_email: userEmail, location_name: locationName }),
      })
        .then(response => response.json())
        .then(data => {
          setUsername(data.username);
          setOrderThreshold(data.order_approval_limit);
        })
        .catch(error => console.error('Error fetching user information:', error));
    }
  };

  const fetchFlagsToResolve = async () => {
    try {
      const response = await fetch(`${call}/get_flags_to_resolve`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ locationName })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch flags to resolve');
      }
      const data = await response.json();

      setFlagsToResolve(data);
    } catch (error) {
      console.error('Error fetching flags to resolve:', error);
    }
  };

  const getFreeProductsNumber = async (flag) => {
    try {
      const response = await fetch(`${call}/get_number_free_products`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ product_id: flag.product_id, location_name: locationName, begin_date: flag.last_date_check })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch free history number');
      }
      const data = await response.json();

      setFreeHistoryNumber(data['number_free_products']);
    } catch (error) {
      console.error('Error fetching free history number:', error);
    }
  };

  const getMoveProductsNumber = async (flag) => {
    try {
      const response = await fetch(`${call}/get_number_moved_products`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ product_id: flag.product_id, location_name: locationName, begin_date: flag.last_date_check })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch move history number');
      }
      const data = await response.json();

      console.log("data");
      console.log(data);

      setMoveHistoryNumber(data['number_move_products']);
    } catch (error) {
      console.error('Error fetching move history number:', error);
    }
  };

  // This handles the accept-decline button that is there on the home page table, logic needs to be built yet on the server side
  const handleAcceptDecline = async (orderId, quantity, isAccepted) => {
    // If the order is declined, prompt the user for a reason
    if (!isAccepted) {
      setSelectedOrderId(orderId); // Set the selected order ID
      setDeclineModalIsOpen(true);
    }

    if (isAccepted) {
      let finalQuantity = quantity;
      finalQuantity = window.prompt('Please finalize the order quantity:', quantity);
      if(finalQuantity>0){
        const response = await fetch(`${call}/accept-order/${orderId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ isAccepted, finalQuantity }),
        });

        if (response.ok) {
          console.log(`Order ${isAccepted ? 'accepted' : 'declined'} successfully`);
          fetchOrderRecData();
        } else {
          console.error('Error accepting/declining order:', response.statusText);
        }
      }
    };
  }

  const submitDecline = async () => {


    let username = '';
    

    if (userEmail) {
      try {
          const response = await fetch(`${call}/get-username?user_email=${userEmail}`);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const data = await response.json();
          username = data.username;
      } catch (error) {
          console.error('Error fetching username:', error);
          return; // Exit the function if there is an error
      }
  }

    // Logic to send decline reason to the server
    const response = await fetch(`${call}/decline-order/${selectedOrderId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isAccepted: false, declineReason, username }),
    });
  
    if (response.ok) {
      console.log('Order declined successfully');
      fetchOrderRecData();
    } else {
      console.error('Error declining order:', response.statusText);
    }
  
    // Close the decline modal and reset state
    setDeclineModalIsOpen(false);
    setDeclineReason('');
  };
  

  const fetchOrderRecData = () => {
    // Fetch order recommendation data from the server, essentially to refresh the table once the order recommendation is accepted or declined
    fetch(`${call}/orders`)
      .then((response) => response.json())
      .then((data) => {
        setorderrecData(data);
      })
      .catch((error) =>
        console.error('Error fetching order recommendation data:', error)
      );
  };

  const awaitingOrders = orderrecData
  .filter(item => item.status_cat === 'Recommend')
  .filter(item => item.location_name === locationName);

  const locationInventoryData = inventoryData
    .filter(item => item.location_name === locationName);

    const columnWidths = {
      name: "20%", // Adjust widths as per your content's need
      description: "20%",
      manufacturer: "5%",
      price: "5%",
      quantity: "5%",
      reason: "15%",
      button: "10%"
    };

   
  
  // Function to toggle notification modal visibility
  const toggleNotificationModal = () => {
    setNotificationModalIsOpen(!notificationModalIsOpen);
    fetchMessages();
  };

  const handleMessage = async (message) => {
    try {
      const response = await fetch(`${call}/message-read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ messageId: message.notification_id }) // Pass the message id to mark as read
      });
  
      if (response.ok) {
        // If message is marked as read successfully, fetch messages again to update the window
        await fetchMessages();
      } else {
        console.error('Failed to mark message as read');
      }
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  awaitingOrders.sort((a, b) => {
    // Compare by manufacturer name first
    if (a.manufacturer_name < b.manufacturer_name) return -1;
    if (a.manufacturer_name > b.manufacturer_name) return 1;
  
    // If manufacturer is the same, then compare by order reason
    if (a.recommendation_rationale < b.recommendation_rationale) return -1;
    if (a.recommendation_rationale > b.recommendation_rationale) return 1;
  
    // If both are the same, keep original order
    return 0;
  });

  const [countedQuantity, setCountedQuantity] = useState(0);
  const [countedQuantityMatch, setCountedQuantityMatch] = useState(null);

  function funcFactorQuantityConversion(conversionQuantity) {
    let factor = 1 / Math.sqrt(4 * conversionQuantity);
  
    if (factor > 0.5) {
      factor = 0.5;
    } else if (factor < 0.2) {
      factor = 0.2;
    }
    
    return factor;
  }

  const checkDeltaInterval = (delta) => {

    const delta_inventory_quantity = delta * flagToResolve.quantity_conversion;

    const bound = flagToResolve.quantity_conversion * funcFactorQuantityConversion(flagToResolve.quantity_conversion)
    
    return (-bound <=  delta_inventory_quantity  && delta_inventory_quantity <= bound)
  };

  const handleCountedQuantityChange = (e) => {
    const counted_input = parseFloat(e.target.value);
    setCountedQuantity(counted_input);

    const counted = isNaN(counted_input) || counted_input === null ? 0 : parseFloat(counted_input);
    
    let delta = parseFloat(counted - flagToResolve.inventory_quantity / flagToResolve.quantity_conversion + flagToResolve.total_delta).toFixed(1);
  
    if (checkDeltaInterval(delta)){
      delta = 0;
      setCountedQuantityMatch(flagToResolve.inventory_quantity / flagToResolve.quantity_conversion - flagToResolve.total_delta);
    }

    setDeltaBeforeProcess(parseFloat(delta));
  };

  const getUpdatedDeltaQuantity = () => {
    const delta = isNaN(deltaBeforeProcess) ? flagToResolve.total_delta : parseFloat(deltaBeforeProcess);
    const orderDeliveredCheck = isNaN(orderDeliveredCheckNumber) ? 0 : parseFloat(orderDeliveredCheckNumber);
    const moveProduct = isNaN(moveProductNumber) ? 0 : parseFloat(moveProductNumber);
    const freeProduct = isNaN(freeProductNumber) ? 0 : parseFloat(freeProductNumber);
    const brokenExpired = isNaN(brokenExpiredNumber) ? 0 : parseFloat(brokenExpiredNumber);
    const gaveProduct = isNaN(gaveProductNumber) ? 0 : parseFloat(gaveProductNumber);
    const externalOrder = isNaN(externalOrderNumber) ? 0 : parseFloat(externalOrderNumber);
    
    let delta_final = parseFloat(parseFloat(delta + orderDeliveredCheck + moveProduct - freeProduct + brokenExpired + gaveProduct - externalOrder).toFixed(1));
    
    if (checkDeltaInterval(delta_final)){
      delta_final = 0;
    }

    return delta_final

  };
  

  const getUpdatedMessageReason = () => {
    return countMessage + orderDeliveredCheckMessage + moveProductMessage + freeProductMessage + brokenExpiredMessage + gaveProductMessage + externalOrderMessage + '|';
  };


  const handleSubmitResolvedQuantity = async () => {
    const old_inventory_quantity = flagToResolve.inventory_quantity;
    const new_inventory_quantity = countedQuantityMatch === null ? Math.round(countedQuantity * flagToResolve.quantity_conversion) : Math.round(countedQuantityMatch * flagToResolve.quantity_conversion);
    const product_id = flagToResolve.product_id;
    const location_name = locationName;
    const origin = "Spotcheck";

    // Edit inventory quantity
    await fetch(`${call}/edit-quantity/${product_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newQuantity: new_inventory_quantity, locationName }),
    });

    // Insert tracking data
    await fetch(`${call}/insert-tracking-data/${product_id}`, {
      method: 'PUT',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location_name, username, product_id, old_inventory_quantity, new_inventory_quantity, origin  }),
    });
    
    // Update spotcheck date
    await updateSpotcheckDate(product_id, location_name);

    await fetchFlagsToResolve();
  };

  const [finalDone, setFinalDone] = useState(false);
  const [countDone, setCountDone] = useState(false);
  const [freeProductDone, setFreeProductDone] = useState(false);
  const [orderDeliveredCheckDone, setOrderDeliveredCheckDone] = useState(false);
  const [gaveProductDone, setGaveProductDone] = useState(false);
  const [externalOrderDone, setExternalOrderDone] = useState(false);
  const [brokenExpiredDone, setBrokenExpiredDone] = useState(false);
  const [moveProductDone, setMoveProductDone] = useState(false);


  const [freeProductNumber, setFreeProductNumber] = useState(0);
  const [orderDeliveredCheckNumber, setOrderDeliveredCheckNumber] = useState(0);
  const [gaveProductNumber, setGaveProductNumber] = useState(0);
  const [externalOrderNumber, setExternalOrderNumber] = useState(0);
  const [brokenExpiredNumber, setBrokenExpiredNumber] = useState(0);
  const [moveProductNumber, setMoveProductNumber] = useState(0);

  const [countMessage, setCountMessage] = useState('');
  const [freeProductMessage, setFreeProductMessage] = useState('');
  const [orderDeliveredCheckMessage, setOrderDeliveredCheckMessage] = useState('');
  const [gaveProductMessage, setGaveProductMessage] = useState('');
  const [externalOrderMessage, setExternalOrderMessage] = useState('');
  const [brokenExpiredMessage, setBrokenExpiredMessage] = useState('');
  const [moveProductMessage, setMoveProductMessage] = useState('');

  const issues = [
    { 'problem' : 'Orders not delivered', 'quantity' : orderDeliveredCheckNumber },
    { 'problem' : 'Untracked moved products' , 'quantity' : moveProductNumber }, 
    { 'problem' : 'Untracked free products' , 'quantity' : freeProductNumber }, 
    { 'problem' : 'Untracked broken or expired produtcs', 'quantity' : brokenExpiredNumber },
    { 'problem' : 'Untracked external orders', 'quantity' : externalOrderNumber }
    // { 'problem' : 'Untracked given away products', 'quantity' : gaveProductNumber }, 
  ];

  const steps = [
    { 'number' : 1, 'title' : "Recount" }, 
    { 'number' : 2, 'title' : "Undelivered Orders" }, 
    { 'number' : 3, 'title' : "Moved Products" }, 
    { 'number' : 4, 'title' : "Free Products" }, 
    { 'number' : 5, 'title' : "Broken or Expired" }, 
    { 'number' : 6, 'title' : "External Orders" },
    { 'number' : 7, 'title' : "Submit" }
  ];

  const [currentStep, setCurrentStep] = useState(1);
    
  const totalSteps = steps.length;

  const handleNextStep = () => {
    checkAndResetVariables();
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    checkAndResetVariables();
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const resetAll = () => {
    setFinalDone(false);
    setCountDone(false);
    setFreeProductDone(false);
    setOrderDeliveredCheckDone(false);
    setGaveProductDone(false);
    setExternalOrderDone(false);
    setBrokenExpiredDone(false);
    setMoveProductDone(false);
    setFreeProductNumber(0);
    setOrderDeliveredCheckNumber(0);
    setGaveProductNumber(0);
    setExternalOrderNumber(0);
    setBrokenExpiredNumber(0);
    setMoveProductNumber(0);
    setCountMessage('');
    setFreeProductMessage('');
    setOrderDeliveredCheckMessage('');
    setGaveProductMessage('');
    setExternalOrderMessage('');
    setBrokenExpiredMessage('');
    setMoveProductMessage('');
    setFlagToResolve([]);
    setCountedQuantity(0);
    setCountedQuantityMatch(null);
    setOrdersToCheck([]);
    setFreeHistoryNumber(0);
    setMoveHistoryNumber(0);
    setFinalResolutionStatus('Resolved');
    setDeltaAfterCorrection(0);
    setCurrentStep(1);
    setSelectedOrders([]);
  };

  const allDone = () => {
    setFinalDone(true);
    setCountDone(true);
    setFreeProductDone(true);
    setOrderDeliveredCheckDone(true);
    setGaveProductDone(true);
    setExternalOrderDone(true);
    setBrokenExpiredDone(true);
    setMoveProductDone(true);
  };

  const [ordersToCheck, setOrdersToCheck] = useState([]);

  const fetchOrdersToCheck = async (flag) => {

    await fetch(`${call}/get-recent-delivered-orders`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ location_name: locationName, product_id: flag.product_id }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setOrdersToCheck(data);
      })
      .catch(error => {
        console.error('Error fetching orders to check:', error);
        // Handle error if needed
      });
  };

  const checkAndResetVariables = () => {
    if (moveProductNumber === null || isNaN(moveProductNumber)) {
      setMoveProductNumber(0);
    }
    if (freeProductNumber === null || isNaN(freeProductNumber)) {
      setFreeProductNumber(0);
    }
    if (orderDeliveredCheckNumber === null || isNaN(orderDeliveredCheckNumber)) {
      setOrderDeliveredCheckNumber(0);
    }
    if (gaveProductNumber === null || isNaN(gaveProductNumber)) {
      setGaveProductNumber(0);
    }
    if (externalOrderNumber === null || isNaN(externalOrderNumber)) {
      setExternalOrderNumber(0);
    }
    if (brokenExpiredNumber === null || isNaN(brokenExpiredNumber)) {
      setBrokenExpiredNumber(0);
    }
    if (countedQuantity === null || isNaN(countedQuantity)) {
      setCountedQuantity(0);
      setCountedQuantityMatch(null);
    }
  };
  

  const [finalResolutionStatus, setFinalResolutionStatus] = useState('Resolved');

  const [deltaAfterCorrection, setDeltaAfterCorrection] = useState(0);
  const [deltaBeforeProcess, setDeltaBeforeProcess] = useState(0);

  const [selectedOrders, setSelectedOrders] = useState([]);

  // Function to handle order checkbox change
  const handleOrderCheckboxChange = async (order) => {
    let ordersSelected;
    if (selectedOrders.includes(order)) {
      setSelectedOrders(selectedOrders.filter(o => o !== order));
      ordersSelected = selectedOrders.filter(o => o !== order);
    } else {
      setSelectedOrders([...selectedOrders, order]);
      ordersSelected = [...selectedOrders, order];
    }

    console.log("ordersSelected");

    console.log(ordersSelected);

    const orderQuantitySum = ordersSelected.reduce((acc, order_selected) => acc + order_selected.order_quantity, 0);
    setOrderDeliveredCheckNumber(orderQuantitySum);
  };

  const ProgressBar = () => {
  
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '0 10px',
                }}
              >
                <div
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: index < currentStep ? '#F49C7D' : '#e0e0e0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: index < currentStep ? '#fff' : '#000',
                    fontWeight: 'bold',
                  }}
                >
                  {index + 1}
                </div>

              </div>
              {index < totalSteps - 1 && (
                <div style={{ width: '60px', height: '4px', backgroundColor: index < currentStep - 1 ? '#F49C7D' : '#e0e0e0', marginLeft: '-10px', marginRight: '-10px' }}></div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'top' }}>
          {steps.map((step, index) => (
            <p style={{ width: '70px', margin: '10px', fontSize: '12px', fontWeight: currentStep === index + 1 ? 'bold' : 'normal' }}>
              {step.title}
            </p>
          ))}
        </div>
      </div>
    );
  };

  const getDeltaStatus = () => {
    const delta = getUpdatedDeltaQuantity();

    if (delta > 0) {
      return {
        textDeltaStatus: `${delta} ${flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} gained`,
        colorDeltaStatus: '#e9453b',
      };
    } else if (delta < 0) {
      return {
        textDeltaStatus: `${Math.abs(delta)} ${flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} lost`,
        colorDeltaStatus: '#e9453b',
      };
    } else {
      return {
        textDeltaStatus: 'Resolved',
        colorDeltaStatus: '#119b1a',
      };
    }
  };
  
  const { textDeltaStatus, colorDeltaStatus } = getDeltaStatus();
    

   // Function to submit the order from the popup
  const submitOrder = async(orderQuantity, orderUrgency, orderComment) => {
    if (orderQuantity <= 0) {
      toast.error('Please input an order quantity.')
      return
    }
    toast.success("Order accepted by system for processing");
    
    console.log('Submitting order:', {
      product: selectedProduct.title,
      quantity: orderQuantity,
      actual: selectedProduct.quantity
    });

    const userEmail = localStorage.getItem('userEmail');
    let username = '';
    

    if (userEmail) {
      try {
          const response = await fetch(`${call}/get-username?user_email=${userEmail}`);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const data = await response.json();
          username = data.username;
      } catch (error) {
          console.error('Error fetching username:', error);
          return; // Exit the function if there is an error
      }
  }


  if(origin === 'inventory'){

    if(Number.isInteger(selectedProduct.orderId)){
    const response =  fetch(`${call}/accept-order-from-inventory/${selectedProduct.orderId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({orderQuantity: orderQuantity, locationName, orderComment, username , orderUrgency, manufacturer_name: selectedProduct.manufacturer_name, permissionCode}),
    });

  }}
  if(origin === 'web'){
  
    console.log("trying to place an order throuh web search");
    console.log(queryResult)
    const response =  fetch(`${call}/accept-order-from-web-search/${queryResult}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({orderQuantity: orderQuantity, orderComment, locationName, title: queryResult.title, price: queryResult.extracted_price, username, orderUrgency, permissionCode }),
    });
  }

  if(origin === 'recommend'){

    
  
    try {
        const response = await fetch(`${call}/accept-order/${selectedProduct.orderId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
          body: JSON.stringify({username, orderQuantity: orderQuantity, old_order_quantity: selectedProduct.old_order_quantity, orderComment, username, orderUrgency, product_id: selectedProduct.product_id, manufacturer_name: selectedProduct.manufacturer_name, locationName }),
        });

        if (response.ok) {
          console.log(`Order processed successfully`);
          fetchOrderRecData();
        } else {
          console.error('Error accepting/declining order:', response.statusText);
        }
        
    } catch (error) {
        console.error('Error in sending order request:', error);
    }


  }

    // Close the order modal
    setOrderModalIsOpen(false);
    
    // Reset order details
    setSelectedProduct(null);
    setOrigin('');
  };

  const updateSpotcheckDate = async (productId, locationName) => {
    try {
      const response = await fetch(`${call}/update-spotcheck-date`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId, locationName }), // Include productId and locationName in the request body
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || 'Failed to update spotcheck status');
      }
  
      console.log('Spotcheck status updated successfully:', data.message);
      // Handle success or show a success message
    } catch (error) {
      console.error('Error updating spotcheck status:', error.message);
      // Handle error or show an error message
    }
  };

  const [flagDetails, setFlagDetails] = useState('');

  const fetchFlagDetails = async (productId, locationName) => {
    try {
        const response = await fetch(`${call}/flag-details`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ product_id: productId, location_name: locationName })
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();

        setFlagDetails(data);

    } catch (error) {
        console.error('Failed to fetch flag details:', error);
    }
  };

  const tableContainerRef = useRef(null);

  function scrollDownTable() {
    setTimeout(() => {
      console.log("Checking and scrolling...");
  
      if (tableContainerRef.current) {
        console.log("Scrolling...");
        tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
      }
    }, 100);
  }

  function calculateFilteredDeltaSumExplained(flagDetails) {
    return flagDetails
      .filter(detail => !detail.origin.startsWith('Spotcheck') && !detail.origin.startsWith('Edit Inventory'))
      .reduce((accumulator, current) => accumulator + parseFloat(current.delta), 0).toFixed(1);
  }

  function calculateFilteredDeltaSumUnexplained(flagDetails) {
    return flagDetails
      .filter(detail => detail.origin.endsWith('Flag'))
      .reduce((accumulator, current) => accumulator + parseFloat(current.delta), 0).toFixed(1);
  }

  async function fetchProductUsage(location_name, product_id) {

    const data = {
        location_name: location_name,
        product_id: product_id
    };

    try {
        const response = await fetch(`${call}/product-usage-per-week`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const usageData = await response.json();

        setProductUsageData(usageData);
        
    } catch (error) {
        console.error('Failed to fetch product usage data:', error);
    }
  }

  return (

    <div ref={contentRef}>
      <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      
        <h2 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#F49C7D',}}>Order Recommendations</h2>

        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <div style={{textAlign: 'center', marginRight: '50px'}}>
            <div className="progress-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div className="progress-bar">
                <div className="progress-processed" style={{ width: `${processedPercent}%`, backgroundColor: barColor }}></div>
                <div className="progress-unprocessed" style={{ width: `${unprocessedPercent}%`, backgroundColor: barColor }}></div>
              </div>
              <span className="progress-text">{remainingText}</span>

              {isTooltipVisible && (
                <div className="tooltip">
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: remainingBudget <= 0 ? '#e9453b' : '#f49c7d',
                        marginRight: '5px'
                      }}
                    ></div>
                    <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                      Processed: ${parseInt((processedOrders ? processedOrders : 0)).toLocaleString()}
                    </p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: remainingBudget <= 0 ? '#e9453b' : '#fad5c0',
                        marginRight: '5px'
                      }}
                    ></div>
                    <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                      Pending: ${parseInt((unprocessedOrders ? unprocessedOrders : 0)).toLocaleString()}
                    </p>
                  </div>
                  {remainingBudget > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                      <div style={{ width: '10px', height: '10px', backgroundColor: '#e0e0e0', marginRight: '5px' }}></div>
                      <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                        Remaining: ${parseInt((remainingBudget ? remainingBudget : 0)).toLocaleString()}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <span className="progress-text" style={{ marginTop: '5px', display: 'block', marginLeft: '150px' }}>{`Monthly Budget: $${Number(parseFloat(budget).toFixed(2)).toLocaleString()}`}</span>
          </div>

        
          {/* Notifications bell */}
          <div>
            <button className="support-btn" onClick={toggleNotificationModal} style={{ backgroundColor: '#F49C7D', marginTop: '-2px' }}>
              <img src={notifications ? bell_notification : bell} alt="notifications" style={{ width: '30px', height: '30px' }} />
            </button>
          </div>
          {/* Notifications modal */}
          <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={notificationModalIsOpen}
            onRequestClose={() => {
              // Call toggleNotificationModal only when the modal is closed
              if (notificationModalIsOpen) {
                toggleNotificationModal();
              }
            }}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            <h2 style={{ textAlign: 'left', marginBottom: '10px' }}>Notifications</h2>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '1px solid black', borderRight: '1px solid black', textAlign: 'center', padding: '5px' }}>Date</th>
                    <th style={{ borderBottom: '1px solid black', textAlign: 'center', padding: '5px' }}>Message</th>
                    <th style={{ borderBottom: '1px solid black', textAlign: 'center', padding: '5px', borderLeft: '1px solid black' }}>Mark as Read</th> {/* Added borderLeft */}
                  </tr>
                </thead>
                <tbody>
                  {messages
                    .filter(message => message.read_status === 0 && message.location_name === locationName) // Add locationName filter
                    .map((message, index) => (
                      <tr key={index}>
                        <td style={{ borderBottom: '1px solid black', borderRight: '1px solid black', textAlign: 'center', padding: '5px' }}>{formatDateText(message.date_time)}</td>
                        <td style={{ borderBottom: '1px solid black', textAlign: 'center', padding: '5px' }}>{message.message}</td>
                        <td style={{ borderBottom: '1px solid black', textAlign: 'center', padding: '5px', borderLeft: '1px solid black' }}> {/* Added borderLeft */}
                          <button onClick={() => handleMessage(message)} style={{backgroundColor: 'transparent', marginRight: '10px', border: 'none', cursor: 'pointer' }}> {/* Pass the message to handleMessage function */}
                            <img src={tick} alt="green-tick" style={{ height: '20px', width: '20px' }} />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal>


          <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: '#F49C7D', marginTop: '-2px'}}>
          
            <h1 style={{ fontSize: '20px' }}>?</h1>
          </button>
          
          {showContact && (
            <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
          )}

        </div> 
      </div> 

      <div style={{ marginTop: '-10px' , display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '100%' }}>
        
        <div style={{ 
          border: '2px solid #F49C7D', 
          display: 'flex', 
          justifyContent: 'left', 
          flexDirection: 'column', 
          width: '100%', 
          maxHeight: '310px', // Set a fixed height
          minHeight: '310px', // Set a fixed height
          overflowY: 'auto', // Enable vertical scrolling
          boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>

          <table style={{  
            backgroundColor: '#fffff', 
            width: '100%', 
            borderCollapse: 'separate', 
            borderSpacing: 0,       
            position: 'relative' // Ensure positioning context
          }}>
            <thead style={{
              position: 'sticky', // Stick the header
              top: 0, // Stick to the top of the table
              backgroundColor: '#ffffff', // Background to cover underlying rows
            
            }}>
              <tr style={{ fontSize: '15px', backgroundColor: '#fffff', color: '#00000', }}>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '300px',textAlign: 'left' }}>Product Name</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '300px',textAlign: 'left' }}>Description</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '100px',textAlign: 'left' }}>Manufacturer</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '40px' ,textAlign: 'left' }}>Price</th>
                <th style={{  borderBottom:'2px solid black', padding: '5px', width: '40px',textAlign: 'center' }}>Quantity</th>
                <th style={{  borderBottom:'2px solid black', padding: '5px', width: '50px',textAlign: 'left' }}>Total</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '140px',textAlign: 'left'}}>Order Reason</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '140px',textAlign: 'center'}}>Details</th>
                <th style={{ borderBottom:'2px solid black', padding: '5px', width: '140px',textAlign: 'left' }}>
                    Actions
                </th>
              </tr>

            </thead>

            <tbody>
            {awaitingOrders.length === 0 ? (
                      <tr>
                          <td colSpan="9" style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '16px',fontStyle:'italic', padding:"1px", padding: '50px', height: '160px'  }}>
                              Order Recommendations Up-to-Date
                          </td>
                      </tr>
                  ) : (
                      awaitingOrders.map((item, index) => (
                        <tr key={item.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff' }} >
                          <td className='table-row' style={{  padding: '5px',  }}>{item.product_name}</td>
                          <td className='table-row' style={{  padding: '5px', }}>{item.product_description}</td>
                          <td className='table-row' style={{ padding: '5px', }}>{item.manufacturer_name}</td>
                          <td className='table-row' style={{  padding: '5px',  }}>{item.unit_price === 0 || item.unit_price === null || item.product_cat === 'web' ? 'TBD' : `$${parseFloat(item.unit_price).toFixed(2)}` }</td>
                          <td className='table-row' style={{  padding: '5px', textAlign: 'center' }}>{item.order_quantity}</td>

                          <td className='table-row' style={{ padding: '5px' }}>{item.unit_price === 0 || item.unit_price === null || item.product_cat === 'web' ? 'TBD' : `$${parseFloat(item.unit_price * item.order_quantity).toFixed(2)}`}</td>

                          <td className='table-row' style={{  padding: '5px',  }}>{item.recommendation_rationale}</td>
                          <td className='table-row' style={{  padding: '5px', textAlign: 'center' }}>
                            {item.recommendation_rationale !== 'Staff Request' &&
                              <button className='action-button-accent' onClick={async () => {setRecommendationDetails(item); await fetchProductUsage(locationName, item.product_id); await fetchRecommendationsPredictions(locationName, item.product_id); toggleRecommendationsDetailsModal();}} >
                                Details
                              </button>
                            }
                          </td>
                          <td className='table-row' style={{ padding: '5px'}}>
                            {permissionCode === 22 ? (
                              <>
                                {(item.unit_price * item.order_quantity) < orderThreshold ? (
                                  <button onClick={() => handleOrder(item.product_id, item.product_name, item.order_id, item.order_quantity, 'recommend', item.manufacturer_name, item.client_comment, item.order_urgency, item)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', marginRight: '10px', border: 'none', cursor: 'pointer' }}>
                                    <img src={tick} alt="green-tick" style={{ height: '20px', width: '20px' }} />
                                  </button>
                                ) : (
                                  <span style={{ display: 'inline-block', width: '42px' }}> {/* Adjust the width to match the button size */} </span>
                                )}
                                <button onClick={() => handleAcceptDecline(item.order_id, item.order_quantity, false)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', marginRight: '10px', border: 'none', cursor: 'pointer' }}>
                                  <img src={cross} alt="red-cross" style={{ height: '20px', width: '20px', marginLeft: '12px' }} />
                                </button>
                              </>
                            ) : (
                              <>
                                <button onClick={() => handleOrder(item.product_id, item.product_name, item.order_id, item.order_quantity, 'recommend', item.manufacturer_name, item.client_comment, item.order_urgency, item)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', marginRight: '10px', border: 'none', cursor: 'pointer' }}>
                                  <img src={tick} alt="green-tick" style={{ height: '20px', width: '20px' }} />
                                </button>
                                <button onClick={() => handleAcceptDecline(item.order_id, item.order_quantity, false)} style={{ backgroundColor: index % 2 === 0 ? '#fff2eb' : '#ffffff', marginRight: '10px', border: 'none', cursor: 'pointer' }}>
                                  <img src={cross} alt="red-cross" style={{ height: '20px', width: '20px' }} />
                                </button>
                              </>
                            )}
                          </td>

                        </tr>
                      ))
                    )}
            </tbody>
          </table>
        </div>

        <Modal overlayClassName="white-modal-overlay"
          className="white-modal"
          isOpen={declineModalIsOpen}
          onRequestClose={() => setDeclineModalIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
                width: '25%',
            }
          }}>
          <ModalHeader title={'Decline Order'} />
          <div style={{marginRight: '20px', marginLeft: '20px'}}>
            <h3 style={{margin: 0, marginTop: '10px'}}>Reason for Decline:</h3>
            <select className='select-box' style={{marginTop: '8px', marginBottom: '8px', maxWidth:'90%'}}
              value={declineReason} onChange={(e) => setDeclineReason(e.target.value)}>
              <option value="">Select a reason</option>
              <option value="Sufficient Stock Available">Sufficient Stock Available</option>
              <option value="Intention to Discontinue Product Use">Intention to Discontinue Product Use</option>
              <option value="Issue with Price">Issue with Price</option>
              <option value="Want a different Product/Manufacturer">Want a different Product/Manufacturer</option>
              <option value="Already Ordered myself with Medvelle">Already Ordered myself with Medvelle</option>
              <option value="Already Ordered myself externallye">Already Ordered myself externally</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <button className='action-button' style={{marginLeft: '20px', marginBottom:'20px'}} onClick={() => submitDecline()}>Submit</button>
          </div>
        </Modal>

        <OrderModal isOpen={orderModalIsOpen} setIsOpen={setOrderModalIsOpen} product={selectedProduct?.item} submitOrder={submitOrder} quantity={selectedProduct?.quantity} permissionCode={permissionCode}/>
      </div>
      

      <div>        
        <h2 style={{ fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>Flagging Resolution</h2>
        <div style={{ 
          border: '2px solid #87D3D7', 
          display: 'flex', 
          justifyContent: 'left', 
          flexDirection: 'column', 
          width: '100%', 
          maxHeight: '310px', // Set a fixed height
          overflowY: 'auto', // Enable vertical scrolling
          boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
        }}>

          <table style={{  
            width: '100%', 
            borderCollapse: 'separate', 
            borderSpacing: 0, 
            position: 'relative' // Ensure positioning context
          }}>
            <thead style={{
              position: 'sticky', // Stick the header
              top: 0, // Stick to the top of the table
            }}>
              <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Product Name</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Description</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Manufacturer</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Total Change</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Details</th>
                <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center'}}>Resolve</th>
              </tr>
            </thead>
            
            <tbody>
              {flagsToResolve.length === 0 ?
              ( <tr>
                  <td colSpan="6" style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '16px',fontStyle:'italic', padding:"1px", padding: '50px', height: '160px'  }}>
                      Flagging Resolution Up-to-Date
                  </td>
                </tr>
              )
              :
              ( <>
                  {flagsToResolve.map((flag, index) => (
                    <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{flag.product_name}</td>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{flag.product_description}</td>
                      <td style={{ padding: '10px', textAlign: 'left' }}>{flag.manufacturer_name}</td>
                      <td style={{ padding: '10px', textAlign: 'left', width: '6%' }}>
                        {flag.total_delta >= 0 ? (
                          <span style={{ float: 'left', color: 'rgba(93, 158, 82, 1)' }}>
                            <FontAwesomeIcon icon={faLongArrowAltUp} />
                          </span>
                        ) : (
                          <span style={{ float: 'left', color: 'rgba(207, 100, 84, 1)' }}>
                            <FontAwesomeIcon icon={faLongArrowAltDown} />
                          </span>
                        )}
                        <span style={{ float: 'right' }}>{Math.abs(flag.total_delta).toFixed(1)}</span>
                      </td>
                      <td style={{ padding: '10px', textAlign: 'center' }}>
                        <button className='action-button' onClick={async () => {setFlagToResolve(flag); await fetchFlagDetails(flag.product_id, locationName); setFlagToResolve(flag); toggleFlagsDetailsModal(); scrollDownTable();}}>Details</button>
                      </td>
                      <td style={{ padding: '10px', textAlign: 'center' }}>
                        <button className='action-button' onClick={async () => {toggleFlagsModal(); setFlagToResolve(flag); setDeltaBeforeProcess(flag.total_delta); setCountedQuantity(flag.quantity_conversion !== 0 ? parseFloat((flag.inventory_quantity / flag.quantity_conversion).toFixed(1)) : flag.inventory_quantity); await fetchOrdersToCheck(flag); await getFreeProductsNumber(flag); await getMoveProductsNumber(flag); }} >Resolve</button>
                      </td>
                    </tr>
                  ))}
                </>
              )
              }
            </tbody>
          </table>
        </div>
      </div>
      <br/>

      <ToastContainer />
      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={FlagsModalIsOpen} // Change to your FlagsModalIsOpen state
        onRequestClose={() => {
          toggleFlagsModal(); resetAll();
        }}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
              width: '50%',
              height: '70%',
              textAlign: 'center'
          }
        }}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Resolve Flag for {flagToResolve.product_name}</h2>
        <br/>

        {!finalDone &&
          <>
            <ProgressBar />
            <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>Status</h3>
            <div style={{ width: '20%', backgroundColor: colorDeltaStatus, padding: '5px', borderRadius: '5px', color: 'white', display: 'inline-block' }}>
              {textDeltaStatus}
            </div>
            <br/>
          </>
        }

        {!countDone && 
          <>
            <div className="flag-resolution-centered">
              <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} of {flagToResolve.product_name} do you have?</p>
              <div style={{ 
                border: '2px solid #87D3D7', 
                display: 'flex', 
                justifyContent: 'left', 
                flexDirection: 'column', 
                width: '100%', 
                maxHeight: '110px', // Set a fixed height
                overflowY: 'auto', // Enable vertical scrolling
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
              }}>
                <table style={{  
                  width: '100%', 
                  borderCollapse: 'separate', 
                  borderSpacing: 0, 
                  position: 'relative' // Ensure positioning context
                }}>
                  <thead style={{
                    position: 'sticky', // Stick the header
                    top: 0, // Stick to the top of the table
                  }}>
                    <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                      <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Current Quantity</th>
                      <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Expected Quantity</th>
                      <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Counted Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr style={{ fontSize: '13px', backgroundColor: '#ebf4f5' }}>
                        <td style={{ padding: '10px', textAlign: 'left' }}>{flagToResolve.quantity_conversion !== 0 ? parseFloat(flagToResolve.inventory_quantity / flagToResolve.quantity_conversion).toFixed(1) : flagToResolve.inventory_quantity} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</td>
                        <td style={{ padding: '10px', textAlign: 'left' }}>{flagToResolve.quantity_conversion !== 0 ? parseFloat((flagToResolve.inventory_quantity / flagToResolve.quantity_conversion - flagToResolve.total_delta)).toFixed(1) : (flagToResolve.inventory_quantity - flagToResolve.total_delta)} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</td>
                        <td style={{ padding: '10px', textAlign: 'left' }}>
                          <input min="0" style={{width:'40%', marginRight: '5px'}} type="number" value={countedQuantity} onChange={handleCountedQuantityChange} />
                          {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button onClick={async () => {
              
              if (deltaBeforeProcess != flagToResolve.total_delta){
                setCountMessage('|  Miscount  ');
              }
              if (deltaBeforeProcess == 0){
                setCurrentStep(steps.length);
                await submitFlagResolution('|  Miscount  |', deltaBeforeProcess);
                return;
              }
              setCountDone(true); 
              handleNextStep();

              }} 
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}            
            >
              Next
            </button>
          </>
        }

        { !orderDeliveredCheckDone && countDone &&
          <>
          { ordersToCheck.length !== 0 ?
            (
            <>
              <div className="flag-resolution-centered">
                <p>Check the orders that haven't been delivered.</p>
                <div style={{ 
                  border: '2px solid #87D3D7', 
                  display: 'flex', 
                  justifyContent: 'left', 
                  flexDirection: 'column', 
                  width: '100%', 
                  maxHeight: '160px', // Set a fixed height
                  overflowY: 'auto', // Enable vertical scrolling
                  boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                }}>
        
                  <table style={{  
                    width: '100%', 
                    borderCollapse: 'separate', 
                    borderSpacing: 0, 
                    position: 'relative' // Ensure positioning context
                  }}>
                    <thead style={{
                      position: 'sticky', // Stick the header
                      top: 0, // Stick to the top of the table
                    }}>
                      <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Order Date</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Product Name</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Order Quantity</th>
                        <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Not Delivered?</th>
                      </tr>
                    </thead>
                    
                    <tbody>
                      {ordersToCheck.map((order, index) => (
                        <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                          <td style={{ padding: '10px', textAlign: 'left' }}>{formatDateText(order.order_date)}</td>
                          <td style={{ padding: '10px', textAlign: 'left' }}>{flagToResolve.product_name}</td>
                          <td style={{ padding: '10px', textAlign: 'left' }}>{order.order_quantity}</td>
                          <td style={{ padding: '10px', textAlign: 'left' }}>
                            <input
                              type="checkbox"
                              checked={selectedOrders.includes(order)}
                              onChange={async () => {
                                await handleOrderCheckboxChange(order);
                              }}
                              style={{ width: '20px', height: '20px', alignItems: 'center' }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <button onClick={async () => {
                setCountDone(false);
                handlePreviousStep();
                setCountMessage('');
                }}
                style={{
                  border: '3px solid #31bac1', 
                  backgroundColor: '#31bac1', 
                  padding: '5px',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  margin: '20px'
                }}
              >
                Back
              </button>
              <button onClick={async () => {
                
                if (orderDeliveredCheckNumber != 0 && !isNaN(orderDeliveredCheckNumber) && orderDeliveredCheckNumber !== null){
                  setOrderDeliveredCheckMessage('|  Order IDs not delivered: ' + selectedOrders.map(order => order.order_id).join(',') + '  ');
                }
                setOrderDeliveredCheckDone(true);
                handleNextStep();

                }}
                
                style={{
                  border: '3px solid #31bac1', 
                  backgroundColor: '#31bac1', 
                  padding: '5px',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  margin: '20px'
                }}
              >
                Next
              </button>
            </>
            )
            :
            (
              <>
                <div className="flag-resolution-centered">
                  <p>No recent delivered orders found. Go to next step.</p>
                </div>
                <button onClick={async () => {
                  setCountDone(false);
                  handlePreviousStep();
                  setCountMessage('');
                  }}
                  style={{
                    border: '3px solid #31bac1', 
                    backgroundColor: '#31bac1', 
                    padding: '5px',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    margin: '20px'
                  }}
                >
                  Back
                </button>
                <button onClick={() => {
                  setOrderDeliveredCheckDone(true); 
                  handleNextStep();
                  }}

                  style={{
                    border: '3px solid #31bac1', 
                    backgroundColor: '#31bac1', 
                    padding: '5px',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    margin: '20px'
                  }}
                >
                  Next
                </button>
              </>
            )
          }
          </>
        }

        { !moveProductDone && orderDeliveredCheckDone &&
          <>
            <div className="flag-resolution-centered">
              <p>We tracked {moveHistoryNumber} moved {flagToResolve.product_name} since {formatDateText(flagToResolve.last_date_check)}. How many additional {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} of {flagToResolve.product_name} did you move?</p>
              <input min="0" style={{width:'20%', marginRight: '5px'}} type="number" value={moveProductNumber} onChange={(e) => setMoveProductNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}
            </div>
            <button onClick={async () => {
              setOrderDeliveredCheckDone(false);
              handlePreviousStep();
              setOrderDeliveredCheckMessage('');
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: '20px'
              }}
            >
              Back
            </button>
            <button onClick={async () => {

              if (moveProductNumber != 0 && !isNaN(moveProductNumber) && moveProductNumber !== null){
                setMoveProductMessage('|  Moved Product(s)  ');
              }
              setMoveProductDone(true);
              handleNextStep();

              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}    
            >
              Next
            </button>
          </>
        }

        { !freeProductDone && moveProductDone &&
          <>
            <div className="flag-resolution-centered">
              <p>We tracked {freeHistoryNumber} free promotional {flagToResolve.product_name} since {formatDateText(flagToResolve.last_date_check)}. How many additional {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} of free {flagToResolve.product_name} did you receive?</p>
              <input min="0" style={{width:'20%', marginRight: '5px'}} type="number" value={freeProductNumber} onChange={(e) => setFreeProductNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}
            </div>
            <button onClick={async () => {
              setMoveProductDone(false);
              handlePreviousStep();
              setMoveProductMessage('');
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: '20px'
              }}
            >
              Back
            </button>
            <button onClick={async () => {

              if (freeProductNumber != 0 && !isNaN(freeProductNumber) && freeProductNumber !== null){
                setFreeProductMessage('|  Free Product(s)  ');
              }
              setFreeProductDone(true);
              handleNextStep();

              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}    
            >
              Next
            </button>
          </>
        }

        { !brokenExpiredDone && freeProductDone &&
          <>
            <div className="flag-resolution-centered">
              <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} of {flagToResolve.product_name} have expired or broken since {formatDateText(flagToResolve.last_date_check)}?</p>
              <input min="0" style={{width:'20%', marginRight: '5px'}} type="number" value={brokenExpiredNumber} onChange={(e) => setBrokenExpiredNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}
            </div>
            <button onClick={async () => {
              setFreeProductDone(false);
              handlePreviousStep();
              setFreeProductMessage('');
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: '20px'
              }}
            >
              Back
            </button>
            <button onClick={() => {

              if (brokenExpiredNumber != 0 && !isNaN(brokenExpiredNumber) && brokenExpiredNumber !== null){
                setBrokenExpiredMessage('|  Broken/Expired Product(s)  ');
              }
              setBrokenExpiredDone(true);
              handleNextStep();

              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}
            >
              Next
            </button>
          </>
        }

        { !externalOrderDone && brokenExpiredDone &&
          <>
            <div className="flag-resolution-centered">
              <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} of {flagToResolve.product_name} did you order without using Medvelle?</p>
              <input min="0" style={{width:'20%', marginRight: '5px'}} type="number" value={externalOrderNumber} onChange={(e) => setExternalOrderNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}
            </div>
            <button onClick={async () => {
              setBrokenExpiredDone(false);
              handlePreviousStep();
              setBrokenExpiredMessage('');
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: '20px'
              }}
            >
              Back
            </button>
            <button onClick={async () => {

              if (externalOrderNumber != 0 && !isNaN(externalOrderNumber) && externalOrderNumber !== null){
                setExternalOrderMessage('|  External Order(s)  ');
              }
              setExternalOrderDone(true);
              handleNextStep();

              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}
            >
              Next
            </button>
          </>
        }

        {/* Gave Away commented for now */}
        {/* { !gaveProductDone && orderDeliveredCheckDone &&
          <>
            <div className="flag-resolution-centered">
              <p>How many {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`} have you given away?</p>
              <input min="0" style={{width:'20%', marginRight: '5px'}} type="number" value={gaveProductNumber} onChange={(e) => setGaveProductNumber(parseFloat(e.target.value))} /> {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}
            </div>
            <button onClick={async () => {
              setOrderDeliveredCheckDone(false);
              handlePreviousStep();
              setOrderDeliveredCheckMessage('');
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: '20px'
              }}
            >
              Back
            </button>
            <button onClick={async () => {
                
              if (gaveProductNumber != 0){
                setGaveProductMessage('|  Gave Away Product(s)  ');
              }
              setGaveProductDone(true);
              handleNextStep();
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}
            >
              Next
            </button>
          </>
        } */}


        { !finalDone && externalOrderDone &&
          <>
            <div className="flag-resolution-centered">
              <p>Do you want to submit the information below?</p>
              <div style={{ 
                border: '2px solid #87D3D7', 
                display: 'flex', 
                justifyContent: 'left', 
                flexDirection: 'column', 
                width: '100%', 
                maxHeight: '220px', // Set a fixed height
                overflowY: 'auto', // Enable vertical scrolling
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
              }}>
      
                <table style={{  
                  width: '100%', 
                  borderCollapse: 'separate', 
                  borderSpacing: 0, 
                  position: 'relative' // Ensure positioning context
                }}>
                  <thead style={{
                    position: 'sticky', // Stick the header
                    top: 0, // Stick to the top of the table
                  }}>
                    <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                      <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Problem</th>
                      <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'left'}}>Quantity - {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    {issues.map((issue, index) => (
                      <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }}>
                        <td style={{ padding: '10px', textAlign: 'left' }}>{issue.problem}</td>
                        <td style={{ padding: '10px', textAlign: 'left' }}>{issue.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <button onClick={async () => {
              setExternalOrderDone(false);
              handlePreviousStep();
              setExternalOrderMessage('');
              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                margin: '20px'
              }}
            >
              Back
            </button>
            <button onClick={async () => {

              const deltaAfterCorrection = getUpdatedDeltaQuantity();
              setDeltaAfterCorrection(deltaAfterCorrection);

              const explanationMessage = getUpdatedMessageReason();

              if (deltaAfterCorrection == 0){
                await submitFlagResolution(explanationMessage, deltaAfterCorrection); 
              }
              else if (deltaAfterCorrection < 0) {
                setFinalResolutionStatus('Unresolved Lost');
                await submitFlagResolution(explanationMessage + '  Lost  |', deltaAfterCorrection);
              } else if (deltaAfterCorrection > 0) {
                setFinalResolutionStatus('Unresolved Gain');
                await submitFlagResolution(explanationMessage + '  Gain  |', deltaAfterCorrection);
              }

              handleNextStep();

              }}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                position: 'absolute',
                bottom: 0,
                right: 0,
                margin: '20px'
              }}
            >
              Submit
            </button>
          </>
        }

        { finalDone && 
          <>
            <div className="flag-resolution-result-centered">

              <h4>Resolution Done <img src={tick} alt="green-tick" style={{ height: '20px', width: '20px', marginBottom: '-5px'}} /></h4>
              <ul style={{ textAlign: 'left', listStylePosition: 'inside', paddingInlineStart: '20px', marginTop: '10px' }}>
                <li><b>Result:</b> {finalResolutionStatus}</li>
                <br/>
              {deltaAfterCorrection === 0 ? (
                <li><b>Status:</b> Resolution Fixed!</li>
              ) : (
                <li>
                  <b>Status:</b> {textDeltaStatus}
                </li>
              )}
                <br/>
                {countMessage !== '' || moveProductMessage !== '' || freeProductMessage !== '' || externalOrderMessage !== '' || orderDeliveredCheckMessage !== '' || gaveProductMessage !== '' || brokenExpiredMessage !== '' ? (
                  <li>
                    <b>Details:</b> 
                    <ul style={{ fontSize: '15px', textAlign: 'left', listStylePosition: 'inside', paddingInlineStart: '20px', marginTop: '10px' }}>
                      {countMessage !== '' && (
                        <li>
                          <b>Miscount</b>
                        </li>
                      )}
                      {orderDeliveredCheckMessage !== '' && (
                        <li>
                          <b>Order(s) not delivered (lost package, contact Medvelle) :</b><span style={{float: 'right', marginLeft: '5px'}}> {orderDeliveredCheckNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</span>
                        </li>
                      )}
                      {moveProductMessage !== '' && (
                        <li>
                          <b>Moved product(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {moveProductNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</span>
                        </li>
                      )}
                      {freeProductMessage !== '' && (
                        <li>
                          <b>Free product(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {freeProductNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</span>
                        </li>
                      )}
                      {brokenExpiredMessage !== '' && (
                        <li>
                          <b>Broken / expired product(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {brokenExpiredNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</span>
                        </li>                      
                      )}
                      {externalOrderMessage !== '' && (
                        <li>
                          <b>External order(s) not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {externalOrderNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</span>
                        </li>                      
                      )}
                      {/* {gaveProductMessage !== '' && (
                        <li>
                          <b>Product(s) given away not tracked:</b><span style={{float: 'right', marginLeft: '5px'}}> {gaveProductNumber} {flagToResolve.product_unit === 'box' ? 'box(es)' : `${flagToResolve.product_unit}(s)`}</span>
                        </li>                     
                      )} */}
                    </ul>
                  </li>
                ) : ('')
              }
              </ul>
              <br/>
              <button onClick={() => {toggleFlagsModal(); resetAll();}}
              style={{
                border: '3px solid #31bac1', 
                backgroundColor: '#31bac1', 
                padding: '5px',
                margin: '20px'
              }}
              >
                Finish
              </button>
            </div>
          </>
        }

      </Modal>

      <Modal
        overlayClassName="modal-overlay"
        className="modal-content"
        isOpen={FlagsDetailsModalIsOpen}
        onRequestClose={() => {
          toggleFlagsDetailsModal(); setFlagToResolve([]); setFlagDetails(''); resetAll();
        }}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
              width: '50%',
              height: '75%',
              textAlign: 'center'
          }
        }}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Details for {flagToResolve.product_name} {flagToResolve.product_description ? `- ${flagToResolve.product_description}` : ''}</h2>
        <br/>

        <div 
          style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div 
            style={{ 
              border: '2px solid #31bac1', 
              display: 'flex', 
              justifyContent: 'left', 
              flexDirection: 'column', 
              width: '50%', 
              boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}
          >
            <table style={{  
              backgroundColor: '#fffff', 
              width: '100%', 
              borderCollapse: 'separate', 
              borderSpacing: 0,       
              position: 'relative', // Ensure positioning context
              tableLayout: 'fixed', // Ensure fixed table layout
            }}>
              <tbody>              
                <tr style={{ fontSize: '13px', backgroundColor: 'rgba(245, 251, 240, 1)'}} >
                    <td className='table-row' style={{ padding: '5px', textAlign: 'left', width: '70%' }}><b>Last verified quantity:</b></td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '15%' }}></td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '15%' }}>
                      {flagDetails && flagDetails.find(detail => detail.flag === 0)?.quantity_after.toFixed(1)}
                    </td>                
                </tr>
                <tr style={{ fontSize: '13px', backgroundColor: '#ffffff'}} >
                    <td className='table-row' style={{ padding: '5px', textAlign: 'left', width: '70%' }}><b>Explained change:</b></td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'left', width: '15%' }}>
                      {flagDetails && calculateFilteredDeltaSumExplained(flagDetails) >= 0 ? (
                        <span style={{ float: 'left', color: '#A9A9A9' }}>
                          <FontAwesomeIcon icon={faLongArrowAltUp} />
                        </span>
                      ) : (
                        <span style={{ float: 'left', color: '#A9A9A9' }}>
                          <FontAwesomeIcon icon={faLongArrowAltDown} />
                        </span>
                      )}
                      <span style={{ float: 'right' }}>{flagDetails && Math.abs(calculateFilteredDeltaSumExplained(flagDetails)).toFixed(1)}</span>
                    </td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '15%' }}></td>                
                </tr>
                <tr style={{ fontSize: '13px', backgroundColor: 'rgba(253, 244, 244, 1)'}} >
                    <td className='table-row' style={{ padding: '5px', textAlign: 'left', width: '70%' }}><b>Unexplained change:</b></td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'left', width: '15%' }}>
                      {flagDetails && calculateFilteredDeltaSumUnexplained(flagDetails) >= 0 ? (
                        <span style={{ float: 'left', color: 'rgba(93, 158, 82, 1)' }}>
                          <FontAwesomeIcon icon={faLongArrowAltUp} />
                        </span>
                      ) : (
                        <span style={{ float: 'left', color: 'rgba(207, 100, 84, 1)' }}>
                          <FontAwesomeIcon icon={faLongArrowAltDown} />
                        </span>
                      )}
                      <span style={{ float: 'right' }}>{flagDetails && Math.abs(calculateFilteredDeltaSumUnexplained(flagDetails)).toFixed(1)}</span>
                    </td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '15%' }}></td>                
                </tr>
                <tr style={{ fontSize: '13px', backgroundColor: '#ffffff'}} >
                    <td className='table-row' style={{ padding: '5px', textAlign: 'left', width: '70%' }}><b>Ending quantity:</b></td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '15%' }}></td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '15%' }}>
                      {flagDetails && flagDetails.length > 0 ? flagDetails[flagDetails.length - 1].quantity_after.toFixed(1) : ''}
                    </td>                
                </tr>  
              </tbody>
            </table>
          </div>

          <br/>

          <div 
            ref={tableContainerRef}
            style={{ 
              border: '2px solid #31bac1', 
              display: 'flex', 
              justifyContent: 'left', 
              flexDirection: 'column', 
              width: '100%', 
              height: 'auto',
              maxHeight: '310px', // Set a fixed height
              overflowY: 'auto', // Enable vertical scrolling
              boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}
          >
            <table style={{  
              backgroundColor: '#fffff', 
              width: '100%', 
              borderCollapse: 'separate', 
              borderSpacing: 0,       
              position: 'relative', // Ensure positioning context
              tableLayout: 'fixed', // Ensure fixed table layout
            }}>
              <thead style={{
                position: 'sticky', // Stick the header
                top: 0, // Stick to the top of the table
                backgroundColor: '#ffffff', // Background to cover underlying rows
              }}>
                <tr style={{ fontSize: '14px', backgroundColor: '#fffff', color: '#00000', }}>
                  <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center', width: '24%' }}>Date</th>
                  <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center', width: '24%' }}>Event</th>
                  <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center', width: '24%' }}>User</th>
                  <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center', width: '7%' }}>Change</th>
                  <th style={{ borderBottom:'2px solid black', padding: '5px', textAlign: 'center', width: '14%' }}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {flagDetails && flagDetails.map((item, index) => (
                  <tr style={{ fontSize: '13px', backgroundColor: (item.flag ? 'rgba(253, 244, 244, 1)' : (item.origin === 'Spotcheck' || item.origin === 'Edit Inventory' ? 'rgba(245, 251, 240, 1)' : '#ffffff'))}} >
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '24%'  }}>{item.processed_origin !== 'Product Usage' ? formatDateText(item.date_time) : '...'}</td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '24%' }}>{item.processed_origin}</td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '24%' }}>{item.username}</td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'left', width: '7%' }}>
                      {(item.origin === 'Spotcheck' || item.origin === 'Edit Inventory') ? (
                        ''
                      ) : (
                        <>
                          {item.delta >= 0 ? (
                            <span style={{ float: 'left', color: (item.flag ? 'rgba(93, 158, 82, 1)' : '#A9A9A9') }}>
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            </span>
                          ) : (
                            <span style={{ float: 'left', color: (item.flag ? 'rgba(207, 100, 84, 1)' : '#A9A9A9') }}>
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            </span>
                          )}
                          <span style={{ float: 'right' }}>{Math.abs(item.delta).toFixed(1)}</span>
                        </>
                      )}
                    </td>
                    <td className='table-row' style={{ padding: '5px', textAlign: 'center', width: '14%' }}>{item.quantity_after.toFixed(1)}</td>
                  </tr>
                ))}      
              </tbody>
            </table>
          </div>
        </div>


        <button onClick={() => {toggleFlagsDetailsModal(); setFlagToResolve([]); setFlagDetails(''); resetAll();}}
          style={{
            border: '3px solid #31bac1', 
            backgroundColor: '#31bac1', 
            padding: '5px',
            margin: '20px'
          }}
        >
          Close
        </button>

        <button onClick={async () => {toggleFlagsDetailsModal(); setFlagDetails(''); toggleFlagsModal(); setDeltaBeforeProcess(flagToResolve.total_delta); setCountedQuantity(flagToResolve.quantity_conversion !== 0 ? parseFloat((flagToResolve.inventory_quantity / flagToResolve.quantity_conversion).toFixed(1)) : flagToResolve.inventory_quantity); await fetchOrdersToCheck(flagToResolve); await getFreeProductsNumber(flagToResolve); await getMoveProductsNumber(flagToResolve); }} 
          style={{
            border: '3px solid #F49C7D', 
            backgroundColor: '#F49C7D', 
            padding: '5px',
            margin: '20px'
          }}              
        >
          Resolve
        </button>


      </Modal>


      <Modal
        overlayClassName="modal-overlay"
        isOpen={RecommendationsDetailsModalIsOpen}
        onRequestClose={() => {
            toggleRecommendationsDetailsModal();
            setRecommendationDetails([]);
            setRecommendationPrediction([]);
        }}
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
                width: '50%',
                height: '85%',
                margin: 'auto',
                padding: '0',
                borderRadius: '8px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'rgba(251, 251, 251)', // Set the background color to white
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
            },
        }}
      >
        <div style={{ flex: '0 1 auto', padding: '20px', borderBottom: '1px solid #ccc' }}>
            <span style={{ color: 'gray', fontSize: '20px' }}>Product Details for</span>
            <br />
            <b style={{ color: 'black', fontSize: '25px' }}>
                {recommendationDetails.product_name} {recommendationDetails.product_description ? `- ${recommendationDetails.product_description}` : ''}
            </b>
        </div>

        <div style={{ flex: '1 1 auto', padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px' }}>
                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h3 style={{ color: 'gray' }}>Current Quantity</h3>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li>
                            <b style={{ fontSize: '40px', color: 'black' }}>
                                {Object.keys(recommendationPrediction).length > 0 && (
                                    <>{recommendationPrediction.current_inventory_quantity.toFixed(1)}</>
                                )}
                            </b>
                        </li>
                    </ul>
                </div>

                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h3 style={{ color: 'gray' }}>Weeks Remaining</h3>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li>
                            <b style={{ fontSize: '40px', color: 'rgb(132, 94, 29)' }}>
                                {Object.keys(recommendationPrediction).length > 0 && (
                                    <>{(recommendationPrediction.current_inventory_quantity / (recommendationPrediction.usage_forecast / 2)).toFixed(1)}</>
                                )}
                            </b>
                        </li>
                    </ul>
                </div>

                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h3 style={{ color: 'gray' }}>Suggested to Order</h3>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li>
                            <b style={{ fontSize: '40px', color: '#31bac1' }}>
                                {recommendationDetails.order_quantity}
                            </b>
                        </li>
                    </ul>
                </div>
            </div>

            <h3 style={{ color: 'gray' }}>Product Usage Per Week</h3>
            <div style={{ width: '100%', height: '250px', marginTop: '-30px' }}>
                <LineGraph
                    data={productUsageData}
                    title=""
                    xAxisLabel=""
                    yAxisLabel={
                        recommendationPrediction.product_unit
                            ? (recommendationPrediction.product_unit === 'box' ? 'box(es)' : `${recommendationPrediction.product_unit}(s)`) + " used"
                            : "units used" // Fallback in case product_unit is undefined
                    }
                    lineColor="#F49C7D"
                    width={1000}
                    height={400}
                />
            </div>
        </div>

        <div style={{ flex: '0 1 auto', padding: '20px', borderTop: '1px solid #ccc' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                    onClick={() =>{
                      toggleRecommendationsDetailsModal();
                      setRecommendationDetails([]);
                      setRecommendationPrediction([]);
                      handleAcceptDecline(recommendationDetails.order_id, recommendationDetails.order_quantity, false);
                    }}
                    style={{
                        border: '1px solid rgb(207, 100, 84)',
                        backgroundColor: 'rgb(207, 100, 84)',
                        padding: '8px',
                        color: 'white',
                        borderRadius: '4px',
                        fontSize: '16px'
                    }}
                >
                    Decline
                </button>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button
                        onClick={() => {
                            toggleRecommendationsDetailsModal();
                            setRecommendationDetails([]);
                            setRecommendationPrediction([]);
                        }}
                        style={{
                            border: '1px solid #31bac1',
                            backgroundColor: 'white',
                            padding: '8px',
                            color: '#31bac1',
                            borderRadius: '4px',
                            fontSize: '16px'
                        }}
                    >
                        Close
                    </button>
                    <button
                        onClick={() => {
                            toggleRecommendationsDetailsModal();
                            setRecommendationDetails([]);
                            setRecommendationPrediction([]);
                            handleOrder(
                              recommendationDetails.product_id,
                              recommendationDetails.product_name,
                              recommendationDetails.order_id,
                              recommendationDetails.order_quantity,
                              'recommend',
                              recommendationDetails.manufacturer_name,
                              recommendationDetails.client_comment,
                              recommendationDetails.order_urgency,
                              recommendationDetails
                            );
                          }
                        }
                        style={{
                            border: '1px solid #31bac1',
                            backgroundColor: '#31bac1',
                            padding: '8px',
                            color: 'black',
                            borderRadius: '4px',
                            fontSize: '16px'
                        }}
                    >
                        Accept
                    </button>
                </div>
            </div>
        </div>
      </Modal>




    </div>

    
  );
};

export default Home;