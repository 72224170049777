import React, { useState } from 'react';
import Modal from 'react-modal';
import { handleEditSubmit } from '../../utils/procurement/procurementUtils';
import { fetchTicketsInfo } from '../../utils/locations/locationsUtils';

import loadingSpinner from '../../assets/images/loading.gif';

const OrderEditModal = ({isOpen, onClose, editedOrder, setEditedOrder, orders, setTicketInfo, username, initialStatus, locationNameSelected, setTickets}) => {
    const [optionPrice, setOptionPrice] = useState('unit_price');
    const [totalOrderPrice, setTotalOrderPrice] = useState(editedOrder?.total_price || 0);
    const [isLoading, setIsLoading] = useState(false);

    // Gets unique category of products
    const orderStatusCategories = [
        'Placed',
        'In Review',
        'Open',
        'Shipped',
        'Delivered',
        'Recommend',
        'Remove',
        'Moved',
        'Declined',
        'Confirmed',
        'Backorder',
        'In Dispute',
        'Returned'
    ];

    return (
        <Modal
            overlayClassName="modal-overlay"
            className="modal-content"
            isOpen={isOpen}
            onRequestClose={() => {onClose(); setOptionPrice("unit_price"); setTotalOrderPrice(0);}}
            style={{
                overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 2,
                },
            }}
        >
            <h3>Edit Order</h3>
            {editedOrder && (
                <div>
                <form onSubmit={async (e) => {
                    e.preventDefault();

                    setIsLoading(true);

                    await handleEditSubmit(
                        editedOrder, 
                        orders, 
                        editedOrder.order_code, 
                        editedOrder.location_name,
                        setTicketInfo,
                        username
                    );
                    setOptionPrice("unit_price");
                    setTotalOrderPrice(0);
                    
                    setIsLoading(false);

                    onClose();

                    await fetchTicketsInfo(setTickets, locationNameSelected);

                }}>
                    {(editedOrder.status_cat !== 'Shipped' && editedOrder.status_cat !== 'Delivered') && (
                    <>
                        <div className="input-group">
                        <label>
                            Order Price:
                            <select
                            value={optionPrice}
                            onChange={(e) => setOptionPrice(e.target.value)}
                            >
                            <option value="unit_price">Unit Price</option>
                            <option value="total_price">Total Price</option>
                            </select>
                        </label>
                        {optionPrice === 'unit_price' ? (
                            <label>
                            <i style={{fontSize: '12px'}}>Unit Price:</i>
                            <input
                                type="number"
                                value={editedOrder.unit_price}
                                onChange={(e) =>
                                setEditedOrder({
                                    ...editedOrder,
                                    unit_price: e.target.value,
                                })
                                }
                            />
                            </label>
                        ) : (
                            <label>
                            <i style={{fontSize: '12px'}}>Total Price:</i>
                            <input
                                type="number"
                                value={totalOrderPrice}
                                onChange={(e) =>
                                setTotalOrderPrice(e.target.value)
                                }
                            />
                            </label>
                        )}
                        </div>
                        <div className="input-group">
                        <label>
                            Order Quantity:
                            <input
                            type="number"
                            value={editedOrder.order_quantity}
                            onChange={(e) =>
                                setEditedOrder({
                                ...editedOrder,
                                order_quantity: e.target.value,
                                })
                            }
                            />
                        </label>
                        </div>
                    </>
                    )}
                    <div className="input-group">
                    <label>
                        Internal Description:
                        <textarea
                        value={editedOrder.internal_description}
                        onChange={(e) =>
                            setEditedOrder({
                            ...editedOrder,
                            internal_description: e.target.value,
                            })
                        }
                        />
                    </label>
                    </div>
                    <div className="input-group">
                    <label htmlFor="status">
                        Status:
                        {editedOrder.status_cat !== 'Shipped' ? (
                        <select
                            id="status"
                            value={editedOrder.status_cat}
                            onChange={(e) =>
                            setEditedOrder({
                                ...editedOrder,
                                status_cat: e.target.value,
                            })
                            }
                            disabled={editedOrder.product_cat === 'web' || initialStatus === 'Delivered'}
                        >
                            {orderStatusCategories.sort().map(status => (
                            <option key={status} value={status}>
                                {status}
                            </option>
                            ))}
                        </select>
                        ) : (
                        <select
                            id="status"
                            value={editedOrder.status_cat}
                            onChange={(e) =>
                            setEditedOrder({
                                ...editedOrder,
                                status_cat: e.target.value,
                            })
                            }
                        >
                            <option value="Shipped">Shipped</option>
                            <option value="Delivered">Delivered</option>
                        </select>
                        )}
                    </label>
                    </div>
                    <div className="input-group">
                        {isLoading ? (
                            <img
                                src={loadingSpinner}
                                alt="loading-spinner"
                                style={{
                                    height: '20px',
                                    marginRight: '10px'
                                }}
                            />
                        ) : (
                            <button
                                type="submit"
                                style={{
                                border: '3px solid #31bac1',
                                backgroundColor: '#31bac1',
                                color: '#fff',
                                padding: '8px 12px',
                                cursor: 'pointer',
                                borderRadius: '8px',
                                }}
                                onClick={() => {
                                if (optionPrice !== 'unit_price') {
                                    setEditedOrder({
                                    ...editedOrder,
                                    unit_price: totalOrderPrice / editedOrder.order_quantity,
                                    });
                                }
                                }}
                            >
                                Submit
                            </button>
                        )}
                        
                        <button
                            onClick={() => { setOptionPrice("unit_price"); setTotalOrderPrice(0); onClose();}}
                            style={{
                            border: '3px solid #31bac1',
                            backgroundColor: '#31bac1',
                            color: '#fff',
                            padding: '8px 12px',
                            cursor: 'pointer',
                            borderRadius: '8px',
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
                </div>
            )}
        </Modal>
    );
};

export default OrderEditModal;
