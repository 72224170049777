import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDateToUTCString } from '../utils/time_zones/TimeZones';
import Select from 'react-select'
import {ProductTable} from '../features/mapping/ProductTable';
import {ServiceTable} from '../features/mapping/ServiceTable';
import {ErrorTable} from '../features/mapping/ErrorTable';
import { formatErrorDescription, getPercentageChange } from '../utils/mapping/Format';
import * as Fetch from '../utils/mapping'

const Mapping = ({ call }) => {
    const contentRef = useRef();
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('VIO Annapolis');
    const [selectedTab, setSelectedTab] = useState('ServiceMapping');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState([]);
    const [lastProductId, setLastProductId] = useState(null);
    const [lastServiceId, setLastServiceId] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [selectedService, setSelectedService] = useState([]);
    const [selectedServiceMapping, setSelectedServiceMapping] = useState([]);
    const [productDone, setProductDone] = useState(false);
    const [priceDone, setPriceDone] = useState(false);
    const [inventoryDone, setInventoryDone] = useState(false);
    const [serviceDone, setServiceDone] = useState(false);
    const [serviceMappingDone, setServiceMappingDone] = useState(false);
    const [finalDone, setFinalDone] = useState(false);
    const [webProductDone, setWebProductDone] = useState(false);
    const [serviceMappingOrderStatus, setServiceMappingOrderStatus] = useState('');
    const [serviceCurrentData, setCurrentServiceData] = useState([]);
    const [compareServiceOptions, setCompareServiceOptions] = useState([])
    const [inventoryCurrentData, setCurrentInventoryData] = useState([]);
    const [webProductsData, setWebProductsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [selectedWebProduct, setSelectedWebProduct] = useState([]);
    const [selectedWebPrice, setSelectedWebPrice] = useState([]);
    const [selectedWebInventory, setSelectedWebInventory] = useState([]);
    const [bulkUsageQuantity, setBulkUsageQuantity] = useState(0);
    const [bulkCheckboxChecked, setBulkCheckboxChecked] = useState(false);
    const [bulkUsagePercent, setBulkUsagePercent] = useState(1);
    const [bulkPercentChecked, setBulkPercentChecked] = useState(false);
    const [overwriteChecked, setOverwriteChecked] = useState(false);
    const [suppliersData, setSuppliersData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [unmappedProducts, setUnmappedProducts] = useState([]);
    const [unmappedServices, setUnmappedServices] = useState([]);
    const [mappingOutliers, setMappingOutliers] = useState([])
    const [initialServicesMapping, setInitialServicesMapping] = useState([]);
    const [highlightedRows, setHighlightedRows] = useState([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState('');
    const [newProductMapping, setNewProductMapping] = useState(false);
    const [newInventoryMapping, setNewInventoryMapping] = useState(false);
    const [newServiceMapping, setNewServiceMapping] = useState(false);
    const [compareLocation, setCompareLocation] = useState([]);
    const [compareProduct, setCompareProduct] = useState({});
    const [cloneProducts, setCloneProducts] = useState([]);
    const [compareService, setCompareService] = useState({})
    const [cloneServices, setCloneServices] = useState([])
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [unknownServices, setUnknownServices] = useState([]);
    const [discontinuedProducts, setDiscontinuedProducts] = useState([]);
    const [deleteProduct, setDeleteProduct] = useState('');
    const [newService, setNewService] = useState({});
    const uniqueServiceCategories = Array.from(new Set(serviceCurrentData.map(item => item.service_cat)));
    const uniqueServiceSubCategories = Array.from(new Set(serviceCurrentData.map(item => item.service_sub_cat)));
    const uniqueServiceInternalCat = Array.from(new Set(serviceCurrentData.map(item => item.internal_cat)));


    const uniqueManufacturers = Array.from(new Set(inventoryCurrentData.map(item => item.manufacturer_name)));
    const uniqueProductCategories = Array.from(new Set(inventoryCurrentData.map(item => item.product_cat)));


    useEffect(() => {
        Fetch.fetchLocations(call, setLocations);
        Fetch.fetchServicesData(call, selectedLocation, setCurrentServiceData);
        Fetch.fetchServicesData(call, null, setCompareServiceOptions);
        Fetch.fetchInventory(call, selectedLocation, setCurrentInventoryData);
        Fetch.fetchLastProductId(call, setLastProductId);
        Fetch.fetchLastServiceId(call, setLastServiceId);
        Fetch.fetchProducts(call, setProductsData);
        Fetch.fetchWebProducts(call, setWebProductsData);
        Fetch.fetchSuppliersData(call, setSuppliersData);
        Fetch.fetchUnmappedProducts(call, selectedLocation, setUnmappedProducts);
        Fetch.fetchUnmappedServices(call, selectedLocation, setUnmappedServices);
        Fetch.fetchUnknownServices(call, selectedLocation, selectedTimeframe, setUnknownServices);
        Fetch.fetchDiscontinuedProducts(call, selectedLocation, setDiscontinuedProducts);
        Fetch.fetchMappingOutliers(call, selectedLocation, setMappingOutliers);

        contentRef.current.style.marginLeft = '250px';
        contentRef.current.style.marginRight = '50px';
    }, [selectedLocation]);

    // Listeners for cloning
    useEffect(() => {
        if (!serviceMappingDone && productDone) {
            const product_id = 'product_id' in compareProduct ? compareProduct.product_id : selectedProduct[0].product_id
            Fetch.fetchCloneServicesData(call, product_id, handleCloneServiceData);
        }

    }, [compareProduct, selectedProduct]);
    // Listeners for cloning
    useEffect(() => {
        if (!serviceMappingDone && serviceDone) {
            const service_name = 'service_name' in compareService ? compareService.service_name : selectedService[0].service_name
            Fetch.fetchCloneProductsData(call, service_name, handleCloneProductData);
        }
    }, [compareService, selectedService]);
    // Listeners for cloning
    useEffect(() => {
        Fetch.fetchUnknownServices(call, selectedLocation, selectedTimeframe,setUnknownServices);
    }, [selectedTimeframe]);
    
    /**********************/
    /* HANDLING FUNCTIONS */
    /**********************/
    const handleServiceMappingInfo = async (data) => {
        // Update state with fetched service mapping info
        setSelectedService([data.service_info]);
        setSelectedServiceMapping([data.services_mapping]);
        setInitialServicesMapping([data.services_mapping]);
    }

    const handleServiceMappingProductInfo = (data) => {
        setSelectedProduct([data.product_info]);
        setSelectedServiceMapping([data.services_mapping]);
        setInitialServicesMapping([data.services_mapping]);
    }
    const handleCloneServiceData = (data) => {
        const currentServiceIds = serviceCurrentData.map(obj => obj.service_name);
        // Select only services with internal_server_id overlapping services at selected location.
        // Specify the services at the new locations for comparing to current.
        const uniqueIdSet = new Set(currentServiceIds);
        const filteredObjects = data.filter(service => uniqueIdSet.has(service.service_name)).map(({ service_id, service_name, ...remaining }) => ({
            compare_service_id: service_id,
            compare_service_name: service_name,
            ...remaining
        }));
        

        // Map services at current location to their internal_server_id.
        const serviceMap = serviceCurrentData.reduce((map, service) => {
            map[service.service_name] = {'service_id':service.service_id, 'service_name':service.service_name};
                return map;
        }, {});
        // Join services at comparison location to the current location's counterpart.
        const joinedData = filteredObjects
        .filter(mapping => serviceMap[mapping.compare_service_name])
        .map(mapping => ({
            ...mapping,
            ...serviceMap[mapping.compare_service_name],
            selected: highlightedRows.length === 0 ? true : 
                ((highlightedRows.includes(serviceMap[mapping.compare_service_name].service_id) && 
                    (mapping.location_name !== selectedLocation)))
        }));

        const finalServices = joinedData.sort((a, b) => a.service_id - b.service_id)
        setCloneServices(finalServices);
        setSelectAllChecked(true);
    }
    const handleCloneProductData = (data) => {
        const inventoryProductIds = inventoryCurrentData.map(obj => obj.product_id);

        // Select only services with internal_server_id overlapping services at selected location.
        const uniqueIdSet = new Set(inventoryProductIds);
        const filteredObjects = data.filter(product => uniqueIdSet.has(product.product_id)).map(({ service_id, service_name, ...remaining }) => ({
            compare_service_id: service_id,
            compare_service_name: service_name,
            service_id: selectedService[0].service_id,
            selected: true, // highlightedRows.length === 0 ? true : highlightedRows.includes(serviceMap[mapping.internal_service_id].product_id) // TODO check
            ...remaining
        }));;

        const finalServices = filteredObjects.sort((a, b) => a.product_id - b.product_id)
        setCloneProducts(finalServices);
        setSelectAllChecked(true);
    }
    // Sets the location for comparing service mappings.
    const handleCloneLocationChange = async (selected) => {
        selected ? setCompareLocation(selected.map(l => l.value)) : setCompareLocation([]);
    };
    
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        resetAll();
    };

    const handleSubmitInventory = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const inventoryData = {
            location_name: formData.get('locationName'),
            product_id: parseInt(formData.get('productId')),
            inventory_quantity: parseFloat(formData.get('inventoryQuantity')),
            class: parseFloat(formData.get('class')),
            spotcheck_status: parseInt(formData.get('spotcheckStatus')),
            spotcheck_date: formData.get('spotcheckDate'),
            discontinue: parseInt(formData.get('discontinue'), 10), // Assuming discontinue is an integer
            // Rest of the fields can be left empty or you can add them here
        };
        
        setSelectedInventory([inventoryData]);

        setInventoryDone(true);
    };

    const handleSubmitProduct = async (event) => {
        event.preventDefault();

        if (selectedWebProduct.length === 0){
            setSelectedProductId(lastProductId + selectedProduct.length + 1)
        }

        const formData = new FormData(event.target);
       
        const productData = {
            product_id: selectedWebProduct.length === 0 ? (lastProductId + selectedProduct.length + 1) : (selectedProductId),
            product_name: formData.get('productName'),
            product_description: formData.get('productDescription'),
            manufacturer_name: formData.get('manufacturerName'),
            product_cat: formData.get('productCategory'),
            quantity_conversion: parseFloat(formData.get('quantityConversion')),
            product_unit: formData.get('productUnit'),
            product_tier: parseInt(formData.get('productTier'), 10)
        };

        setSelectedProduct([productData]);
        if (!serviceDone){
            setSelectedServiceMapping([[]]);
        }
        setProductDone(true);
    };

    const handleSubmitPrice = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const priceData = {
            product_id: parseInt(formData.get('productId'), 10),
            supplier_id: parseInt(formData.get('supplierId'), 10),
            client_price: parseFloat(formData.get('clientPrice')),
            delivery_days: parseInt(formData.get('deliveryDays'), 10),
        };

        setSelectedPrice([priceData]);

        setPriceDone(true);
    };

    const handleSubmitService = async (event) => {
        event.preventDefault();

        setSelectedServiceId(lastServiceId + selectedService.length + 1);
        const formData = new FormData(event.target);
        const serviceData = {
            service_id: lastServiceId + selectedService.length + 1,
            service_name: formData.get('serviceName'),
            location_name: formData.get('locationName'),
            service_cat: formData.get('serviceCategory'),
            service_sub_cat: formData.get('serviceSubCategory'),
            internal_cat: formData.get('internalCategory'),
            service_price: parseFloat(formData.get('servicePrice')),
            duration: parseFloat(formData.get('duration')),
            provider_requirement: formData.get('providerRequirement'),
            room_type: formData.get('roomType'),
            //internal_service_id: !newService.internal_service_id ?  null : newService.internal_service_id//parseInt(formData.get('internalServiceId'))
        };
        setSelectedService([serviceData]);

        if (!productDone){
            setSelectedServiceMapping([[]]);
        }


        setServiceDone(true);
    };
    
    const handleAddInventoryProduct = async (product) => {
        await Fetch.fetchServiceMappingProductInfo(call, selectedLocation, product.product_id, handleServiceMappingProductInfo);
        setSelectedProductId(product.product_id);
        setSelectedProduct([product]);
        setProductDone(true);
        setPriceDone(true);
        setInventoryDone(true);
    };

    const handleAddProductToInventory = async (product) => {        
        // Check if productIdToCheck is not in the inventory list
        const isProductIdInInventory = inventoryCurrentData.some(inv => inv.product_id === product.product_id);

        if (isProductIdInInventory) {
            alert('This product already exists in the inventory');
            return; // Exit the function without adding a new mapping
        }

        setSelectedProductId(product.product_id);
        setSelectedProduct([product]);
        setProductDone(true);

        const price = await Fetch.getPrice(call, product.product_id);
        if (price != '' && price != null){
            setPriceDone(true);
        }
    };

    const handleAddService = async (service) => {
        await Fetch.fetchServiceMappingInfo(call,selectedLocation, service.service_id, handleServiceMappingInfo);
        setSelectedServiceId(service.service_id);
        setServiceDone(true);
    };

    const handleAddServiceToMapping = (service) => {

        if (selectedServiceMapping[0]){
            // Check if there's already a mapping with the same product_id and service_id
            const isMappingExist = selectedServiceMapping[0].some(mapping => mapping.product_id === selectedProductId && mapping.service_id === service.service_id);
                
            if (isMappingExist) {
                alert('Mapping for this combination already present');
                return; // Exit the function without adding a new mapping
            }
        }
    
        // If mapping doesn't exist, create a new mapping object
        const service_mapping = {
            product_id: selectedProductId,
            service_id: service.service_id,
            usage_quantity: 0
        };

        // Add the new mapping to selectedServiceMapping
        if (selectedServiceMapping[0]){
            setSelectedServiceMapping([[...selectedServiceMapping[0], service_mapping]]);
        }
        else{
            setSelectedServiceMapping([[service_mapping]]);
        }
    };
    
    const handleDeleteServiceMapping = (productId, serviceId) => {

        const updatedMappings = selectedServiceMapping[0].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
        setSelectedServiceMapping([updatedMappings]);
    };

    const handleDeleteServiceMappingPermanent = (productId, serviceId) => {
        return new Promise(async (resolve, reject) => {
            // Make PUT request to delete-service-mapping API
            await fetch(`${call}/delete-service-mapping`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    product_id: productId,
                    service_id: serviceId
                })
            })
            .then(response => {
                // Check if response is ok (status between 200 and 299)
                if (response.ok) {
                    return response.json(); // Parse response JSON
                } else {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
            })
            .then(data => {
                resolve(data); // Resolve with response data

                const updatedMappings = selectedServiceMapping[0].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
                setSelectedServiceMapping([updatedMappings]);
            })
            .catch(error => {
                reject(error.message); // Reject with error message
            });
        });
    };

    const handleDeleteProductInventory = () => {
        return new Promise(async (resolve, reject) => {
            // Make PUT request to delete-service-mapping API
            await fetch(`${call}/delete-inventory-product`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    product_id: deleteProduct,
                    location_name: selectedLocation
                })
            })
            .then(response => {
                // Check if response is ok (status between 200 and 299)
                if (response.ok) {
                    return response.json(); // Parse response JSON
                } else {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
            })
            .then(data => {
                resolve(data); // Resolve with response data

                // const updatedMappings = selectedServiceMapping[0].filter(mapping => mapping.product_id !== productId || mapping.service_id !== serviceId);
                // setSelectedServiceMapping([updatedMappings]);
            })
            .catch(error => {
                reject(error.message); // Reject with error message
            });
        });
    };

    const handleAddInventoryProductMapping = (product) => {
        // Check if there's already a mapping with the same product_id and service_id
        if (selectedServiceMapping[0]){
            const isMappingExist = selectedServiceMapping[0].some(mapping => mapping.product_id === product.product_id && mapping.service_id === selectedServiceId);
    
            if (isMappingExist) {
                alert('Mapping for this combination already present');
                return; // Exit the function without adding a new mapping
            }
        }
    
        // If mapping doesn't exist, create a new mapping object
        const service_mapping = {
            product_id: product.product_id,
            service_id: selectedServiceId,
            usage_quantity: 0
        };

        // Add the new mapping to selectedServiceMapping
        if (selectedServiceMapping[0]){
            setSelectedServiceMapping([[...selectedServiceMapping[0], service_mapping]]);
        }
        else{
            setSelectedServiceMapping([[service_mapping]]);

        }

    };

    const handleAddWebProduct = async (product) => {
        setSelectedWebProduct([product]);

        setSelectedProductId(product.product_id);
        await Fetch.fetchServiceMappingProductInfo(call, selectedLocation, product.product_id, handleServiceMappingProductInfo);
        setWebProductDone(true);

        const priceWeb = await Fetch.getPrice(call, product.product_id);
        const inventoryWeb = await Fetch.getInventoryWeb(call, product.product_id, selectedLocation);
        setSelectedWebPrice([priceWeb]);
        setSelectedWebInventory([inventoryWeb]);
    };

    const handleQuantityChange = (productId, newValue) => {
        // Update the usage_quantity of the mapping with the specified productId
        const updatedMappings = selectedServiceMapping[0].map(mapping =>
            mapping.product_id === productId ? { ...mapping, usage_quantity: newValue } : mapping
        );

        // Update the state with the updated mappings
        setSelectedServiceMapping([updatedMappings]);
    };

    const handleQuantityChangeProduct = (serviceId, newValue) => {
        // Update the usage_quantity of the mapping with the specified serviceId
        const updatedMappings = selectedServiceMapping[0].map(mapping =>
            mapping.service_id === serviceId ? { ...mapping, usage_quantity: newValue } : mapping
        );

        // Update the state with the updated mappings
        setSelectedServiceMapping([updatedMappings]);
    };

    const handleSubmit = () => {
        // Implement logic to handle submission of updated quantities
        setServiceDone(true);
        setServiceMappingDone(true);
        setProductDone(true);
        setPriceDone(true);
        setInventoryDone(true);
    };

    const handleUnknownService = async (item) => {
        if (item.service_exists){
            await Fetch.fetchServiceInfo(call, item.service_name, item.price, setNewService)
        }
        else {
            setNewService({service_name: item.service_name});
        }
        setServiceMappingOrderStatus('New Service')
    }
    const handleUnmappedProduct = async (item) => {
        let {category, description, discontinue, func, sub_category, ...targetItem} = item
        await handleAddInventoryProduct(targetItem);
        setServiceMappingOrderStatus('Product');
    }
    const handleUnmappedService = async (item) => {
        await handleAddService({service_id: item.service_id, service_name: item.service_name,location_name:selectedLocation});
        setServiceMappingOrderStatus('Service')
    }
    const handleMappingOutlier = async (item) => {
        setHighlightedRows([item.service_id]);
        await handleAddInventoryProduct({product_id: item.product_id, product_name:item.product_name, product_description:item.product_description});
        setServiceMappingOrderStatus('Product');
    }
    const handleDiscontinuedProduct = (item) => {
        setDeleteProduct(item.product_id);
        setServiceMappingOrderStatus('Remove Product')
    }
    const handleManualOrder = async (item) => {
        setHighlightedRows(item.service_ids);
        await handleAddInventoryProduct({product_id: item.product_id, product_name:item.product_name, product_description:item.product_description});
        setServiceMappingOrderStatus('Product');
    }

    /*********/
    /* FORMS */
    /*********/
    const ProductForm = ({ productData = {} }) => {

        const [formData, setFormData] = useState({
            productName: productData.product_name || '',
            productDescription: productData.product_description || '',
            manufacturerName: productData.manufacturer_name || '',
            productCategory: productData.product_cat || '',
            quantityConversion: productData.quantity_conversion || '',
            productUnit: productData.product_unit || '',
            productTier: productData.product_tier || ''
        });
    
        const handleChange = (event) => {
            const { name, value } = event.target;
            setFormData({
                ...formData,
                [name]: value
            });
        };
    
        return (
            <div style={{ 
                border: '2px solid #87D3D7', 
                borderRadius: '5px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <h2 style={{textAlign: 'center'}}>Product</h2>
                <form onSubmit={handleSubmitProduct} style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: '15px',
                }}>
                    <label htmlFor="productName">Product Name:</label>
                    <input type="text" id="productName" name="productName" value={formData.productName} onChange={handleChange} required />
                    <br />
                    <label htmlFor="productDescription">Product Description:</label>
                    <input type="text" id="productDescription" name="productDescription" value={formData.productDescription} onChange={handleChange} required />
                    <br />
                    <label htmlFor="manufacturerName">Manufacturer Name:</label>
                    <select id="manufacturerName" name="manufacturerName" onChange={handleChange} required>
                        <option value={formData.manufacturerName}>{formData.manufacturerName === '' ? (<>Select Manufacturer...</>) : (<>{formData.manufacturerName}</>)}</option>
                        {uniqueManufacturers.sort().map((manufacturer, index) => (
                            <option key={manufacturer} value={manufacturer}>{manufacturer}</option>
                        ))}
                    </select>
                    <br />
                    <label htmlFor="productCategory">Product Category:</label>
                    <select id="productCategory" name="productCategory" onChange={handleChange} required>
                        <option value={formData.productCategory}>{formData.productCategory === '' ? (<>Select Product Category...</>) : (<>{formData.productCategory}</>)}</option>
                        {uniqueProductCategories.sort().map((productCat, index) => (
                            <option key={productCat} value={productCat}>{productCat}</option>
                        ))}
                    </select>
                    <br />
                    <label htmlFor="quantityConversion">Quantity Conversion:</label>
                    <input type="number" step="any" id="quantityConversion" name="quantityConversion" value={formData.quantityConversion} onChange={handleChange} required />
                    <br />
                    <label htmlFor="productUnit">Product Unit:</label>
                    <input type="text" id="productUnit" name="productUnit" value={formData.productUnit} onChange={handleChange} required />
                    <br />
                    <label htmlFor="productTier">Product Tier:</label>
                    <input type="number" id="productTier" name="productTier" value={formData.productTier} onChange={handleChange} required />
                    <br />
                    {/* Input for product picture can be added here in the future */}
                    {/* Input for subcategory can be added here in the future */}
                    <input type="submit" value="Add Product" style={{ 
                        backgroundColor: '#87D3D7', 
                        color: 'white',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }} />
                </form>
            </div>
        );
    };
    
    const PriceForm = ({ priceData = {} }) => {

        const [formData, setFormData] = useState({
            supplierId: priceData.supplier_id || '',
            clientPrice: priceData.client_price || '',
            deliveryDays: priceData.delivery_days || '',
        });
    
        const handleChange = (event) => {
            const { name, value } = event.target;
            setFormData({
                ...formData,
                [name]: value
            });
        };
    
        return (
            <div style={{ 
                border: '2px solid #87D3D7', 
                borderRadius: '5px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <h2 style={{textAlign: 'center'}}>Price</h2>
                <form onSubmit={handleSubmitPrice} style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: '15px',
                }}>
                    <input type="hidden" id="productId" name="productId" value={selectedProductId} />
                    <label htmlFor="supplierId">Supplier ID:</label>
                    <select id="supplierId" name="supplierId" onChange={handleChange} required>
                        <option value={formData.supplierId}>{formData.supplierId === '' ? (<>Select Supplier...</>) : (<>{suppliersData.filter(item => item.supplier_id == formData.supplierId)[0].supplier_name}</>)}</option>
                        {suppliersData.sort().map((supplier, index) => (
                            <option key={supplier.supplier_id} value={supplier.supplier_id}>{supplier.supplier_name}</option>
                        ))}
                    </select>
                    <br />
                    <label htmlFor="clientPrice">Client Price:</label>
                    <input type="number" step="0.01" id="clientPrice" name="clientPrice" value={formData.clientPrice} onChange={handleChange} required />
                    <br />
                    <label htmlFor="deliveryDays">Delivery Days:</label>
                    <input type="number" id="deliveryDays" name="deliveryDays" value={formData.deliveryDays} onChange={handleChange} required />
                    <br />
                    <input type="submit" value="Add Price" style={{ 
                        backgroundColor: '#87D3D7', 
                        color: 'white',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }} />
                </form>
            </div>
        );
    };    

    const InventoryForm = ({ inventoryData = {} }) => {

        const [formData, setFormData] = useState({
            inventoryQuantity: inventoryData.inventory_quantity || '',
            class: inventoryData.class || '',
            spotcheckStatus: inventoryData.spotcheck_status || ''
        });
    
        const handleChange = (event) => {
            const { name, value } = event.target;
            setFormData({
                ...formData,
                [name]: value
            });
        };
    
        return (
            <div style={{ 
                border: '2px solid #87D3D7', 
                borderRadius: '5px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <h2 style={{textAlign: 'center'}}>Inventory</h2>
                <form onSubmit={handleSubmitInventory} style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: '15px',
                }}>
                    <input type="hidden" id="locationName" name="locationName" value={selectedLocation} />
                    <input type="hidden" id="productId" name="productId" value={selectedProductId} />
                    <label htmlFor="inventoryQuantity">Inventory Quantity:</label>
                    <input type="number" step="any" id="inventoryQuantity" name="inventoryQuantity" value={formData.inventoryQuantity} onChange={handleChange} required />
                    <br />
                    <label htmlFor="class">Class:</label>
                    <input type="number" step="any" id="class" name="class" value={formData.class} onChange={handleChange} required />
                    <br />
                    <label htmlFor="spotcheckStatus">Spotcheck Status:</label>
                    <input type="number" step="any" id="spotcheckStatus" name="spotcheckStatus" value={formData.spotcheckStatus} onChange={handleChange} required />
                    <br />
                    {/* Spotcheck date automatically set to today's date */}
                    <input type="hidden" id="spotcheckDate" name="spotcheckDate" value={formatDateToUTCString(new Date())} />
                    <input type="hidden" id="discontinue" name="discontinue" value="0" />
                    {/* Rest of the fields can be left empty */}
                    <input type="submit" value="Add to Inventory" style={{ 
                        backgroundColor: '#87D3D7', 
                        color: 'white',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }} />
                </form>
            </div>
        );
    };
    
    const ServiceForm = () => {
        return (
            <div style={{ 
                border: '2px solid #87D3D7', 
                borderRadius: '5px',
                width: '40%',
                padding: '20px',
                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
            }}>
                <h2 style={{textAlign: 'center'}}>Service</h2>
                <form onSubmit={handleSubmitService} style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    gap: '15px',
                }}>
                    <input type="hidden" id="locationName" name="locationName" value={selectedLocation} />
                    
                    <label htmlFor="serviceName">Service Name:</label>
                    <input type="text" id="serviceName" name="serviceName" required defaultValue={newService.service_name} readOnly={newService.service_name}/>
                    
                    <label htmlFor="serviceCategory">Service Category:</label>
                    <select id="serviceCategory" name="serviceCategory" defaultValue={newService.service_cat} required>
                        <option value="">Select Service Category...</option>
                        {uniqueServiceCategories.sort().map((category, index) => (
                            <option key={index} value={category}>{category}</option>
                        ))}
                    </select>
                    
                    <label htmlFor="serviceSubCategory">Service Sub Category:</label>
                    <select id="serviceSubCategory" name="serviceSubCategory" defaultValue={newService.service_sub_cat} required>
                        <option value="">Select Service Sub Category...</option>
                        {uniqueServiceSubCategories.sort().map((subCategory, index) => (
                            <option key={index} value={subCategory}>{subCategory}</option>
                        ))}
                    </select>
                    
                    <label htmlFor="internalCategory">Internal Category:</label>
                    <select id="internalCategory" name="internalCategory" required>
                        <option value="">Select Internal Category...</option>
                        {uniqueServiceInternalCat.sort().map((internalCat, index) => (
                            <option key={index} value={internalCat}>{internalCat}</option>
                        ))}
                    </select>
                    
                    <label htmlFor="servicePrice">Service Price:</label>
                    <input type="text" id="servicePrice" name="servicePrice" defaultValue={newService.service_price} required />
                    
                    <label htmlFor="duration">Duration:</label>
                    <input type="text" id="duration" name="duration" defaultValue={newService.duration} required />
                    
                    <label htmlFor="providerRequirement">Provider Requirement:</label>
                    <input type="text" id="providerRequirement" name="providerRequirement" defaultValue={newService.provider_requirement} required />
                    
                    <label htmlFor="roomType">Room Type:</label>
                    <input type="text" id="roomType" name="roomType" defaultValue={newService.room_type} required />
                    
                    <input type="submit" value="Add Service" style={{ 
                        backgroundColor: '#87D3D7', 
                        color: 'white',
                        padding: '10px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }} />
                </form>
            </div>
        );
    }

    /*****************/
    /* FILTERED DATA */
    /*****************/
    const getFilteredData = () => {
        const products = unmappedProducts.map(product => ({
            ...product,
            category: 'Error',
            sub_category: 'Unmapped Product',
            description: formatErrorDescription(product, 'Unmapped Product'),
            callBack:  handleUnmappedProduct
        }));

        const services = unmappedServices.map(service => ({
            ...service,
            category: 'Error',
            sub_category: 'Unmapped Service',
            description: formatErrorDescription(service, 'Unmapped Service'), 
            callBack:  handleUnmappedService

        }));

        const services_unknown = unknownServices.map(service => ({
            ...service,
            category: 'Error',
            sub_category: 'Unknown Service',
            description: formatErrorDescription(service, 'Unknown Service'), 
            callBack:  handleUnknownService
        }));

        const discontinued_products = discontinuedProducts.map(product => ({
            ...product,
            category: 'Alert',
            sub_category: 'Discontinued Product',
            description: formatErrorDescription(product, 'Discontinued Product'), 
            callBack:  handleDiscontinuedProduct
        }));

        const outliers = mappingOutliers.filter(item => (item.origin.toLowerCase().includes('anomaly'))).map(service => ({
            ...service,
            category: 'Anomaly',
            sub_category: 'Outlier',
            description: formatErrorDescription(service, 'Outlier'), 
            callBack:  handleMappingOutlier
        }));

        const manualGrouped = Object.values(mappingOutliers.filter(item => (item.origin.toLowerCase().includes('alert'))).reduce((acc, mapping) => {
            const product_id = mapping.product_id;
            
            // If the department doesn't exist in the accumulator, create an object for it
            if (!acc[product_id]) {
                acc[product_id] = { 
                    product_id, 
                    product_name: mapping.product_name,
                    product_description: mapping.product_description, 
                    product_cat: mapping.product_cat, 
                    product_unit: mapping.product_unit, 
                    origin: mapping.origin,
                    spotcheck_status: mapping.spotcheck_status,
                    usage_quantity: mapping.usage_quantity,
                    service_ids: [] };
            }
        
            
            // Push the current employee object with selected fields to the appropriate department group
            acc[product_id].service_ids.push(mapping.service_id);
            
            return acc;
        }, {}));

        const manual_mapping = manualGrouped.map(service => ({
            ...service,
            category: 'Alert',
            sub_category: 'Manual Order',
            description: formatErrorDescription(service, 'Manual Order'), 
            callBack:  handleManualOrder
        }));
        return products.concat(services).concat(services_unknown).concat(discontinued_products).concat(manual_mapping).concat(outliers);
    }
    
    const resetAll = () => {
        setProductDone(false);
        setPriceDone(false);
        setInventoryDone(false);
        setServiceDone(false);
        setServiceMappingDone(false);
        setFinalDone(false);
        setWebProductDone(false);
        setServiceMappingOrderStatus('');
        setSelectedProduct([]);
        setSelectedPrice([]);
        setSelectedInventory([]);
        setSelectedService([]);
        setSelectedServiceMapping([]);
        setSelectedProductId(null);
        setSelectedServiceId(null);
        setSelectedWebProduct([]);
        setSelectedWebPrice([]);
        setSelectedWebInventory([]);
        setBulkCheckboxChecked(false);
        setBulkUsageQuantity(0);
        setOverwriteChecked(false);
        setSelectedCategory('');
        setNewProductMapping(false);
        setNewInventoryMapping(false);
        setNewServiceMapping(false);
        setCompareProduct({});
        setCompareLocation([]);
        setCloneServices([]);
        setBulkPercentChecked(false);
        setBulkUsagePercent(1);
        setCompareService({});
        setCloneProducts([]);
        setSelectAllChecked(false);
        setNewService({});
        setHighlightedRows([]);
    };

    /************************/
    /* SUBMISSION FUNCTIONS */
    /************************/
    // Function to make API request to submit mapping data
    async function submitMappingDataGeneral() {
        try {
            // Handle spotcheck from error tab.
            const mapping = selectedServiceMapping;
            if (selectedTab === 'Errors' && mapping[0] !== undefined){
                mapping[0] = mapping[0].map(item => ({
                    ...item,        // Spread the existing properties
                    highlighted: highlightedRows.includes(item.service_id)
                }));
            }

            setLoading(true);
            const response = await fetch(`${call}/add-mapping-new`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                location_name: selectedLocation,
                selectedProduct, // Your selectedProduct list
                selectedInventory, // Your selectedInventory list
                selectedPrice, // Your selectedPrice list
                selectedService, // Your selectedService list
                selectedServiceMapping: mapping // Your selectedServiceMapping list
            })
        });
        if (response.ok) {
            setLoading(false);
            // Reinitialize all the lists to empty lists
            setSelectedProduct([]);
            setSelectedInventory([]);
            setSelectedPrice([]);
            setSelectedService([]);
            setSelectedServiceMapping([]);
        } else {
            response.json().then(error => {
                console.log('Error ' + response.status + ': ' + error.error);
                alert('Error ' + response.status + ': ' + error.error);
                throw new Error('Error ' + response.status + ': ' + error.error);
              });
        }
        } catch (error) {
        console.error('Error:', error);
        }

        Fetch.fetchServicesData(call, selectedLocation, setCurrentServiceData);
        Fetch.fetchServicesData(call, null, setCompareServiceOptions);
        Fetch.fetchInventory(call, selectedLocation, setCurrentInventoryData);
        Fetch.fetchLastProductId(call, setLastProductId);
        Fetch.fetchLastServiceId(call, setLastServiceId);
        Fetch.fetchWebProducts(call, setWebProductsData);
        Fetch.fetchUnmappedProducts(call, selectedLocation, setUnmappedProducts);
        Fetch.fetchUnmappedServices(call, selectedLocation, setUnmappedServices);
        Fetch.fetchUnknownServices(call, selectedLocation, selectedTimeframe, setUnknownServices);
        Fetch.fetchDiscontinuedProducts(call, selectedLocation, setDiscontinuedProducts);
        Fetch.fetchMappingOutliers(call, selectedLocation, setMappingOutliers);
        setSelectedWebProduct([]);
    }

    // Function to make API request to submit mapping data
    async function submitMappingDataProductOrService() {
        try {

        setLoading(true);
        const response = await fetch(`${call}/add-mapping-new`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            location_name: selectedLocation,
            selectedProduct, // Your selectedProduct list
            selectedInventory, // Your selectedInventory list
            selectedPrice, // Your selectedPrice list
            selectedService, // Your selectedService list
            selectedServiceMapping: []
            })
        });
    
        if (response.ok) {
            console.log('Data submitted successfully');
            setLoading(false);
        } else {
            response.json().then(error => {
                console.log('Error ' + response.status + ': ' + error.error);
                alert('Error ' + response.status + ': ' + error.error);
                throw new Error('Error ' + response.status + ': ' + error.error);
              });
        }
        } catch (error) {
        console.error('Error:', error);
        }

        Fetch.fetchServicesData(call, selectedLocation, setCurrentServiceData);
        Fetch.fetchServicesData(call, null, setCompareServiceOptions);
        Fetch.fetchInventory(call, selectedLocation, setCurrentInventoryData);
        Fetch.fetchProducts(call, setProductsData);
        Fetch.fetchLastProductId(call, setLastProductId);
        Fetch.fetchLastServiceId(call, setLastServiceId);
        Fetch.fetchWebProducts(call, setWebProductsData);
        Fetch.fetchUnmappedProducts(call, selectedLocation, setUnmappedProducts);
        Fetch.fetchUnmappedServices(call, selectedLocation, setUnmappedServices);
        Fetch.fetchUnknownServices(call, selectedLocation,selectedTimeframe, setUnknownServices);
        Fetch.fetchDiscontinuedProducts(call, selectedLocation, setDiscontinuedProducts);
        Fetch.fetchMappingOutliers(call, selectedLocation, setMappingOutliers);
    }
    
    /**************/
    /* COMPONENTS */
    /**************/
    const serviceMappingFromService = () => {
    
        const serviceMappings = selectedServiceMapping[0];
        const displayCloneServices = compareLocation.length === 0 ? cloneProducts : cloneProducts.filter(obj => compareLocation.includes(obj.location_name));
        const uniqueServiceOptions = Array.from(new Map(
            compareServiceOptions.map(item => [item.service_name, item])
        ).values());
        const uniqueLocationOptions = Array.from(new Set(cloneProducts.map(item => item.location_name)));
        const handleSelectRow = (product_id, service_id) =>  (_) =>{
            const newCloneProducts = [...cloneProducts];

            const index = newCloneProducts.findIndex(row => (row.product_id === product_id) && (row.compare_service_id) === service_id);

            newCloneProducts[index].selected = !newCloneProducts[index].selected
            
            setCloneProducts(newCloneProducts);
            setSelectAllChecked(false);
        };

        const handleSelectAll = (isChecked) => {
            const newCloneProducts = [...cloneProducts].map(row => ({
                ...row,
                selected: isChecked ? true : false
            }));;
            setSelectAllChecked(!selectAllChecked);
            setCloneProducts(newCloneProducts);
        };

        const handleOverwriteChange = (event) => {
                setOverwriteChecked(event.target.checked);
        };

        const handleSubmitCloneProducts = () => {
            const displayCloneProducts = compareLocation.length === 0 ? cloneProducts.filter(obj => obj.selected) : 
                cloneProducts.filter(
                    obj => (compareLocation.includes(obj.location_name) && obj.selected)
                );
            
            // Group data on product_id for aggregating product usage from different locations.
            const groupedData = displayCloneProducts.reduce((acc, obj) => {                
                if (!acc[obj.product_id]) {
                    acc[obj.product_id] = { total: 0, count: 0};
                }
                acc[obj.product_id].total += obj.usage_quantity;
                acc[obj.product_id].count += 1;
                return acc;
            }, {});
            // Calculate the usage quanitites of new products.
            const averages = Object.keys(groupedData).map(key => {
                let usage = groupedData[key].total / groupedData[key].count;

                return {
                    product_id: parseInt(key, 10),
                    usage_quantity: usage,
                    service_id: selectedService[0].service_id
                };
            });

            // Combine original and new mappings.
            const combinedMap = new Map();
            if (overwriteChecked){
                // Updates original quantities when overwriting 
                selectedServiceMapping[0].forEach(obj => combinedMap.set(obj.product_id, obj));
                averages.forEach(obj => combinedMap.set(obj.product_id, obj));
            }
            else{
                // Original will overwrite update quantity where keys are equal.
                averages.forEach(obj => combinedMap.set(obj.product_id, obj));
                selectedServiceMapping[0].forEach(obj => combinedMap.set(obj.product_id, obj));
            }
            const combinedServices = Array.from(combinedMap.values());
            setSelectedServiceMapping([
                    combinedServices.sort((a, b) => a.product_id - b.product_id),
                    ...selectedServiceMapping.slice(1) // TODO: Maybe not necessary
            ])
        };

        const handleCloneServiceChange = async (selected) => {
            setCompareService({'service_name':selected.label})//, 'internal_service_id':selected.value}); 
        };

        return (
            <div>
                <h3>{selectedService[0].service_name} - Service Mappings</h3>
                <input
                    type="checkbox"
                    id="overwriteCheckbox"
                    checked={overwriteChecked}
                    onChange={handleOverwriteChange}
                />
                <label htmlFor="overwriteCheckbox">Overwrite </label>
                <br/>
                <br/>

                <div style={{display:'flex'}}>

                    <div style={{ 
                        border: '2px solid #87D3D7', 
                        display: 'flex', 
                        justifyContent: 'left', 
                        flexDirection: 'column', 
                        width: '50%', 
                        maxHeight: '610px', // Set a fixed height
                        overflowY: 'auto', // Enable vertical scrolling
                        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                    }}>
                        <table style={{  
                            width: '100%', 
                            borderCollapse: 'separate', 
                            borderSpacing: 0, 
                            position: 'relative' // Ensure positioning context
                        }}>
                            <thead style={{
                                position: 'sticky', // Stick the header
                                top: 0, // Stick to the top of the table
                                zIndex: 1 // Ensure it stays above the tbody rows
                            }}>
                                <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Product ID</th>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Product Name</th>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Usage Quantity</th>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serviceMappings && serviceMappings.map((mapping, index) => {
                                    // Check if the mapping is not in initialServicesMapping
                                    const isEditable = initialServicesMapping[0] ? (!initialServicesMapping[0].some(initialMapping =>
                                        initialMapping.product_id === mapping.product_id &&
                                        initialMapping.service_id === selectedServiceId
                                    )) : (true);

                                    return (
                                    <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                        <td style={{ padding: '10px', }}>{mapping.product_id}</td>
                                        <td style={{ padding: '10px', }}>
                                            {inventoryCurrentData.find(item => item.product_id === mapping.product_id)?.product_name || ''}
                                        </td>
                                        <td style={{ padding: '10px', }}>
                                            <input
                                                type="number"
                                                value={mapping.usage_quantity}
                                                onChange={(event) => handleQuantityChange(mapping.product_id, parseFloat(event.target.value))}
                                            />
                                        </td>
                                        {isEditable ? (
                                            <td style={{ padding: '10px' }}>
                                                <button onClick={() => handleDeleteServiceMapping(mapping.product_id, selectedServiceId)} style={{backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}}>
                                                    Delete
                                                </button>
                                            </td>
                                        )
                                        :
                                        (
                                        <td style={{ padding: '10px' }}>
                                            <button onClick={async () => await handleDeleteServiceMappingPermanent(mapping.product_id, selectedServiceId)} style={{backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}}>
                                                Delete
                                            </button>
                                        </td>
                                        )
                                        }
                                    </tr>
                                    );
                                })}
                                {!serviceMappings && (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }}>No service mappings available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <button
                            onClick={handleSubmitCloneProducts}
                            style={{
                                marginLeft: '8px',
                                marginRight: '8px',
                                backgroundColor: '#87D3D7',
                                width: '10%',
                                color: '#fff',
                                border: 'none',
                                padding: '8px 12px',
                                cursor: 'pointer',
                                borderRadius: '8px',
                                opacity: displayCloneServices.length > 0 ? 1 : 0.5, // Optionally adjust opacity
                                pointerEvents: displayCloneServices.length > 0 ? 'auto' : 'none', // Optionally disable pointer events
                                maxHeight: '40px',  // Limit the height of the button
                                overflow: 'hidden', // Ensure content doesn't overflow
                                display: 'inline-flex', // Ensures button is inline and respects height
                                alignItems: 'center', // Vertically center content
                                justifyContent: 'center', // Horizontally center content
                                fontWeight: 'bold',
                            }}
                        >
                            {
                                String.fromCharCode(8592)//backarrow
                            }
                        </button>
                        <div style={{ 
                                    display: 'flex', 
                                    justifyContent: 'left', 
                                    width: '45%', 
                                    flexDirection:'column',
                                    border: '2px solid #87D3D7', 
                                    maxHeight: '610px', // Set a fixed height
                                    overflowY: 'auto', // Enable vertical scrolling
                                    boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                                }}>
                            
                                <div style={{ 
                                    display: 'flex', 
                                    justifyContent: 'left', 
                                    flexDirection: 'column', 
                                    width: '100%', 
                                }}>
                                    <div style={{flexDirection:'row',display: 'flex'}}>
                                        <div style={{ fontStyle: 'italic', width: '50%', padding: '5px'}}>
                                            <Select
                                                defaultValue={{value: selectedService[0].service_name, label: selectedService[0].service_name}}
                                                name="services"
                                                options={uniqueServiceOptions.map(item => ({
                                                    value: item.service_name,
                                                    label: item.service_name
                                                })).sort((a, b) => a.label.localeCompare(b.label))}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.querySelector('body')}
                                                onChange={handleCloneServiceChange}
                                                display='flex'
                                                placeholder="Select Service"  // Placeholder text
                                            />
                                        </div>
                                        <div style={{fontStyle: 'italic', width: '50%',  padding: '5px'}}>
                                            <Select
                                                // defaultValue={[options[1], options[2]]}
                                                defaultValue={compareLocation}
                                                isMulti
                                                isClearable
                                                name="locations"
                                                options={uniqueLocationOptions
                                                    .map(location => ({ value: location, label: location }))
                                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.querySelector('body')}
                                                onChange={handleCloneLocationChange}
                                                display='flex'
                                                placeholder="Select Location"  // Placeholder text
                                            />
                                        </div>
                                    
                                </div>
                            </div>
                                {
                                    (displayCloneServices.length === 0 && compareLocation.length > 0) ?
                                    (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center', // Centers horizontally
                                        alignItems: 'center',     // Centers vertically
                                        }}>
                                        <div>No overlapping products are mapped to this service.</div>
                                    </div>
                                ) : <div></div>
                                }
                                {
                                (displayCloneServices.length > 0 ) ? (
                        
                        <div>
                            <table style={{  
                                    width: '100%', 
                                    borderCollapse: 'separate', 
                                    borderSpacing: 0, 
                                    position: 'relative', // Ensure positioning context

                                }}>
                                    <thead style={{
                                        position: 'sticky', // Stick the header
                                        top: 0, // Stick to the top of the table
                                        zIndex: 1 // Ensure it stays above the tbody rows
                                    }}>
                                        <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Product ID </th>
                                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Product Name </th>
                                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Location</th>
                                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service ID (Location)</th>
                                            <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service Name  (Location)</th>
                                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center' }}>Usage Quantity</th>
                                            <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center' }}>
                                                <input 
                                                    checked={selectAllChecked}
                                                    type="checkbox" 
                                                    onChange={(e) => handleSelectAll(e.target.checked)}
                                                    style={{ width: '15px', height: '15px' }}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayCloneServices.length > 0 && displayCloneServices.map((mapping, index) => {
                                            return (
                                                <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                                    <td style={{ padding: '10px', }}>{mapping.product_id}</td>
                                                    <td style={{ padding: '10px', }}>
                                                        {displayCloneServices.find(item => item.product_id === mapping.product_id)?.product_name || ''}
                                                    </td>
                                                    <td style={{ padding: '10px', }}>{mapping.location_name}</td>
                                                    <td style={{ padding: '10px', }}>{mapping.compare_service_id}</td>
                                                    <td style={{ padding: '10px', }}>
                                                        {displayCloneServices.find(item => item.compare_service_id === mapping.compare_service_id)?.compare_service_name || ''}
                                                    </td>
                                                    
                                                    <td style={{ padding: '10px', textAlign: 'center'  }}>{mapping.usage_quantity}</td>
                                                    <td style={{ padding: '10px', textAlign: 'center' }}> 
                                                        <input
                                                            type="checkbox"
                                                            id="bulkCheckbox"
                                                            checked={mapping.selected}
                                                            onChange={handleSelectRow(mapping.product_id, mapping.compare_service_id)}
                                                            style={{ width: '15px', height: '15px' }} // Adjust the scale and size as needed
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {!serviceMappings && (
                                            <tr>
                                                <td colSpan="3" style={{ textAlign: 'center' }}>No service mappings available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table></div>
                            
                        ) : <div>
                            </div>
                            }  
                        </div> 
                </div>
                <br/>
                <button onClick={serviceMappings && handleSubmit} 
                        style={{ 
                            backgroundColor: '#87D3D7', 
                            color: '#fff', 
                            border: 'none', 
                            padding: '8px 12px', 
                            cursor: 'pointer', 
                            borderRadius: '8px',
                            opacity: serviceMappings ? 1 : 0.5, // Optionally adjust opacity
                        }}
                        >
                    Submit
                </button>
            </div>
        );
    };
    
    // Page for mapping serviecs from products
    const serviceMappingFromProduct = () => {
        const serviceMappings = selectedServiceMapping[0];
        const displayCloneServices = compareLocation.length === 0 ? cloneServices : cloneServices.filter(obj => compareLocation.includes(obj.location_name));
        const uniqueLocationOptions = Array.from(new Set(cloneServices.map(item => item.location_name)));

        const groupedData = displayCloneServices.reduce((acc, obj) => {                
            if (!acc[obj.service_id]) {
                acc[obj.service_id] = { total: 0, count: 0};
            }
            acc[obj.service_id].total += obj.usage_quantity;
            acc[obj.service_id].count += 1;
            return acc;
        }, {});
        const handleBulkUsageQuantityChange = (event) => {
            setBulkUsageQuantity(event.target.value);
        };
    
        const handleBulkCheckboxChange = (event) => {
            setBulkCheckboxChecked(event.target.checked);
            if (!event.target.checked){
                setBulkUsageQuantity(0);
            }
            else {
                setBulkPercentChecked(false);
                setBulkUsagePercent(1);
            }
        };
        
        const handleBulkUsagePercentChange = (event) => {
            setBulkUsagePercent(event.target.value);
        };
    
        const handleBulkPercentCheckboxChange = (event) => {
            setBulkPercentChecked(event.target.checked);
            if (!event.target.checked){
                setBulkUsagePercent(1);
            }
            else {
                setBulkCheckboxChecked(false);
                setBulkUsageQuantity(0);
            }
        };

        const handleSelectRow = (service_id) =>  (event) =>{
            const newCloneServices = [...cloneServices];

            const index = newCloneServices.findIndex(row => row.compare_service_id === service_id);

            newCloneServices[index].selected = !newCloneServices[index].selected
            
            setCloneServices(newCloneServices);
            setSelectAllChecked(false);
        };

        const handleSelectAll = (isChecked) => {
            const newCloneServices = [...cloneServices].map(row => ({
                ...row,
                selected: isChecked ? true : false
            }));;
            setSelectAllChecked(!selectAllChecked);
            setCloneServices(newCloneServices);
            
        };

        const handleSubmitCloneService = () => {
            // Locations currently being displayed
            const displayCloneServices = compareLocation.length === 0 
                ? cloneServices.filter(obj => obj.selected) 
                : cloneServices.filter(obj => compareLocation.includes(obj.location_name) && obj.selected);
            
            // Group data on service_id for aggregating similar services from different locations.
            const groupedData = displayCloneServices.reduce((acc, obj) => {                
                if (!acc[obj.service_id]) {
                    acc[obj.service_id] = { total: 0, count: 0};
                }
                acc[obj.service_id].total += obj.usage_quantity;
                acc[obj.service_id].count += 1;
                return acc;
            }, {});

            // Calculate the usage quanitites of new service usage.
            const averages = Object.keys(groupedData).map(key => {
                let usage = groupedData[key].total / groupedData[key].count;
                
                // Apply bulk changes
                if (bulkPercentChecked) {
                    usage *= bulkUsagePercent;
                }
                if (bulkCheckboxChecked) {
                    usage = bulkUsageQuantity;
                } 

                return {
                    service_id: parseInt(key, 10),
                    usage_quantity: usage,
                    product_id: selectedProduct[0].product_id
                };
            });

            const combinedMap = new Map();
            if (overwriteChecked){
                // Update will overwrite original quantity where keys are equal.
                selectedServiceMapping[0].forEach(obj => combinedMap.set(obj.service_id, obj));
                averages.forEach(obj => combinedMap.set(obj.service_id, obj));
            }
            else{
                // Original will overwrite update quantity where keys are equal.
                averages.forEach(obj => combinedMap.set(obj.service_id, obj));
                selectedServiceMapping[0].forEach(obj => combinedMap.set(obj.service_id, obj));
            }
            // Convert map values back to an array
            const combinedServices = Array.from(combinedMap.values());
            
            setSelectedServiceMapping([
                    combinedServices.sort((a, b) => a.service_id - b.service_id),
                    ...selectedServiceMapping.slice(1) // TODO: Maybe not necessary
            ])
        };

        const handleCompareProductChange = async (selected) => {
            setCompareProduct({'product_name':selected.label, 'product_id':selected.value}); 
        };

        const handleOverwriteChange = (event) => {
            setOverwriteChecked(event.target.checked);
        };
    
        const handleCategoryChange = (event) => {
            setSelectedCategory(event.target.value);
        };
    
        // Function to apply bulk usage quantity to all mappings
        const applyBulkUsageQuantity = () => {
            let existingMapping = [];
            const newMappings = [];
    
            if (overwriteChecked) {

                // Filtering serviceCurrentData based on selected service_ids
                if (bulkCheckboxChecked){
                    existingMapping = selectedServiceMapping[0]
                    .map(service => ({
                        product_id: service.product_id,
                        service_id: service.service_id,
                        usage_quantity: (serviceCurrentData.filter(service_general => service_general.service_id === service.service_id)[0].service_cat === selectedCategory || selectedCategory === '') ? parseFloat(bulkUsageQuantity) : service.usage_quantity
                    }));
                }
                else if (bulkPercentChecked) {
                    existingMapping = selectedServiceMapping[0]
                    .map(service => ({
                        product_id: service.product_id,
                        service_id: service.service_id,
                        usage_quantity: (serviceCurrentData.filter(service_general => service_general.service_id === service.service_id)[0].service_cat === selectedCategory || selectedCategory === '') ? parseFloat(bulkUsagePercent) * service.usage_quantity : service.usage_quantity
                    }));
                }
            }
            for (const service of serviceCurrentData) {
                if (!overwriteChecked && serviceMappings){
                    existingMapping = serviceMappings.find(
                        m => m.product_id === selectedProductId && m.service_id === service.service_id
                    );
                }
    
                if ((service.service_cat === selectedCategory || selectedCategory === '')) {
                    newMappings.push({
                        product_id: selectedProductId,
                        service_id: service.service_id,
                        usage_quantity: parseFloat(bulkUsageQuantity)
                    });
                }
            }

            const combinedMap = new Map();
            if (overwriteChecked && bulkCheckboxChecked){
                // Update will overwrite original quantity where keys are equal.
                selectedServiceMapping[0].forEach(obj => combinedMap.set(obj.service_id, obj));
                newMappings.forEach(obj => combinedMap.set(obj.service_id, obj));
            }
            else if (overwriteChecked && bulkPercentChecked){
                existingMapping.forEach(obj => combinedMap.set(obj.service_id, obj));
            }
            else if (!overwriteChecked && bulkCheckboxChecked) {
                // Update will keep original and overwrite new when keys are equal.
                newMappings.forEach(obj => combinedMap.set(obj.service_id, obj));
                selectedServiceMapping[0].forEach(obj => combinedMap.set(obj.service_id, obj));
            }
            // Convert map values back to an array
            const combinedServices = Array.from(combinedMap.values());
            setSelectedServiceMapping([combinedServices.sort((a, b) => a.service_id - b.service_id)]);
        };

        return (
            <div>
                <h3>{selectedProduct[0].product_name} - Service Mappings</h3>
                <br/>
                <div>
                    <div style={{flexDirection:'row'}}>
                        <input
                            type="checkbox"
                            id="bulkCheckbox"
                            checked={bulkCheckboxChecked}
                            onChange={handleBulkCheckboxChange}
                        />
                        <label htmlFor="bulkCheckbox" style={{marginRight: '10px'}}>Bulk quantity: </label>
                        <input
                            type="number"
                            value={bulkUsageQuantity}
                            onChange={handleBulkUsageQuantityChange}
                            disabled={!bulkCheckboxChecked}
                        />
                       
                        <input
                            type="checkbox"
                            id="bulkPercentBox"
                            checked={bulkPercentChecked}
                            onChange={handleBulkPercentCheckboxChange}
                        />
                        <label htmlFor="bulkCheckbox" style={{marginRight: '10px'}}>Bulk factor: </label>
                        <input
                            type='number' 
                            min={0} 
                            step={0.1}
                            value={bulkUsagePercent}
                            onChange={handleBulkUsagePercentChange}
                            disabled={!bulkPercentChecked}
                        />
                    </div>
                    
                    <br/>
                    <input
                        type="checkbox"
                        id="overwriteCheckbox"
                        checked={overwriteChecked}
                        onChange={handleOverwriteChange}
                    />
                    <label htmlFor="overwriteCheckbox">Overwrite </label>
                    <br/>
                    <br/>
                    <select id="categorySelect" value={selectedCategory} onChange={handleCategoryChange}
                        style={{ marginLeft: '50px', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
                    >
                        <option value="">All Categories</option>
                        {uniqueServiceCategories.sort().map((category, index) => (
                            <option key={index} value={category}>{category}</option>
                        ))}
                    </select>              
                </div>
                <br/>
                <button 
                    onClick={() => (bulkCheckboxChecked || (bulkPercentChecked && overwriteChecked)) && applyBulkUsageQuantity()} 
                    style={{ 
                        marginLeft: '50px',
                        backgroundColor: '#87D3D7', 
                        color: '#fff', 
                        border: 'none', 
                        padding: '8px 12px', 
                        cursor: 'pointer', 
                        borderRadius: '8px',
                        opacity: bulkCheckboxChecked || (bulkPercentChecked && overwriteChecked) ? 1 : 0.5, // Optionally adjust opacity
                        pointerEvents: bulkCheckboxChecked || (bulkPercentChecked && overwriteChecked) ? 'auto' : 'none' // Optionally disable pointer events
                    }}
                >
                    Apply
                </button>
                <br/>
                <br/>
                <div style={{display:'flex'}}>
                    <div style={{ 
                        border: '2px solid #87D3D7', 
                        display: 'flex', 
                        justifyContent: 'left', 
                        flexDirection: 'column', 
                        width: '47.5%', 
                        maxHeight: '610px', // Set a fixed height
                        overflowY: 'auto', // Enable vertical scrolling
                        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                    }}>
                        <table style={{  
                            width: '100%', 
                            borderCollapse: 'separate', 
                            borderSpacing: 0, 
                            position: 'relative' // Ensure positioning context
                        }}>
                            <thead style={{
                                position: 'sticky', // Stick the header
                                top: 0, // Stick to the top of the table
                                zIndex: 1 // Ensure it stays above the tbody rows
                            }}>
                                <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service ID</th>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service Name</th>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Usage Quantity</th>
                                    <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serviceMappings && serviceMappings.map((mapping, index) => {
                                    const difference = getPercentageChange(mapping.usage_quantity, groupedData, mapping.service_id)
                                    // Check if the mapping is not in initialServicesMapping
                                    const isImportant = highlightedRows.includes(mapping.service_id);
                                    
                                    const isEditable = initialServicesMapping[0] ? (!initialServicesMapping[0].some(initialMapping =>
                                        initialMapping.product_id === selectedProductId &&
                                        initialMapping.service_id === mapping.service_id
                                    )) : (true);
                                    const rowStyle = {
                                        fontSize: '13px',
                                        backgroundColor: isImportant ? '#FFD580' : index % 2 === 0 ? '#ebf4f5' : '#ffffff', // Highlight color
                                    };
                                
                                    return (
                                        <tr key={index} style={rowStyle} >
                                            <td style={{ padding: '10px', }}>{mapping.service_id}</td>
                                            <td style={{ padding: '10px', }}>
                                                {`${serviceCurrentData.find(item => item.service_id === mapping.service_id)?.service_name || ''}  ${isImportant && difference ? `(${difference > 0?'+':''}${difference}% average)` : ''}`} 
                                            </td>
                                            <td style={{ padding: '10px', }}>
                                                <input
                                                    type="number"
                                                    value={mapping.usage_quantity}
                                                    onChange={(event) => handleQuantityChangeProduct(mapping.service_id, parseFloat(event.target.value))}
                                                />
                                            </td>
                                            {isEditable ? (
                                                <td style={{ padding: '10px' }}>
                                                    <button onClick={() => handleDeleteServiceMapping(selectedProductId, mapping.service_id)} style={{backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}}>
                                                        Delete
                                                    </button>
                                                </td>
                                            )
                                            :
                                            (
                                            <td style={{ padding: '10px' }}>
                                                <button onClick={async () => await handleDeleteServiceMappingPermanent(selectedProductId, mapping.service_id)} style={{backgroundColor: '#F49C7D', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}}>
                                                    Delete
                                                </button>
                                            </td>
                                            )
                                            }
                                        </tr>
                                    );
                                })}
                                {!serviceMappings && (
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: 'center' }}>No service mappings available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <button
                        onClick={handleSubmitCloneService}
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            backgroundColor: '#87D3D7',
                            width: '5%',
                            color: '#fff',
                            border: 'none',
                            padding: '8px 12px',
                            cursor: 'pointer',
                            borderRadius: '8px',
                            opacity: displayCloneServices.length > 0 ? 1 : 0.5, // Optionally adjust opacity
                            pointerEvents: displayCloneServices.length > 0 ? 'auto' : 'none', // Optionally disable pointer events
                            maxHeight: '40px',  // Limit the height of the button
                            overflow: 'hidden', // Ensure content doesn't overflow
                            display: 'inline-flex', // Ensures button is inline and respects height
                            alignItems: 'center', // Vertically center content
                            justifyContent: 'center', // Horizontally center content
                            fontWeight: 'bold',
                        }}
                    >
                        {
                            String.fromCharCode(8592)//backarrow
                        }
                    </button>
                    <div style={{ 
                                display: 'flex', 
                                justifyContent: 'left', 
                                width: '47.5%', 
                                flexDirection:'column',
                                border: '2px solid #87D3D7', 
                                maxHeight: '610px', // Set a fixed height
                                overflowY: 'auto', // Enable vertical scrolling
                                boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                            }}>
                        
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'left', 
                                flexDirection: 'column', 
                                width: '100%', 
                            }}>
                                <div style={{flexDirection:'row',display: 'flex'}}>
                                    <div style={{ fontStyle: 'italic', width: '50%', padding: '5px'}}>
                                        <Select
                                            defaultValue={{value: selectedProduct[0].product_id,label: `${ selectedProduct[0].product_name} - ${ selectedProduct[0].product_description}` }}
                                            name="products"
                                            options={inventoryCurrentData.map(item => ({
                                                value: item.product_id,
                                                label: item.product_description
                                                        ? `${item.product_name} - ${item.product_description}`
                                                        : item.product_name 
                                            })).sort((a, b) => a.label.localeCompare(b.label))}
    
                                            classNamePrefix="select"
                                            menuPortalTarget={document.querySelector('body')}
                                            onChange={handleCompareProductChange}
                                            display='flex'
                                            placeholder="Select Product" 
                                        />
                                    </div>
                                    <div style={{fontStyle: 'italic', width: '50%',  padding: '5px'}}>
                                        <Select
                                            // defaultValue={[options[1], options[2]]}
                                            defaultValue={compareLocation}
                                            isMulti
                                            isClearable
                                            name="locations"
                                            options={uniqueLocationOptions
                                                    .map(location => ({ value: location, label: location }))
                                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.querySelector('body')}
                                            onChange={handleCloneLocationChange}
                                            display='flex'
                                            placeholder="Select Location"  // Placeholder text
                                        />
                                    </div>
                                
                            </div>
                        </div>
                            
                            {
                                (displayCloneServices.length === 0 && compareLocation.length > 0) ?
                                (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Centers horizontally
                                    alignItems: 'center',     // Centers vertically
                                    }}>
                                    <div>No overlapping services are mapped to this product.</div>
                                </div>
                            ) : <div></div>
                            }
                            {
                            (displayCloneServices.length > 0 ) ? (
                    
                    <div>
                        <table style={{  
                                width: '100%', 
                                borderCollapse: 'separate', 
                                borderSpacing: 0, 
                                position: 'relative',
                                tableLayout: 'fixed'
                            }}>
                                <thead style={{
                                    position: 'sticky', // Stick the header
                                    top: 0, // Stick to the top of the table
                                    zIndex: 1 // Ensure it stays above the tbody rows
                                }}>
                                    <tr style={{ fontSize: '13px', backgroundColor: 'white' }}>
                                        <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service ID</th>
                                        <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service Name</th>
                                        <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Location</th>
                                        <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service ID (Location)</th>
                                        <th style={{ borderBottom:'2px solid black', padding: '10px'}}>Service Name  (Location)</th>
                                        <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center' }}>Usage Quantity</th>
                                        <th style={{ borderBottom:'2px solid black', padding: '10px', textAlign: 'center' }}>
                                            <input 
                                                checked={selectAllChecked}
                                                type="checkbox" 
                                                onChange={(e) => handleSelectAll(e.target.checked)}
                                                style={{ width: '15px', height: '15px' }}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayCloneServices.length > 0 && displayCloneServices.map((mapping, index) => {
                                        return (
                                            <tr key={index} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                                                <td style={{ padding: '10px', }}>{mapping.service_id}</td>
                                                <td style={{ padding: '10px', }}>
                                                    {displayCloneServices.find(item => item.service_id === mapping.service_id)?.service_name || ''}
                                                </td>
                                                <td style={{ padding: '10px', }}>{mapping.location_name}</td>
                                                <td style={{ padding: '10px', }}>{mapping.compare_service_id}</td>
                                                <td style={{ padding: '10px', }}>
                                                    {displayCloneServices.find(item => item.compare_service_id === mapping.compare_service_id)?.service_name || ''}
                                                </td>
                                                
                                                <td style={{ padding: '10px', textAlign: 'center'  }}>{mapping.usage_quantity}</td>
                                                <td style={{ padding: '10px', textAlign: 'center' }}> 
                                                    <input
                                                        type="checkbox"
                                                        id="bulkCheckbox"
                                                        checked={mapping.selected}
                                                        onChange={handleSelectRow(mapping.compare_service_id)}
                                                        style={{ width: '15px', height: '15px' }} // Adjust the scale and size as needed
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {!serviceMappings && (
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: 'center' }}>No service mappings available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table></div>
                        
                    ) : <div>
                        </div>
                        }  
                    </div>      
                </div>
                <br/>
                <button onClick={serviceMappings && handleSubmit} 
                        style={{ 
                            backgroundColor: '#87D3D7', 
                            color: '#fff', 
                            border: 'none', 
                            padding: '8px 12px', 
                            cursor: 'pointer', 
                            borderRadius: '8px',
                            opacity: serviceMappings ? 1 : 0.5, // Optionally adjust opacity
                        }}
                        >
                    Submit
                </button>
            </div>
        );
    };
    
    /**********************/
    /* LOGIC FLOW CONTROL */
    /**********************/
    const completeMappingFlow = () => {
        return (<>
            {!finalDone && serviceMappingDone  && (
                <div style={{marginLeft: '50px'}}>
                    {loading ? (
                        <span>Uploading...</span>
                    ) : (
                        <>
                            Do you want to continue?
                            <br/>
                            <br/>
                            <button 
                                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                onClick={async () => {
                                    setLoading(true);
                                    await submitMappingDataGeneral(); 
                                    resetAll();
                                    setLoading(false);
                                }}
                            >
                                Yes
                            </button>
                            
                            <button 
                                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                onClick={async () => {
                                    setLoading(true);
                                    await submitMappingDataGeneral(); 
                                    setFinalDone(true);
                                    setLoading(false);
                                }}
                            >
                                No
                            </button>
                        </>
                    )}
                </div>
            )}


            {finalDone  && (
                <div style={{marginLeft: '50px'}}>
                    <div>Done!</div>
                    <br/>
                    <button 
                        style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                        onClick={resetAll}
                    >
                        Update Service Mapping
                    </button>
                </div>
            )}</>
        );
    }

    const existingServiceFlow = () => {
        return (
            <>
                {!serviceDone && (
                    <ServiceTable services={serviceCurrentData} onClick={handleAddService}/>
                )}

                    {!serviceMappingDone && serviceDone && (
                    <>
                        {serviceMappingFromService()}
                        <br/>
                        <br/>
                        <ProductTable products={inventoryCurrentData} onClick={handleAddInventoryProductMapping}/>
                        <br/>
                        <button 
                            onClick={() => setNewProductMapping(true)}
                            disabled={newInventoryMapping}
                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            New Product
                        </button>
                        <button 
                            onClick={() => setNewInventoryMapping(true)}
                            disabled={newProductMapping}
                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            New Inventory
                        </button>
                        <button 
                            onClick={() => {
                                setNewProductMapping(false);
                                setNewInventoryMapping(false);
                                setProductDone(false);
                                setPriceDone(false);
                                setInventoryDone(false);
                                setLoading(false);
                                }
                            }
                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            Cancel
                        </button>
                        <br/>
                        <br/>
                        {newProductMapping &&
                            <>
                                {!productDone && (
                                    <ProductForm />
                                )}

                                {!priceDone && productDone && (
                                    <PriceForm />
                                )}

                                {!inventoryDone && priceDone && (
                                    <InventoryForm />
                                )}
                                
                                {inventoryDone && (
                                    <>
                                        {loading ? (
                                            <span>Uploading...</span>
                                        ) : (
                                        <button 
                                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                            onClick={async () => {
                                                setLoading(true);
                                                await submitMappingDataProductOrService();
                                                setNewProductMapping(false);
                                                setProductDone(false);
                                                setPriceDone(false);
                                                setInventoryDone(false);
                                                setLoading(false);
                                                setSelectedProduct([]);
                                            }}
                                        >
                                            Upload product
                                        </button>
                                        )}
                                    </>
                                )}
                            </>
                        }

                        {newInventoryMapping &&
                            <>
                                {!productDone && (
                                    <ProductTable products={productsData.
                                        filter(item1 => !inventoryCurrentData.some(item2 => item2.product_id === item1.product_id))} 
                                        onClick={handleAddProductToInventory}/>
                                )}

                                {!priceDone && productDone && (
                                    <PriceForm />
                                )}

                                {!inventoryDone && priceDone && (
                                    <InventoryForm />
                                )}
                                
                                {inventoryDone && (
                                    <>
                                        {loading ? (
                                            <span>Uploading...</span>
                                        ) : (
                                        <button 
                                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                            onClick={async () => {
                                                setLoading(true);
                                                await submitMappingDataProductOrService();
                                                setNewInventoryMapping(false);
                                                setProductDone(false);
                                                setPriceDone(false);
                                                setLoading(false);
                                                setSelectedProduct([]);
                                                setSelectedPrice([]);
                                                setSelectedInventory([]);
                                            }}
                                        >
                                            Upload inventory
                                        </button>
                                        )}
                                    </>
                                )}
                            </>
                        }
                    </>
                )}
            </>
        );
    }

    const newServiceFlow = () => {
        return (
            <>
                {!serviceDone && (
                    <ServiceForm />
                )}
                {!serviceMappingDone && serviceDone && (
                    existingServiceFlow()
                )}
            </>
        );
    }

    const existingProductFlow = () => {
        return (
            <>
                {!productDone && (
                    <ProductTable products={inventoryCurrentData} onClick={handleAddInventoryProduct}/>

                )}

                {!serviceMappingDone && productDone && (
                    <>
                        {serviceMappingFromProduct()}
                        <br/>
                        <br/>
                        <ServiceTable services={serviceCurrentData} onClick={handleAddServiceToMapping}/>
                        <br/>
                        <button 
                            onClick={() => setNewServiceMapping(true)}
                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            New Service
                        </button>
                        <button 
                            onClick={() => {
                                setNewServiceMapping(false);
                                setServiceDone(false);
                                setLoading(false);
                                }
                            }
                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                        >
                            Cancel
                        </button>
                        <br/>
                        <br/>
                        {newServiceMapping &&
                            <>
                                {!serviceDone && (
                                    <ServiceForm />
                                )}

                                {serviceDone && (
                                    <>
                                        {loading ? (
                                            <span>Uploading...</span>
                                        ) : (
                                        <button 
                                            style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                            onClick={async () => {
                                                setLoading(true);
                                                await submitMappingDataProductOrService();
                                                setNewServiceMapping(false);
                                                setServiceDone(false);
                                                setLoading(false);
                                                setSelectedService([]);
                                            }}
                                        >
                                            Upload service
                                        </button>
                                        )}
                                    </>
                                )}
                            </>
                        }
                        {/* <CurrentServicesMapping /> */}
                    </>
                )}

                
            </>
        );
    }

    const newProductFlow = () => {
        return (
                <>
                    {!productDone && (
                        <ProductForm />
                    )}

                    {!priceDone && productDone && (
                        <PriceForm />
                    )}

                    {!inventoryDone && priceDone && (
                        <InventoryForm />
                    )}


                    {!serviceMappingDone && inventoryDone && (
                        existingProductFlow()
                    )}
                </>
        );
    }

    const removeProductFlow = () => {
        return (
            <>
                { !serviceMappingDone  &&
                <>
                    Remove from inventory?
                                <br/>
                                <br/>
                                <button 
                                    style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                    onClick={ async () => {
                                        await handleDeleteProductInventory()
                                        setServiceMappingDone(true);

                                    }}
                                >
                                    Yes
                                </button>
                                
                                <button 
                                    style={{ marginRight: '20px', backgroundColor: '#ff4d4d', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                    onClick={ () => {
                                        setServiceMappingOrderStatus('');
                                    }}
                                >
                                    No
                                </button>
                </>
                }
            </>
        )
    }

    // Logical flow for going through a mapping.
    const mappingFlow = () => {
        return(
            <>
                { serviceMappingOrderStatus == 'New Service' && 
                    newServiceFlow()
                }
                { serviceMappingOrderStatus == 'Service' && 
                    existingServiceFlow()
                }
                {serviceMappingOrderStatus == 'New Product' && 
                    newProductFlow()
                }
                {serviceMappingOrderStatus == 'Product' &&   
                    existingProductFlow()
                }
                {serviceMappingOrderStatus == 'Remove Product' &&
                    removeProductFlow()
                }
                {completeMappingFlow()}
            </>
        );
    }
    
    return (
        <div ref={contentRef} style={{ textAlign: 'left'}}>
            <ToastContainer />
            <h1 style={{ marginTop: '27px',  fontSize: '40px', fontWeight: 'bold', color: '#87D3D7'}}>Mapping</h1>

            <select
                id="location"
                value={selectedLocation}
                onChange={(e) => {resetAll(); setSelectedLocation(e.target.value);}}
                style={{ padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', width: '300px' }}
            >
                {locations.sort().map((item, index) => (
                    <option key={index} value={item.location_name}>{item.location_name}</option>
                ))}
            </select>
            
            <br/>
            <br/>
            <br/>

            <div>         
                <span 
                    onClick={() => handleTabClick('ServiceMapping')}
                    style={{
                    marginLeft: '50px',
                    color: '#000000',
                    borderBottom: selectedTab === 'ServiceMapping' ? ' 2px solid black' : ' 2px solid transparent',
                    fontSize: '25px', // Updated font size
                    fontWeight: 'bold', // Added font weight
                    cursor: 'pointer' // Set cursor to pointer
                    }}
                >
                    Edit Service Mapping
                </span>

                <span 
                    onClick={() => handleTabClick('Web')}
                    style={{
                    marginLeft: '50px',
                    color: '#000000',
                    borderBottom: selectedTab === 'Web' ? ' 2px solid black' : ' 2px solid transparent',
                    fontSize: '25px', // Updated font size
                    fontWeight: 'bold', // Added font weight
                    cursor: 'pointer' // Set cursor to pointer
                    }}
                >
                    Edit Web Product
                </span>

                <span 
                    onClick={() => handleTabClick('Errors')}
                    style={{
                    marginLeft: '50px',
                    color: '#000000',
                    borderBottom: selectedTab === 'Errors' ? ' 2px solid black' : ' 2px solid transparent',
                    fontSize: '25px', // Updated font size
                    fontWeight: 'bold', // Added font weight
                    cursor: 'pointer' // Set cursor to pointer
                    }}
                >
                    Review
                </span>
            </div>

            <br/>
            <br/>
           
            {selectedTab === 'ServiceMapping' && (
                <>
                    { true && (
                        <>
                            {serviceMappingOrderStatus == '' &&

                                <div style={{marginLeft: '50px'}}>
                                    <button onClick={() => setServiceMappingOrderStatus('New Product')}
                                        style={{ fontWeight: 'bold', marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                    >
                                        Map a new product to service(s)
                                    </button>
                                    <br/>
                                    <br/>
                                    <button onClick={() => setServiceMappingOrderStatus('New Service')}
                                        style={{ fontWeight: 'bold',  marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                    >
                                        Map a new service to product(s)
                                    </button>
                                    <br/>
                                    <br/>
                                    <button onClick={() => setServiceMappingOrderStatus('Product')}
                                        style={{ fontWeight: 'bold',  marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                    >
                                        Map an existing product to service(s)
                                    </button>
                                    <br/>
                                    <br/>
                                    <button onClick={() => setServiceMappingOrderStatus('Service')}
                                        style={{ fontWeight: 'bold',  marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                    >
                                        Map an existing service to product(s)
                                    </button>
                                </div>
                            }
                            {mappingFlow()}   
                        </>
                    )} 
                </>
            )}

            { selectedTab === 'Web' && ( 
                <>
                    {!webProductDone && (
                        <>
                            <ProductTable products={webProductsData.filter(
                                item =>(selectedLocation === '' || item.location_name.toLowerCase() === selectedLocation.toLowerCase())
                                )}  onClick={handleAddWebProduct} onlySearch={true}/>
                            </>
                    )}
                    {!productDone && webProductDone && (
                        <ProductForm productData = {selectedWebProduct[0]} />
                    )}

                    {!priceDone && productDone && (
                        <PriceForm priceData={selectedWebPrice[0]} />
                    )}

                    {!inventoryDone && priceDone && (
                        <InventoryForm inventoryData={selectedWebInventory[0]}/>
                    )}

                    {!serviceMappingDone && inventoryDone && (
                        <>
                            {serviceMappingFromProduct()}
                            <br/>
                            <br/>
                            <ServiceTable services={serviceCurrentData} onClick={handleAddServiceToMapping}/>
                        </>
                    )}


                    {!finalDone && serviceMappingDone && (
                        <div style={{ marginLeft: '50px' }}>
                            {loading ? (
                                <span>Uploading...</span>
                            ) : (
                                <>
                                    Update other web product?
                                    <br />
                                    <br />
                                    <button
                                        style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                        onClick={async () => {
                                            setLoading(true);
                                            await submitMappingDataGeneral();
                                            resetAll();
                                            setLoading(false);
                                        }}
                                    >
                                        Yes
                                    </button>

                                    <button
                                        style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }}
                                        onClick={async () => {
                                            setLoading(true);
                                            await submitMappingDataGeneral();
                                            setFinalDone(true);
                                            setLoading(false);
                                        }}
                                    >
                                        No
                                    </button>
                                </>
                            )}
                        </div>
                    )}


                    {finalDone  && (
                        <div style={{marginLeft: '50px'}}>
                            <div>Done!</div>
                            <br/>
                            <button 
                                style={{ marginRight: '20px', backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px' }} 
                                onClick={resetAll}
                            >
                                Edit Web Product
                            </button>
                        </div>
                    )}
                </>
            )}

            { selectedTab === 'Errors' && ( 
                <>  
                    {((serviceMappingOrderStatus == '')) && 
                        <ErrorTable errors={getFilteredData()} timeframeCallback={setSelectedTimeframe}/>
                    }
                    {mappingFlow()}  
                </>
            )}
            <br/>
            <br/>
        </div>
    );
}

export default Mapping;
