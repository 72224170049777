import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const ActionModal = ({ isOpen, onClose, orderDetails, openDeliveryIssueModal, openReturnProductModal, submitFeedback }) => {
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [disputeType, setDisputeType] = useState('');

  // Emoji options
  const emojis = [
    { id: 1, icon: '😠', label: 'Angry' },
    { id: 2, icon: '😐', label: 'Neutral' },
    { id: 3, icon: '🙂', label: 'Satisfied' },
    { id: 4, icon: '😊', label: 'Happy' },
    { id: 5, icon: '😍', label: 'Delighted' }
  ];

  
  useEffect(() => {
    if (isOpen) {
      setSelectedEmoji(orderDetails?.feedback_value || null);
    }
  }, [isOpen, orderDetails]);
  

  // Handle emoji selection
  const handleEmojiClick = (emojiId) => {
    setSelectedEmoji(emojiId);
  };

  // Handle feedback submission
  const handleSubmit = () => {
    if (selectedEmoji === null) {
      toast.error('Please select a rating before submitting');
      return;
    }

    if (selectedEmoji !== 5 && !feedbackText) {
      toast.error('Please provide feedback on how we can do better');
      return;
    }

    const feedbackData = {
      orderId: orderDetails?.order_id,
      rating: selectedEmoji,
      comments: feedbackText ? feedbackText : orderDetails?.client_comment
    };
    submitFeedback(feedbackData);
    setSelectedEmoji(null);
    setFeedbackText('');
    setDisputeType('');
    onClose();
  };

  const handleModalClose = () => {
    setSelectedEmoji(null);
    setFeedbackText('');
    onClose();
    setDisputeType('');
  };

  const handleOpenDispute = () => {
    if (disputeType === 'delivery') {
      openDeliveryIssueModal();
    } else if (disputeType === 'return') {
      openReturnProductModal();
    } else {
      toast.error('Please select a dispute type');
    }
    setDisputeType('');
  };

  return (
    <Modal
      overlayClassName="white-modal-overlay"
      className="white-modal"
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      style={{
        content: {
          width: '75vw',
          height: '80vh',
          display: 'flex',
          flexDirection: 'row',
          border: '0.15rem solid #87D3D7'
        },
      }}
    >
      <div style={{ width: '50%', padding: '2%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Give Us Your Feedback</h1>
        <div style={{ textAlign: 'center', width: '100%', marginTop: '5%' }}>
          <h2>How was the order for {orderDetails?.product_name}!</h2>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginBottom: '2rem' }}>
            {emojis.map((emoji) => (
              <button
                key={emoji.id}
                onClick={() => handleEmojiClick(emoji.id)}
                style={{
                  fontSize: '2rem',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  outline: 'none',
                  transform: selectedEmoji === emoji.id ? 'scale(1.2)' : 'scale(1)',
                  transition: 'transform 0.2s',
                  filter: selectedEmoji === emoji.id ? 'drop-shadow(0 0 0.5rem #31bac1)' : 'none'
                }}
                title={emoji.label}
              >
                {emoji.icon}
              </button>
            ))}
          </div>

          <h3>How can we do better?</h3>
          <textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder="Enter your feedback here..."
            style={{
              width: '80%',
              height: '5rem',
              padding: '0.5rem',
              borderRadius: '0.5rem',
              border: '0.1rem solid #87D3D7',
              resize: 'none',
              marginBottom: '2rem'
            }}
          />

        <div>
          <button
            className="action-button"
            onClick={handleSubmit}
            style={{
              padding: '0.5rem 1rem',
            }}
          >
            Submit
          </button>
        </div>
        </div>
      </div>

      <div style={{ marginTop: '5%', width: '0.1rem', height: '80%', backgroundColor: 'black' }}></div>

      <div style={{ width: '50%', padding: '2%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Dispute Resolution</h1>
        <h2 style={{ textAlign: 'center', marginTop: '10%' }}>
          Do you want to start a dispute?
        </h2>
        <select
          value={disputeType}
          onChange={(e) => setDisputeType(e.target.value)}
          style={{
            width: '60%',
            padding: '0.5rem',
            margin: '1rem 0',
            border: '0.1rem solid #87D3D7',
            borderRadius: '0.3rem'
          }}
        >
          <option value="">Select Dispute Type</option>
          <option value="delivery">Delivery Issue</option>
          <option value="return">Return Product</option>
        </select>
        <p style={{ textAlign: 'left', marginTop: '5%', padding: '1rem' }}>
          Medvelle’s return policy aligns with the policies set by our suppliers. We will make every effort to assist 
          you throughout the return process, adhering to the guidelines and conditions established by the respective supplier. Please note that 
          the final decision regarding returns is subject to the supplier’s approval and terms.
        </p>
        <button
          className="action-button"
          onClick={handleOpenDispute}
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#d9534f',
            color: 'white',
            border: 'none',
            borderRadius: '0.3rem',
            cursor: 'pointer',
            marginTop: '1rem'
          }}
        >
          Open Dispute
        </button>
      </div>
    </Modal>
  );
};

export default ActionModal;
