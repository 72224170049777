const ModalHeader = ({description, title}) => { 
    return (
        <div style={{flex: '0 1 auto', padding: '20px', borderBottom: '1px solid #ccc'}}>
            {description && (
                <>
                    <span style={{color: 'gray', fontSize: '20px'}}>{description}</span>
                    <br />
                </>
            )}
            <div style={{textAlign: !description ? 'center' : 'left'}}>
                <b style={{color: 'black', fontSize: '25px', }}>
                    {title}
                </b>
            </div>
        </div>
    );
};

export default ModalHeader;