import React, { useState, useEffect } from 'react';
import InfoPopup from '../../components/InfoPopup';
import Slider from '../../components/Slider';
import { toast, ToastContainer } from 'react-toastify';
import { fetchSpotcheckSettings, updateSpotcheckSettings } from '../../utils/settings/settingsUtils';

const SpotCheckSettings = ({locationName}) => {

    const [isDefaultSettings, setIsDefaultSettings] = useState(false);

    const defaultSettings = {
        count_frequency: 1,
        minimum_product_price: 15,
        minimum_order_price: 15,
        product_count: 12,
        start_date: new Date().toISOString().split('T')[0]
    };

    const [spotcheckSettings, setSpotcheckSettings] = useState(defaultSettings);

    const inventoryCountsContent = [
        <p key="inventory-1">Medvelle AI customizes SpotChecks based on your preferences, including frequency, number of products to check, and minimum product value.</p>,
        <p key="inventory-2">This allows you to streamline your inventory process, focusing only on the products that matter most to your business.</p>
    ];
    
    const orderCountsContent = [
        <p key="order-1">Medvelle AI initiates a SpotCheck for every delivery to ensure accuracy.</p>,
        <p key="order-2">You can set a minimum order price to reduce SpotChecks for lower-value orders.</p>
    ];

    const predefinedSteps = {
        count_frequency: [1, 10, 20, 30],
        minimum_product_price: [0, 100, 200, 300, 400, 500],
        product_count: [0, 20, 40, 60, 80, 100],
        minimum_order_price: [0, 200, 400, 600, 800, 1000],
    };

    const maxOrderPrice = 1000;
    const maxProductPrice = 500;
    const maxProductCount = 100;
    const maxDaysOccurrency = 30;

    const handleSliderChange = (key, value) => {        
        // Update the local state immediately
        setSpotcheckSettings({ ...spotcheckSettings, [key]: value });
    };
      
      // New function to handle mouse up event
    const handleSliderCommit = (key, value) => {            
        if (!isDefaultSettings){
            // Call updateSpotcheckSettings and show toast only on commit
            updateSpotcheckSettings({ ...spotcheckSettings, [key]: value }, locationName);
            toast.success('SpotCheck Settings updated!');
        }
    };

    const handleDateChange = (date) => {
        if (date && new Date(date) <= new Date()) { // Prevent selecting a future date
            setSpotcheckSettings({ ...spotcheckSettings, start_date: date || null });
            updateSpotcheckSettings({ ...spotcheckSettings, start_date: date || null }, locationName);
            toast.success('SpotCheck Settings updated!');
        }
    };

    const toggleSettings = async () => {
        setIsDefaultSettings(!isDefaultSettings);
        setSpotcheckSettings(defaultSettings);

        if (!isDefaultSettings){
            await updateSpotcheckSettings(defaultSettings, locationName);
            toast.success('SpotCheck Settings updated!');
        }
    };

    useEffect(() => {

        fetchSpotcheckSettings(setSpotcheckSettings, locationName, setIsDefaultSettings, defaultSettings);

    }, []); 

    return (
        <div>
            {/* Main Title */}
            <h1 style={{ marginTop: '35px', fontSize: '30px', fontWeight: 'bold', color: '#87D3D7' }}>
            SpotCheck Settings
            </h1>

            <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '10px' }}>Medvelle Default Settings</span>
                <label className="switch">
                    <input type="checkbox" checked={!isDefaultSettings} onChange={toggleSettings} />
                    <span className="slider round"></span>
                </label>
                <span style={{ marginLeft: '10px' }}>Custom Settings</span>
            </div>

            {/* Order Counts Section */}
            <section style={{ marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ marginTop: '15px', display: 'flex', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }}>
                    Delivery Validation
                    </h1>
                    <InfoPopup title="Delivery Validation" content={orderCountsContent} />
                </div>
                <p>
                    Medvelle will use SpotChecks to validate deliveries for orders over
                    <strong className='orange-text'> ${spotcheckSettings.minimum_order_price} </strong>.
                </p>
                <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                    {/* Minimum Order Price Slider */}
                    <li style={{ marginBottom: '15px' }}>
                    <Slider
                        value={spotcheckSettings.minimum_order_price}
                        isDisabled={isDefaultSettings}
                        min={0}
                        max={maxOrderPrice}
                        step={1}
                        marks={predefinedSteps.minimum_order_price.map(val => `$${val}`)}
                        onChange={(e) => handleSliderChange('minimum_order_price', Number(e.target.value))} // Update state on change
                        onChangeCommitted={(e) => handleSliderCommit('minimum_order_price', Number(e.target.value))} // Commit on release
                    />
                    </li>
                </ul>
            </section>

            {/* Regular Counts Section */}
            <section style={{ marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ marginTop: '15px', display: 'flex', fontSize: '20px', fontWeight: 'bold', color: '#87D3D7' }}>
                    Inventory SpotChecks
                    </h1>
                    <InfoPopup title="Inventory SpotChecks" content={inventoryCountsContent} />
                </div>
                <p>
                    Medvelle will SpotCheck
                    <strong className='orange-text'> {spotcheckSettings.product_count} </strong> product{spotcheckSettings.product_count > 1 ? 's' : ''} priced over <strong className='orange-text'> ${spotcheckSettings.minimum_product_price} </strong> every
                    <strong className='orange-text'> {spotcheckSettings.count_frequency} </strong> day{spotcheckSettings.count_frequency > 1 ? 's' : ''}, starting from <strong className='orange-text'>{spotcheckSettings.start_date}</strong>.
                </p>
                <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                    {/* Full Inventory Count Frequency Slider */}
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <strong style={{ marginRight: '10px', marginBottom: '30px', width: '80px' }}>Quantity:</strong>
                        <Slider
                            value={spotcheckSettings.product_count}
                            isDisabled={isDefaultSettings}
                            min={0}
                            max={maxProductCount}
                            step={1}
                            marks={predefinedSteps.product_count}
                            onChange={(e) => handleSliderChange('product_count', Number(e.target.value))} // Update state on change
                            onChangeCommitted={(e) => handleSliderCommit('product_count', Number(e.target.value))} // Commit on release
                        />
                    </li>

                    {/* Minimum Product Price Slider */}
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <strong style={{ marginRight: '10px', marginBottom: '30px', width: '80px' }}>Value:</strong>
                        <Slider
                            value={spotcheckSettings.minimum_product_price}
                            isDisabled={isDefaultSettings}
                            min={0}
                            max={maxProductPrice}
                            step={1}
                            marks={predefinedSteps.minimum_product_price.map(val => `$${val}`)}
                            onChange={(e) => handleSliderChange('minimum_product_price', Number(e.target.value))} // Update state on change
                            onChangeCommitted={(e) => handleSliderCommit('minimum_product_price', Number(e.target.value))} // Commit on release
                        />
                    </li>

                    {/* Counting Frequency Slider */}
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <strong style={{ marginRight: '10px', marginBottom: '30px', width: '80px' }}>Frequency:</strong>
                        <Slider
                            value={spotcheckSettings.count_frequency}
                            isDisabled={isDefaultSettings}
                            min={1}
                            max={maxDaysOccurrency}
                            step={1}
                            marks={predefinedSteps.count_frequency.map(val => `${val} day${val > 1 ? 's' : ''}`)}
                            onChange={(e) => handleSliderChange('count_frequency', Number(e.target.value))} // Update state on change
                            onChangeCommitted={(e) => handleSliderCommit('count_frequency', Number(e.target.value))} // Commit on release
                        />
                    </li>


                    {/* Start Date Section */}
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <strong style={{ marginRight: '10px', width: '80px' }}>Start Date:</strong>
                        <input
                            type="date"
                            value={spotcheckSettings.start_date ? spotcheckSettings.start_date : ''}
                            disabled={isDefaultSettings}
                            onChange={(e) => handleDateChange(e.target.value)} // Handle date change
                            max={new Date().toISOString().split('T')[0]} // Prevent selecting a date after today
                            style={{ fontSize: '14px', padding: '5px' }} // Additional styling
                        />
                    </li>

                </ul>
            </section>

            <br/>

            <ToastContainer />
        </div>
    );
};

export default SpotCheckSettings;