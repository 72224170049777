import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Card from '../../components/Card';
import PromotionModal from './PromotionModal';
import SupportForm from '../support/SupportForm';
import logo from '../../assets/images/medvelle_blue.png';

const PromotionCarousel = forwardRef(({ cards, waitTime, locationName, permissionCode}, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const [totalScroll, setTotalScroll] = useState(0); // Track total scroll distance
    const [width, setWidth] = useState(0);
    const scrollRef = useRef(null);
    const supplierCardRef = useRef(null);
    const totalCards = cards.slice(0, 7); // Get the first 6 cards
    const gap = 40;
    const wrappedCards = [...cards];
    const [promotionModalOpen, setPromotionModalOpen] = useState(false);
    const [selectedPromotion, setSelectedPromotion] = useState({})
    const [showContact, setShowContact] = useState(false);

    // Expose the height of the carousel container via the ref
    useEffect(() => {
        if (ref && scrollRef.current) {
            ref.current = scrollRef.current; // Pass the scroll container reference to the parent
        }
    }, [ref]);

    useEffect(()=>{
        if (supplierCardRef.current) {
            const cardWidth = supplierCardRef.current.getWidth(); // Call the exposed method
            setWidth(cardWidth + gap); // Store the width in state
        }
    },[cards]);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        const scrollStep = scrollContainer ? totalScroll > 0 ? width - totalScroll % width : width : 0; // Calculate scroll step
        let intervalId;

        const startScrolling = () => {
            if (scrollContainer) {
                intervalId = setInterval(() => {
                    const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

                    scrollContainer.scrollBy({
                        left: scrollStep,
                        behavior: 'smooth',
                    });
                    
                    setTotalScroll(0); 
                }, waitTime); // Scroll every 5 seconds
            }
        };

        // Start scrolling if not hovered
        if (!isHovered) {
            startScrolling();
        }

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [wrappedCards, isHovered]);

    const handleScroll = () => {
        const scrollContainer = scrollRef.current;
        if (scrollContainer && isHovered) {
            setTotalScroll(scrollContainer.scrollLeft); // Update total scroll distance
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const toggleContactInfo = () => {
        setShowContact(!showContact);
    };

    return (
        <>
            <div
                style={{display: 'flex', overflow: 'auto', gap: gap, width: 'auto', paddingRight: '8px'}}
                onScroll={handleScroll}
                className='scroll-container'
                ref={scrollRef}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={handleMouseLeave}
            >
                {wrappedCards.map((item, index) => (
                    <Card
                        key={index}
                        ref={supplierCardRef}
                        title={item.promotion_title}
                        onClick={() => {setPromotionModalOpen(true); setSelectedPromotion(item);}}
                        paragraphs={[
                            <div style={{color: 'grey', fontWeight: 'bold'}}>
                                {item.supplier_name}
                            </div>,
                            <div style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2, /* Limit to 2 lines */
                                WebkitBoxOrient: 'vertical', /* Vertical orientation */
                                overflow: 'hidden', /* Hide overflowing text */
                                textOverflow: 'ellipsis',
                            }}>{item.promotion_description}</div>,

                            <div style={{fontStyle: 'italic'}}>Expiration: {new Date(item.end_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</div>
                        ]}
                        image={item.promotion_picture}
                    />

                ))}
                <Card
                    ref={supplierCardRef}
                    title={'Submit Promotion'}
                    onClick={toggleContactInfo}
                    paragraphs={[
                        <div style={{color: 'grey', fontWeight: 'bold'}}>
                            {'Any Supplier'}
                        </div>,
                        <div style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2, /* Limit to 2 lines */
                            WebkitBoxOrient: 'vertical', /* Vertical orientation */
                            overflow: 'hidden', /* Hide overflowing text */
                            textOverflow: 'ellipsis',
                        }}>{'Share a new promotion using Medvelle Community Promotions.'}</div>,

                        <div style={{display: 'inline-block', alignContent: 'center'}}>
                            <button className='action-button' style={{whiteSpace: 'nowrap'}} onClick={toggleContactInfo}>
                                New Promotion
                            </button>
                        </div>
                    ]}
                    image={logo}

                />
            </div>
            <PromotionModal isOpen={promotionModalOpen} setIsOpen={setPromotionModalOpen} onClose={() => { }} promotion={selectedPromotion} />
            {showContact && (
                <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} supportCategory={'Promotion Submission'}/>
            )}
        </>
        
    );
});

export default PromotionCarousel;
