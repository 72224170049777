import React from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader.js';
import ReturnModal from './ReturnModal'; // Import ReturnModal
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const DisputeModal = ({ isOpen, onClose, orderDetails, openReturnModal, openDeliveryIssueModal }) => {
  return (
    <Modal
      overlayClassName="white-modal-overlay"
      className="white-modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          width: '75vw',
          height: '80vh',
          display: 'flex',
          alignItems: 'center', 
          justifyContent: 'center',
          border: '2px solid #87D3D7'
        },

      }}
    >
            
      <div style={{width: '100%', height: '100%'}}>

      <div>

      <h1 style={{ color: '#87D3D7', marginBottom: '-10px', textAlign: 'center', }}>
        Medvelle Dispute Resolution Assistant
      </h1>
      <button className="support-button"
                    style={{
                        marginLeft: 'auto', // Push the button to the far right
                        fontSize: 'medium',
                        display: 'flex',
                        marginTop:'-40px',
                        marginRight:'10px',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        height: '2.5rem',
                        width: '2.5rem'


                    }} onClick={onClose}>
                    <FontAwesomeIcon style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                    }} icon={faTimes} />
                </button>
        </div>
      <p style={{ color: 'black', fontSize: '18px', textAlign: 'center', marginBottom: '20px' }}>
        Here to help you resolve your order disputes with your suppliers
      </p>

      {/* Main Content Container */}
      <div style={{ display: 'flex', alignItems: 'center', height: '60%', width: '100%' }}>
        
        {/* First Section */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
          <h2>Facing Delivery Issues?</h2>
          <button className='action-button' style={{ width: '150px', marginTop:'20px' }} onClick={openDeliveryIssueModal}>Help</button>
        </div>

        {/* Vertical Line Divider */}
        <div style={{ width: '2px', height: '100%', backgroundColor: 'black' }}></div>

        {/* Second Section */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
          <h2>Initiate Return to Supplier</h2>
          <button className='action-button' style={{ width: '150px', marginTop:'20px' }} onClick={openReturnModal}>Help</button>
        </div>

      </div>
      <p style={{fontStyle:'italic', marginTop:'80px', marginBottom: '-10px', color:'', marginLeft:'10.2rem'}}>Note: Returns are handled based on the suppliers return policy; Medvelle will try its best to help you navigate</p>
      </div>
    </Modal>
  );
};

export default DisputeModal;
