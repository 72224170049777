import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader';

const PromotionModal = ({promotion, isOpen, setIsOpen, onClose}) => {
    const promotionDisclaimer = 'Medvelle Community Promotions are a beta feature available to select users and updated monthly. Medvelle aims to provide helpful and informative content but does not guarantee the accuracy, completeness, or timeliness of the Community Promotions listed. Community Promotions are subject to each suppliers Terms and may change at any time. For the most current information, we recommend directly engaging with your Sales Representatives and actively soliciting available promotions.'

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className="white-modal"
            overlayClassName="white-modal-overlay"
            style={{content: {width: '40vw', height: 'auto'}}}
        >
            <ModalHeader
                title={promotion.promotion_title}
                description={'Promotion details for'}
                subheader={promotion.supplier_name}
                onClose={handleClose}
                showExit={true}
            />
            <div style={{margin:'20px'}}>
                {promotion.promotion_picture && (
                    <img style={{
                        width: '90%',
                        height: 'auto',
                        aspectRatio: '2 / 1',
                    }}
                        src={promotion.promotion_picture}
                        className="promo-image"
                    />
                )}
                <div style={{fontWeight: 'bold'}}>
                    Description:
                </div>
                <div style={{
                }}>{promotion.promotion_description}</div>
                <div style={{fontStyle: 'italic', marginTop: '40px'}}><b>Expiration:</b> {new Date(promotion.end_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</div>
                <div style={{fontStyle: 'italic', marginTop: '4px', color: '#E4846E', fontSize:'1rem'}}><b style={{color:'black'}}>Disclaimer:</b> {promotionDisclaimer}</div>
            </div>
        </Modal>
    )
}

export default PromotionModal