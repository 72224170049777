
import React from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader';
import Table from '../../components/Table';

const RepModal = ({reps, supplier, isOpen, setIsOpen, onClose}) => {

    const handleClose = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className="white-modal"
            overlayClassName="white-modal-overlay"
            style={{content: {width: '50vw', height: 'auto'}}}
        >
            <ModalHeader
                title={supplier.supplier_name}
                description={'Representatives for'}
                onClose={handleClose}
                showExit={true}
            />
            {/* Modal header with download and close buttons */}
            <div style={{
                margin: '20px',
            }}>
                <>
                    <Table
                        data={reps}
                        columns={['Rep First Name', 'Rep Last Name', 'Rep Title', 'Rep Email', 'Rep Phone Number']}
                        mapping={{'Rep First Name':'rep_first_name', 'Rep Last Name':'rep_last_name', 'Rep Title': 'rep_title', 'Rep Email':'rep_email', 'Rep Phone Number':'rep_phone'}}
                        isHoverable={true}
                    />

                </>
            </div>

        </Modal>
    );
};

export default RepModal;