/**
 * Sends an order email by making a POST request to the /send-email API.
 *
 * @param {Object} emailData - The data to be sent in the email.
 * @param {string} emailData.sender_email - The sender's email address.
 * @param {Array<string>} emailData.recipient_emails - List of recipient email addresses.
 * @param {Array<string>} emailData.cc_emails - List of CC email addresses.
 * @param {string} emailData.subject - The subject of the email.
 * @param {string} emailData.message - The HTML content of the email.
 * @returns {Promise<void>}
 */
export const sendOrderEmail = async (emailData) => {
  try {
      // Function to validate email addresses
      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      // Filter out invalid email addresses
      emailData.recipient_emails = emailData.recipient_emails.filter(isValidEmail);
      emailData.cc_emails = emailData.cc_emails.filter(isValidEmail);

      // Check if sender email is provided and at least one valid recipient email exists
      if (!isValidEmail(emailData.sender_email) || emailData.recipient_emails.length === 0) {
          alert('Please provide a valid sender email and at least one valid recipient email.');
          throw new Error('Failed to reply to email');
      }

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/send_email', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData),
      });

      if (!response.ok) {
          // Extract error details if available
          return false;
      }

      console.log('Order email sent successfully');

      return true;
  } catch (error) {
      console.error('Error sending order email:', error);
      return false;
  }
};

/**
* Sends an order email by making a POST request to the /send-email API.
*
* @param {Object} emailData - The data to be sent in the email.
* @param {string} emailData.sender_email - The sender's email address.
* @param {Array<string>} emailData.recipient_emails - List of recipient email addresses.
* @param {Array<string>} emailData.cc_emails - List of CC email addresses.
* @param {string} emailData.subject - The subject of the email.
* @param {string} emailData.message - The HTML content of the email.
* @returns {Promise<void>}
*/
export const sendEmail = async (emailData) => {

try {
  // Function to validate email addresses
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Filter out invalid email addresses
  emailData.recipient_emails = emailData.recipient_emails.filter(isValidEmail);
  emailData.cc_emails = emailData.cc_emails.filter(isValidEmail);

  // Check if sender email is provided and at least one valid recipient email exists
  if (!isValidEmail(emailData.sender_email) || emailData.recipient_emails.length === 0) {
    alert('Please provide a valid sender email and at least one valid recipient email.');
    throw new Error('Failed to reply to email');
  }

  const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/send-email2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(emailData),
  });

  const data = await response.json();

  if (!response.ok) {
    // Extract error details if available
    return false;
  }

  console.log('Order email sent successfully');

  return data;
} catch (error) {
  console.error('Error sending order email:', error);
  return false;
}
};

/**
* Replies to an email using the provided sender email, email ID, reply message, and bundle ID.
* 
* @param {string} senderEmail - The email address of the sender.
* @param {string} emailId - The ID of the email to reply to.
* @param {string} replyMessage - The message content to be sent as a reply.
* @returns {Promise<object>} - A promise that resolves to the response from the server.
*/
export const replyToEmail = async (senderEmail, emailId, replyMessage, recipientEmails, ccEmails, bundleID) => {
try {
    // Function to validate email addresses
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Filter out invalid email addresses
    recipientEmails = recipientEmails.filter(isValidEmail);
    ccEmails = ccEmails.filter(isValidEmail);

    // Check if sender email is provided and at least one valid recipient email exists
    if (!isValidEmail(senderEmail) || recipientEmails.length === 0) {
        alert('Please provide a valid sender email and at least one valid recipient email.');
        throw new Error('Failed to reply to email');
    }

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/reply_to_email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            sender_email: senderEmail,
            email_id: emailId,
            reply_message: replyMessage,
            recipient_emails: recipientEmails,
            cc_emails: ccEmails,
            bundle_id: bundleID
        })
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Failed to reply to email');
    }

    return data;
} catch (error) {
    console.error('Error replying to email:', error);
    throw error;
}
};

/**
* Sends a reply to an email with optional attachments.
*
* @param {string} senderEmail - The email address of the sender.
* @param {string} emailId - The ID of the email being replied to.
* @param {string} replyMessage - The message content for the reply.
* @param {string[]} recipientEmails - List of recipient email addresses.
* @param {string[]} [ccEmails=[]] - List of CC email addresses (optional).
* @param {Object[]} [attachments=[]] - List of attachment objects with `name`, `type`, and `content` properties (optional).
* 
* @returns {Promise<Object>} - A promise that resolves to the response data from the API.
* @throws {Error} - Throws an error if validation fails or the API request fails.
*/
export const replyToEmail2 = async (senderEmail, emailId, replyMessage, recipientEmails, ccEmails, attachments) => {
try {
  // Function to validate email addresses
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Filter out invalid email addresses
  recipientEmails = recipientEmails.filter(isValidEmail);
  ccEmails = ccEmails.filter(isValidEmail);

  // Check if sender email is provided and at least one valid recipient email exists
  if (!isValidEmail(senderEmail) || recipientEmails.length === 0) {
    alert('Please provide a valid sender email and at least one valid recipient email.');
    throw new Error('Failed to reply to email');
  }
  const formData = new FormData();

  formData.append('sender_email', senderEmail);
  formData.append('email_id', emailId);
  formData.append('reply_message', replyMessage);

  // Add recipient emails
  recipientEmails.forEach(email => {
    formData.append('recipient_emails[]', email);
  });

  // Add CC emails
  if (ccEmails.length > 0) {
  // Append each email to FormData
    ccEmails.forEach(email => {
      formData.append('cc_emails[]', email);
    });
  }
  attachments.forEach(attachment => {
    let blob = new Blob([attachment], {type: attachment.type});
    formData.append('attachments', blob, attachment.name);
  });

  const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/reply-to-email2', {
    method: 'POST',
    body: formData
  });

  const data = await response.json();

  if (!data.ok) {
    throw new Error(data.error || 'Failed to reply to email');
  }

  return data;
} catch (error) {
  console.error('Error replying to email:', error);
  throw error;
}
};



/**
* Downloads a file from the server.
* 
* @param {string} email_id - The ID of the email containing the attachment.
* @param {string} email_address - The email address of the user.
* @param {string} attachment_id - The ID of the attachment to download.
*/
export const downloadFile = async (email_id, email_address, attachment_id, attachment_name) => {
  try {
    // Make a request to your Express server to fetch the file
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/download_attachment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email_id, // The ID of the email
        email_address, // The email address of the user
        attachment_id, // The ID of the attachment
      }),
    });

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Extract filename and content type from response headers
    const filename = attachment_name ? attachment_name : 'downloaded_file';

    // Create a Blob from the response data
    const blob = await response.blob();

    // Create a link element, set its href to the Blob URL, and trigger a download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Set the filename for the download
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};


/**
* Gets a file from the server as a url.
* 
* @param {string} email_id - The ID of the email containing the attachment.
* @param {string} email_address - The email address of the user.
* @param {string} attachment_id - The ID of the attachment to download.
*/
export const getFileBlob = async (email_id, email_address, attachment_id, setBlob) => {
try {
  // Make a request to your Express server to fetch the file
  const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/download_attachment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email_id, // The ID of the email
      email_address, // The email address of the user
      attachment_id, // The ID of the attachment
    }),
  });

  // Check if the response is okay
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${ response.status }`);
  }

  const blob = await response.blob();

  setBlob(blob);
  return blob;
} catch (error) {
  console.error('Error downloading the file:', error);
}
};
/**
* Fetches email conversation details and updates the state.
*
* @param {string} emailID - The ID of the email whose conversation is to be fetched.
* @param {string} emailAddress - The email address of the user whose conversation is to be fetched.
* @param {Function} setEmailConversation - A state setter function to update the state with the fetched conversation data.
*/
export const fetchEmailConversation = async (emailID, emailAddress, setEmailConversation) => {
  try {
    // Fetch the email conversation data
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/print_conversation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email_id: emailID,
        email_address: emailAddress,
      }),
    });

    // Check if the response is okay
    if (!response.ok) {
      const errorMessage = `Error ${response.status}: ${response.statusText}`;
      setEmailConversation({ error: errorMessage });
      console.error('API call failed:', errorMessage);
      return "Error";
  }

    // Parse the response data
    const data = await response.json();

    // Extract the last message
    const lastMessage = data.messages[data.messages.length - 1];

    // Collect all attachments and include the email_id with each
    const allAttachments = data.messages.reduce((acc, message) => {
      if (message.attachments && message.attachments.length > 0) {
        message.attachments.forEach(attachment => {
          acc.push({
            ...attachment,
            email_id: message.message_id  // Add email_id to each attachment
          });
        });
      }
      return acc;
    }, []);

    // Update the state with the last message and attachments
    setEmailConversation({
      lastMessage,
      attachments: allAttachments
    });

    return "Success";

  } catch (error) {
    console.error('Error fetching email conversation:', error);
    // Optionally, update the state with an error message or handle the error
    setEmailConversation({ error: error.message });
  }
};